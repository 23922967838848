import FSEntitiesMenu from "@/components/features/files/FSEntitiesMenu";
import FileCard from "@/components/features/files/FileCard";
import { Button } from "@/components/ui/button/Button";
import {
  ContextMenu,
  ContextMenuTrigger,
} from "@/components/ui/context-menu/ContextMenu";
import {
  DropdownMenu,
  DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import useLongPress from "@/hooks/useLongPress";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { useFilesStorage } from "@/store/filesStore";
import { DirectoryFileI } from "@/types/files";
import { MoreVertical } from "lucide-react";
import { useRef, useState } from "react";

type FileStorageFileElementProps = {
  file: DirectoryFileI;
};

export default function File({ file }: FileStorageFileElementProps) {
  const fileRef = useRef<HTMLDivElement>(null);
  const breakpoint = useMediaQueryHook("sm");
  const [openDropdownMenu, setOpenDropdownMenu] = useState<boolean>(false);
  const [openContextMenu, setOpenContextMenu] = useState<boolean>(false);

  const {
    OnFSEntityClick,
    isSelected,
    selectedSize,
    setOpenViewer,
    setFileToOpen,
  } = useFilesStorage();
  const isSelectedFile = isSelected(file.id, "file");
  const size = selectedSize();

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && file.permissions.read) {
      setFileToOpen(file);
      setOpenViewer(true);
    }
  };

  const onDbClick = () => {
    if (file.permissions.read) {
      setFileToOpen(file);
      setOpenViewer(true);
    }
  };

  const onClick = (event: React.PointerEvent, isTouch: boolean) => {
    if ((isTouch && size > 0) || !isTouch) {
      OnFSEntityClick({ file: file, event: event });
    } else if (isTouch) {
      setFileToOpen(file);
      setOpenViewer(true);
    }
  };

  const onHold = (event: React.PointerEvent) => {
    OnFSEntityClick({ file: file, event: event });
  };

  const { onPointerDown, onPointerMove, onPointerUp } = useLongPress({
    onClick,
    onHold,
    options: { preventDefault: true },
  });

  const handleOpenDropdownMenu = (bool: boolean) => {
    setOpenDropdownMenu(bool);
    OnFSEntityClick({ file: file });
  };
  const handleOpenContextMenu = (bool: boolean) => {
    setOpenContextMenu(bool);
    OnFSEntityClick({ file: file });
  };

  return (
    <ContextMenu onOpenChange={handleOpenContextMenu}>
      <DropdownMenu
        onOpenChange={handleOpenDropdownMenu}
        open={openDropdownMenu}
      >
        <ContextMenuTrigger disabled={breakpoint} asChild>
          <FileCard
            aria-selected={isSelectedFile}
            tabIndex={0}
            ref={fileRef}
            onDoubleClick={onDbClick}
            onKeyDown={onKeyDown}
            onPointerDown={onPointerDown}
            onPointerMove={onPointerMove}
            onPointerUp={onPointerUp}
            file={file}
            menuButton={
              <DropdownMenuTrigger asChild>
                <Button
                  disabled={size > 1}
                  variant={"ghost"}
                  variantColor={"muted"}
                  icon={<MoreVertical />}
                  iconPosition={"only"}
                  size={"sm"}
                />
              </DropdownMenuTrigger>
            }
            className={cn(
              "bg-bg-container",
              isSelectedFile && "border-fg-brand bg-bg-element-selected",
            )}
          />
        </ContextMenuTrigger>
        <FSEntitiesMenu
          element={file}
          type={"file"}
          showContextMenu={!breakpoint}
          showDropdownMenu
        />
      </DropdownMenu>
    </ContextMenu>
  );
}
