import {
  PostMeetingPropsT,
  PostMeetingSchedulePropsT,
} from "@/api/endpoints/meetings";
import { CalendarFormSchemaType } from "@/schemas/meeting.schema";
import { MeetingI } from "@/types/meetings";
import { format, setHours, setMinutes } from "date-fns";

function convertDataToForm(
  data: MeetingI | undefined,
): CalendarFormSchemaType | undefined {
  if (data === undefined) return undefined;

  const { name, desc, type, meetingURL, locationId, startDate, endDate } = data;
  const handleFormatTime = (date: string) => {
    try {
      return format(new Date(date), "HH:mm:ss");
    } catch {
      return "";
    }
  };
  return {
    name: name,
    desc: desc ? desc : undefined,
    type: type,
    locationId: locationId ? locationId : undefined,
    meetingURL: meetingURL ? meetingURL : undefined,
    date: new Date(startDate),
    startTime: handleFormatTime(startDate),
    endTime: handleFormatTime(endDate),
  } as CalendarFormSchemaType;
}

function convertFormToData({
  date,
  startDate,
  endDate,
  startTime,
  endTime,
  name,
  desc,
  type,
  locationId,
  meetingURL,
  userArr,
  groupArr,
  method,
  excludeHolidays,
  schedule,
}: CalendarFormSchemaType): PostMeetingPropsT | PostMeetingSchedulePropsT {
  const handleSetTime = (date: Date, timeString: string) => {
    if (!date || !timeString) throw new Error("Invalid date or time");

    const [hours, minutes] = timeString.split(":").map(Number);

    let newDate = setHours(date, hours);
    newDate = setMinutes(newDate, minutes);

    return newDate;
  };

  if (method === "once") {
    return {
      name: name,
      desc: desc,
      type: type,
      startDate: handleSetTime(date, startTime).toISOString(),
      endDate: handleSetTime(date, endTime).toISOString(),
      locationId: locationId,
      meetingURL: meetingURL,
      userArr: userArr,
      groupArr: groupArr,
    } as PostMeetingPropsT;
  }
  return {
    name: name,
    desc: desc,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    userArr: userArr,
    groupArr: groupArr,
    excludeHolidays: excludeHolidays,
    schedule: schedule,
  } as PostMeetingSchedulePropsT;
}

export { convertDataToForm, convertFormToData };
