import { tableAddGroupT } from "@/components/features/manage/tanstack-table/TanstackTable";
import { Button } from "@/components/ui/button/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { ChevronDown, Plus, Trash2 } from "lucide-react";

export default function TanstackTableActions({
  addGroup,
  removeRows,
  isSelection,
}: {
  addGroup: tableAddGroupT[];
  removeRows: () => void;
  isSelection: boolean;
}) {
  const breakpoint = useMediaQueryHook("md");

  let content;
  if (addGroup.length === 1) {
    content = (
      <Button
        variant={"solid"}
        variantColor={"brand"}
        onClick={addGroup[0].func}
        icon={addGroup[0].icon ? addGroup[0].icon : <Plus />}
        iconPosition={breakpoint ? "only" : "left"}
      >
        Dodaj
      </Button>
    );
  } else {
    content = (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant={"solid"}
            variantColor={"brand"}
            icon={breakpoint ? <Plus /> : <ChevronDown />}
            iconPosition={breakpoint ? "only" : "right"}
          >
            Dodaj
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align={"end"}>
          <DropdownMenuGroup>
            {addGroup.map((btn) => {
              return (
                <DropdownMenuItem key={btn.id} onClick={btn.func}>
                  {btn.icon}
                  {btn.label}
                </DropdownMenuItem>
              );
            })}
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }
  return (
    <div className={cn("flex gap-2 shrink-0 grow justify-end")}>
      <Button
        variant={"ghost"}
        variantColor={"destructive"}
        icon={<Trash2 />}
        iconPosition={breakpoint ? "only" : "left"}
        onClick={removeRows}
        disabled={!isSelection}
      >
        Usuń
      </Button>
      {content}
    </div>
  );
}
