import WeekCards from "@/components/features/calendar/WeekCards";
import { Button } from "@/components/ui/button/Button";
import { Label } from "@/components/ui/label/Label";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import pl from "date-fns/locale/pl";
import { ArrowUpRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

type MeetingsListHeaderProps = {
  date: Date;
  setDate?: (day: Date) => void;
  defaultNavigate?: string;
  className?: string;
};

export default function MeetingsListHeader({
  date,
  setDate,
  defaultNavigate = "/calendar/?viewKey=month",
  className,
}: MeetingsListHeaderProps) {
  const navigate = useNavigate();

  return (
    <div className={cn("flex flex-col gap-5", className)}>
      <div className={"flex justify-between items-center px-4"}>
        <Label className={"text-xl font-semibold text-fg-primary"}>
          {format(date, "LLLL yyyy", { locale: pl })}
        </Label>
        <Button
          size={"sm"}
          variant={"ghost"}
          variantColor={"muted"}
          icon={<ArrowUpRight />}
          iconPosition={"only"}
          onClick={() => navigate(defaultNavigate)}
        />
      </div>
      <div className={"flex w-full justify-center"}>
        <div className={"flex gap-1  px-4 grow overflow-x-auto"}>
          <WeekCards date={date} onClick={setDate} className={"w-full"} />
        </div>
      </div>
    </div>
  );
}
