import { Helmet } from "react-helmet-async";

type SeoHelmetProps = {
  title: string;
  description: string;
  type?: string;
  prioritizeSeoTags?: boolean;
};

export default function SeoHelmet({
  title = "BetterCallPaul",
  description = "BetterCallPaul",
  type,
  prioritizeSeoTags = true,
}: SeoHelmetProps) {
  return (
    <Helmet prioritizeSeoTags={prioritizeSeoTags}>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
    </Helmet>
  );
}
