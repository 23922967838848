import VideoPlayer from "@/components/ui/media-viewer/VideoPlayer";
import { FilesI } from "@/types/files";
import getFileUrl from "@/utils/getFileUrl";
import { VideoMimeType } from "@vidstack/react/types/vidstack";
import { useMemo } from "react";

type MediaVideoViewerProps = {
  file: FilesI | File;
};

export default function MediaVideoViewer({ file }: MediaVideoViewerProps) {
  const isFilesInstance = "id" in file;

  const { fileURL, type } = useMemo(() => {
    if (!file || isFilesInstance) {
      return { fileURL: getFileUrl(file.id), type: file.fileType };
    }
    return { fileURL: URL.createObjectURL(file), type: file.type };
  }, [file]);

  return (
    <VideoPlayer
      src={{
        src: fileURL,
        type: type as VideoMimeType,
      }}
      title={file?.name ? file?.name : "video file"}
      className={"h-full"}
    />
  );
}
