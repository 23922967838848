import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { Separator } from "@/components/ui/separator/Separator";
import { cn } from "@/lib/utils";
import "@radix-ui/react-dropdown-menu";
import { Column } from "@tanstack/react-table";
import { CheckIcon, Plus } from "lucide-react";
import { ComponentType } from "react";

interface DataTableFacetedFilterProps<TData, TValue> {
  column?: Column<TData, TValue>;
  title?: string;
  options: {
    label: string;
    value: string | number | boolean;
    icon?: ComponentType<{ className?: string }>;
  }[];
}

export function TanstackTableFacetedFilters<TData, TValue>({
  column,
  title,
  options,
}: DataTableFacetedFilterProps<TData, TValue>) {
  const facets = column?.getFacetedUniqueValues();

  let selectedValues: Set<string | number | boolean> = new Set();

  const filterValue = column?.getFilterValue();

  if (Array.isArray(filterValue) && filterValue.length > 0) {
    if (typeof filterValue[0] === "string") {
      selectedValues = new Set<string>(filterValue as string[]);
    } else if (typeof filterValue[0] === "number") {
      selectedValues = new Set<number>(filterValue as number[]);
    } else if (typeof filterValue[0] === "boolean") {
      selectedValues = new Set<boolean>(filterValue as boolean[]);
    }
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant={"outline"}
          variantColor={"muted"}
          size={"sm"}
          icon={<Plus />}
          iconPosition={"left"}
          className={"border-dashed gap-1 px-2"}
        >
          {title}
          {selectedValues?.size > 0 && (
            <>
              <Separator orientation={"vertical"} className={"mx-2 h-4"} />
              <Badge
                size={"sm"}
                variant={"muted"}
                hideDot
                className={"gap-0.5"}
              >
                {selectedValues.size}
              </Badge>
            </>
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[12rem]" align="start">
        <DropdownMenuGroup>
          {options.map((option) => {
            const isSelected = selectedValues.has(option.value);
            return (
              <DropdownMenuItem
                key={option.value.toString()}
                onSelect={(e) => {
                  e.preventDefault();
                  if (isSelected) {
                    selectedValues.delete(option.value);
                  } else {
                    selectedValues.add(option.value);
                  }
                  const filterValues = Array.from(selectedValues);
                  column?.setFilterValue(
                    filterValues.length ? filterValues : undefined,
                  );
                }}
              >
                <div
                  className={cn(
                    "mr-2 flex h-4 w-4 items-center justify-center",
                    isSelected
                      ? "text-fg-primary"
                      : "opacity-50 [&_svg]:invisible",
                  )}
                >
                  <CheckIcon className={cn("h-4 w-4")} />
                </div>
                {option.icon && (
                  <option.icon className="mr-2 h-4 w-4 text-fg-primary" />
                )}
                <span>{option.label}</span>
                {facets?.get(option.value) && (
                  <span className="ml-auto flex h-4 w-4 items-center justify-center text-xs">
                    {facets.get(option.value)}
                  </span>
                )}
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuGroup>
        {selectedValues.size > 0 && (
          <>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              <DropdownMenuItem
                onSelect={() => column?.setFilterValue(undefined)}
                className="justify-center text-center"
              >
                Wyczyść
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
