import GroupElementHeader from "@/components/features/groups/layout/GroupElementHeader";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { forwardRef } from "react";

type GroupTasksProps = {
  className?: string;
};

const GroupTasks = forwardRef<HTMLDivElement, GroupTasksProps>(
  ({ className }: GroupTasksProps, ref) => {
    const breakpoint = useMediaQueryHook("sm");

    const handleNavigate = () => {
      console.log("Navigate to files");
    };

    return (
      <div
        ref={ref}
        className={cn(
          "flex flex-col rounded-lg border-border border-1 bg-bg-container overflow-auto",
          className,
        )}
      >
        <GroupElementHeader label={"Zadania"} callback={handleNavigate} />
        <div
          className={cn(
            "flex flex-col px-4 pb-4",
            breakpoint ? "" : "overflow-y-auto overscroll-contain",
          )}
        >
          <p className={"py-4 text-fg-muted text-sm text-center"}>Brak zadań</p>
        </div>
      </div>
    );
  },
);
GroupTasks.displayName = "GroupTasks";
export default GroupTasks;
