import { httpErrorHandler } from "@/api/api";
import { usePostLoginMutation as usePostLogin } from "@/api/queries/authQueries";
import SeoHelmet from "@/app/SeoHelmet";
import { BackgroundWrapper } from "@/components/ui/background-wrapper/BackgroundWrapper";
import { Button } from "@/components/ui/button/Button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form/Form";
import { Input, InputActionButton } from "@/components/ui/input/Input";
import { LoginSchema, LoginSchemaT } from "@/schemas/auth.schema";
import { useSetCredentials } from "@/store/authStore";
import { useStateStore } from "@/store/stateStore";
import { yupResolver } from "@hookform/resolvers/yup";
import { Eye, EyeOff } from "lucide-react";
import { lazy, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const LoginId = lazy(
  () => import("@/assets/images/auth/login-id-elements-img.svg?react"),
);
const LoginPhone = lazy(
  () => import("@/assets/images/auth/login-phone-img.svg?react"),
);
const Bubble = lazy(() => import("@/assets/images/auth/bubble.svg?react"));

export default function LoginPage() {
  const navigate = useNavigate();
  const setCredentials = useSetCredentials();
  const { mutateAsync, isPending } = usePostLogin();
  const { pathURL } = useStateStore();
  const [InputMode, setInputMode] = useState<boolean>(false);

  const form = useForm<LoginSchemaT>({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onSubmit",
    resolver: yupResolver(LoginSchema),
  });

  const onSubmit = (data: LoginSchemaT) => {
    mutateAsync(data)
      .then(({ redirectURL, user }) => {
        setCredentials(user);
        const URL =
          redirectURL === "/" && pathURL && pathURL !== window.location.pathname
            ? pathURL
            : redirectURL;
        navigate(URL);
      })
      .catch((error) => {
        const { detail } = httpErrorHandler(error);

        form.setError("password", {
          type: "manual",
          message: detail,
        });
        form.setError("email", {
          type: "manual",
          message: detail,
        });
      });
  };

  return (
    <>
      <SeoHelmet
        title={"BetterCallPaul - login"}
        description={"BetterCallPaul login page"}
      />
      <BackgroundWrapper>
        <LoginId
          className={
            "absolute -left-14 top-2/4 -translate-y-2/4 animate-fade-in opacity-60 duration-200 sm:hidden"
          }
        />
        <LoginPhone
          className={
            "absolute -right-14 top-2/4 -translate-y-2/4 animate-fade-in opacity-60 duration-200 sm:hidden"
          }
        />
        <Bubble
          className={
            "absolute -right-14 top-0 hidden animate-fade-in opacity-60 duration-200 sm:block"
          }
        />
      </BackgroundWrapper>
      <div
        className={
          "flex min-h-[100dvh] w-full items-center justify-center p-6 sm:items-end sm:p-0 sm:pt-6"
        }
      >
        <section
          className={
            "z-30 flex min-h-[400px] w-[clamp(380px,50dvw,420px)] flex-col items-center gap-7 overflow-auto rounded-2xl border border-border bg-bg-container px-6 py-6 shadow-md-sharp-border sm:h-[85dvh] sm:w-full sm:rounded-b-none sm:border-0 sm:border-t-1 sm:px-4"
          }
        >
          <Form {...form}>
            <form
              className={"flex w-full flex-grow flex-col gap-7"}
              onSubmit={form.handleSubmit(onSubmit)}
              noValidate
            >
              <h1 className={"py-3 text-4xl font-semibold text-fg-primary"}>
                Zaloguj się
              </h1>
              <fieldset className={"flex flex-col gap-3"}>
                <FormField
                  name={"email"}
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder={"Email"}
                          autoComplete={"username"}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name={"password"}
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Hasło</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          type={InputMode ? "text" : "password"}
                          placeholder={"Hasło"}
                          autoComplete={"current-password"}
                          endContent={
                            <InputActionButton
                              aria-label={"Toggle password visibility"}
                              icon={InputMode ? <EyeOff /> : <Eye />}
                              onClick={() => setInputMode(!InputMode)}
                            />
                          }
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </fieldset>
              <footer className={"flex flex-col gap-3"}>
                <Button
                  className={"w-full"}
                  type={"submit"}
                  size={"lg"}
                  isLoading={{
                    state: isPending,
                    message: "Logowanie...",
                  }}
                  variant={"solid"}
                  variantColor={"brand"}
                  onClick={form.handleSubmit(onSubmit)}
                >
                  Zaloguj
                </Button>
                <Button
                  onClick={() => navigate("/auth/passwordReset/")}
                  className={"w-full"}
                  size={"lg"}
                  variant={"outline"}
                  variantColor={"muted"}
                >
                  Odzyskaj hasło
                </Button>
              </footer>
            </form>
          </Form>
        </section>
      </div>
    </>
  );
}
