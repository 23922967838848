import { Button } from "@/components/ui/button/Button";
import Image from "@/components/ui/image/Image";
import AudioPlayer from "@/components/ui/media-viewer/AudioPlayer";
import VideoPlayer from "@/components/ui/media-viewer/VideoPlayer";
import { cn, formatBytes } from "@/lib/utils";
import { FilesI } from "@/types/files";
import { checkFileType } from "@/utils/checkFileType";
import getFileUrl from "@/utils/getFileUrl";
import { AudioMimeType, VideoMimeType } from "@vidstack/react/types/vidstack";
import { FileUp, X } from "lucide-react";
import { forwardRef, useMemo } from "react";

interface FileCardProps {
  className?: string;
  contentClassName?: string;
  file: File | FilesI;
  onRemove: () => void;
}

const FileInputCard = forwardRef<HTMLDivElement, FileCardProps>(
  ({ className, contentClassName, file, onRemove, ...props }, ref) => {
    const isFileInstance = file instanceof File;

    const { fileType, mimeType } = useMemo(() => {
      const type = isFileInstance ? file.type : file.fileType;
      return { fileType: checkFileType(type), mimeType: type };
    }, [file, isFileInstance]);

    const fileURL = useMemo(() => {
      if (isFileInstance) {
        return URL.createObjectURL(file);
      } else if (!isFileInstance && file.id) {
        return getFileUrl(file.id);
      }
      return "";
    }, [file, isFileInstance]);

    const content = useMemo(() => {
      if (fileType === "image") {
        return (
          <Image
            src={fileURL}
            alt={file?.name}
            thumbnail={!isFileInstance ? file?.thumbnail : undefined}
            type={mimeType}
            className={"w-full h-full object-fill"}
            layoutClassName={"w-full h-full justify-center"}
          />
        );
      } else if (fileType === "video") {
        return (
          <VideoPlayer
            src={{
              src: fileURL,
              type: mimeType as VideoMimeType,
            }}
            title={"Video"}
            className={"w-full h-full object-fill z-10 aspect-square"}
            variant={"minimal"}
            autoPlay={true}
            muted={true}
          />
        );
      } else if (fileType === "audio") {
        return (
          <AudioPlayer
            src={{
              src: fileURL,
              type: mimeType as AudioMimeType,
            }}
            title={"Audio"}
            variant={"minimal"}
            className={"w-full h-full z-10"}
          />
        );
      } else {
        return (
          <div
            className={cn(
              " w-full h-full flex flex-col items-center justify-center gap-2",
              contentClassName,
            )}
          >
            <FileUp className={"w-5 h-5 stroke-fg-secondary"} />
            <div className={"flex flex-col max-w-full p-1 gap-1"}>
              <p className="text-xs font-medium text-fg-secondary truncate">
                {file.name}
              </p>
              <span className="text-xs font-medium text-fg-muted text-center">
                {isFileInstance && formatBytes(file.size)}
              </span>
            </div>
          </div>
        );
      }
    }, [fileType]);

    return (
      <div
        ref={ref}
        className={cn(
          "relative flex gap-2 overflow-hidden border-border border-1 rounded-md h-[200px] p-1",
          className,
        )}
        {...props}
      >
        {content}
        <Button
          size={"sm"}
          type={"button"}
          variant={"outline"}
          variantColor={"muted"}
          className={
            "absolute z-10 top-1 right-1 bg-bg-container min-h-7 h-7 min-w-7 w-7"
          }
          icon={<X />}
          iconPosition={"only"}
          onClick={(e) => {
            onRemove();
            e.preventDefault();
          }}
        />
      </div>
    );
  },
);

FileInputCard.displayName = "FileInputCard";
export default FileInputCard;
