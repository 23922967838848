import { axiosInstance } from "@/api/api";
import {
  AddLocationSchemaType,
  EditLocationSchemaType,
} from "@/schemas/location.schema";
import { LocationI } from "@/types/locations";

export const getLocation = async (id?: number): Promise<LocationI> => {
  return await axiosInstance.get("/api/location", { params: { id } });
};

export const getLocations = async (): Promise<LocationI[]> => {
  return await axiosInstance.get("/api/location/getAll");
};

export const postLocation = async (
  data: AddLocationSchemaType,
): Promise<LocationI> => {
  return await axiosInstance.put("/api/location", data);
};

export const editLocation = async (
  data: EditLocationSchemaType,
): Promise<LocationI> => {
  return await axiosInstance.post("/api/location", data);
};

export const deleteLocation = async ({
  id,
}: {
  id: number;
}): Promise<string> => {
  return await axiosInstance.delete("/api/location", { data: { id } });
};
