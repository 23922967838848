import { Time } from "@vidstack/react";

export function TimeGroup() {
  return (
    <div className={"ml-1 flex items-center w-13"}>
      <Time className={"time text-xs"} type={"current"} />
      <div className={"mx-1 text-xs font-medium"}>/</div>
      <Time className={"time text-xs"} type={"duration"} />
    </div>
  );
}
