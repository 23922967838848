import { Navigation } from "@/components/ui/navigation/Navigation";
import { Toaster } from "@/components/ui/toast/Toaster";
import { createContext, useContext, useRef } from "react";
import { Outlet } from "react-router";

type MainLayoutPageContextT = {
  ref: React.RefObject<HTMLDivElement> | null;
};

const MainLayoutPageContext = createContext<MainLayoutPageContextT>({
  ref: null,
});

export const useMainLayoutPageContext = () => {
  return useContext(MainLayoutPageContext);
};

export default function MainLayoutPage() {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <MainLayoutPageContext.Provider value={{ ref: ref }}>
      <div
        className={
          "relative flex sm:flex-col overflow-auto overflow-x-hidden h-[100dvh] !overscroll-contain"
        }
      >
        <Navigation />
        <main
          ref={ref}
          className={
            "max-w-desktop overflow-x-auto relative flex flex-col flex-auto px-6 py-6 mx-auto sm:mx-0 sm:my-0 sm:p-4"
          }
        >
          <Outlet />
        </main>
      </div>
      <Toaster />
    </MainLayoutPageContext.Provider>
  );
}
