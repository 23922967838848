import {
  ZustandHookSelectors,
  createSelectorHooks,
} from "auto-zustand-selectors-hook";
import { create } from "zustand";
import { persist } from "zustand/middleware";

type State = {
  isNavbarOpen: boolean;
  pathURL: string | null;
  avatarMap: Map<number, string>;
};

type Action = {
  updateNavbarState: (isOpen: State["isNavbarOpen"]) => void;
  setPathURL: (pathURL: State["pathURL"]) => void;
  setAvatarMap: (map: State["avatarMap"]) => void;
};

const useStateStoreBase = create<Action & State>()(
  persist(
    (set) => ({
      isNavbarOpen: false,
      pathURL: null,
      avatarMap: new Map<number, string>(),
      setPathURL: (pathURL: State["pathURL"]) => set({ pathURL }),
      updateNavbarState: (isOpen: State["isNavbarOpen"]) =>
        set({ isNavbarOpen: isOpen }),
      setAvatarMap: (newAvatarMap: State["avatarMap"]) => {
        set({ avatarMap: newAvatarMap });
      },
    }),
    {
      name: "state",
      partialize: (state) => ({
        pathURL: state.pathURL,
        isNavbarOpen: state.isNavbarOpen,
      }),
    },
  ),
);

const useStateStore = createSelectorHooks(
  useStateStoreBase,
) as typeof useStateStoreBase & ZustandHookSelectors<Action & State>;

export const { useIsNavbarOpen, useUpdateNavbarState } = useStateStore;
export { useStateStore, useStateStoreBase };
