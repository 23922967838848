import { httpErrorHandler } from "@/api/api";
import { useEditPostMutation } from "@/api/queries/postsQueries";
import { Alert, AlertDescription } from "@/components/ui/alert/Alert";
import { Button } from "@/components/ui/button/Button";
import {
  AlertDialogBody,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/dialog/AlertDialog";
import { PostsI } from "@/types/posts";
import { motion } from "framer-motion";
import { AlertCircle } from "lucide-react";

export default function ActivatePost({
  post,
  onOpenChange,
}: {
  post: PostsI | undefined;
  onOpenChange: (value: boolean) => void;
}) {
  const { mutateAsync, isPending, isError, error } = useEditPostMutation();

  const onSubmit = () => {
    if (post) {
      mutateAsync({ id: post.id, active: !post.active }).then(() => {
        onOpenChange(false);
      });
    }
  };
  const { detail } = httpErrorHandler(error);

  return (
    <AlertDialogContent className={"max-w-[45ch]"}>
      <AlertDialogHeader>
        <AlertDialogTitle>Zmienić aktywność posta ?</AlertDialogTitle>
      </AlertDialogHeader>
      <AlertDialogBody className={"flex flex-col gap-4"}>
        <p className={"text-sx text-fg-secondary"}>
          Czy na pewno chcesz zmienić aktywność posta <b>{post?.name} ?</b>
        </p>

        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{
            opacity: isError ? 1 : 0,
            height: isError ? "auto" : 0,
          }}
          transition={{ duration: 0.2 }}
          className={"px-6 sm:px-4 py-4 overflow-hidden"}
        >
          <Alert variant={"destructive"}>
            <AlertCircle className={"h-3 w-3"} />
            <AlertDescription>{detail}</AlertDescription>
          </Alert>
        </motion.div>
      </AlertDialogBody>
      <AlertDialogFooter>
        <AlertDialogCancel variant={"ghost"} variantColor={"muted"}>
          Anuluj
        </AlertDialogCancel>
        <Button
          autoFocus
          variant={"flat"}
          variantColor={"destructive"}
          onClick={onSubmit}
          isLoading={{ state: isPending }}
        >
          Potwierdź
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  );
}
