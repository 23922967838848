import { MeetingsI } from "@/types/meetings";

export function mapMeetingsByDate(dates: Date[], meetings: MeetingsI[]) {
  const meetingsByDate: { [key: string]: MeetingsI[] } = {};

  dates.forEach((date) => {
    const dateString = date.toISOString();

    meetingsByDate[dateString] = meetings.filter((meeting) => {
      const meetingDate = new Date(meeting.startDate);

      return (
        meetingDate.getDate() === date.getDate() &&
        meetingDate.getMonth() === date.getMonth() &&
        meetingDate.getFullYear() === date.getFullYear()
      );
    });
  });

  return meetingsByDate;
}
