import { cn } from "@/lib/utils";
import { format, getDate, isToday, isValid } from "date-fns";
import pl from "date-fns/locale/pl";

interface CalendarContentNavDayProps {
  date: Date;
}

export default function CalendarHeaderDay({
  date,
}: CalendarContentNavDayProps) {
  const handleFormatDate = (date: Date): string => {
    if (isValid(date)) {
      return format(date, "EEEE, d MMMM yyyy", { locale: pl });
    }
    return "";
  };

  return (
    <div className={"flex flex-row gap4 items-center gap-2"}>
      <div
        className={cn(
          "rounded-md p-2 flex w-11 h-11 justify-center items-center",
          isToday(date)
            ? "bg-bg-brand text-fg-brand-on"
            : "bg-bg-muted text-fg-primary",
        )}
      >
        {getDate(date)}
      </div>
      <p className={"text-md text-fg-primary font-medium"}>
        {handleFormatDate(date)}
      </p>
    </div>
  );
}
