import { httpErrorHandler } from "@/api/api";
import { useGetAssignedMeetingsQuery as useGetMeetings } from "@/api/queries/meetingsQuery";
import CalendarContentHours from "@/components/features/calendar/layout/content/CalendarContentHours";
import CalendarContentRowLines from "@/components/features/calendar/layout/content/CalendarContentRowLines";
import CalendarContentTimeLine from "@/components/features/calendar/layout/content/CalendarContentTimeLine";
import Meeting from "@/components/features/meeting/Meeting";
import MeetingCalendarCard from "@/components/features/meeting/MeetingCalendarCard";
import { ToastAction } from "@/components/ui/toast/Toast";
import { useToast } from "@/components/ui/toast/useToast";
import { eventOverlap } from "@/utils/eventOverlap";
import { endOfDay, isToday, startOfDay } from "date-fns";
import { useEffect, useMemo, useRef } from "react";

interface CalendarContentDayProps {
  date: Date;
  containerHeight: number;
}

export default function CalendarContentDay({
  date,
  containerHeight,
}: CalendarContentDayProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <div
      className={"relative flex flex-row w-full"}
      style={{ height: `${containerHeight}px` }}
    >
      <CalendarContentHours />
      <CalendarContentRowLines
        space={containerHeight / 24}
        className={"absolute w-full h-full pl-10"}
      />
      <div className={"relative flex w-full"} ref={containerRef}>
        <CalendarContentTimeLine
          hide={!isToday(date)}
          containerHeight={containerHeight}
        />
        <CalendarContentDataDay date={date} containerHeight={containerHeight} />
      </div>
    </div>
  );
}

interface CalendarContentDataProps {
  date: Date;
  containerHeight: number;
}

function CalendarContentDataDay({
  date,
  containerHeight,
}: CalendarContentDataProps) {
  const { toast } = useToast();
  const queryParams = {
    after: startOfDay(date).toISOString(),
    before: endOfDay(date).toISOString(),
  };
  const {
    data: meetings = [],
    isError,
    error,
    refetch,
  } = useGetMeetings(queryParams);

  useEffect(() => {
    if (isError) {
      const { title, detail } = httpErrorHandler(error);
      toast({
        variant: "destructive",
        title: title,
        description: detail,
        action: (
          <ToastAction altText="Try again" onClick={() => refetch()}>
            Ponów
          </ToastAction>
        ),
      });
    }
  }, [error, isError, refetch, toast]);

  const styles = useMemo(() => eventOverlap(meetings), [meetings]);

  return meetings.map((meeting) => {
    return (
      <Meeting key={meeting.id} meeting={meeting} queryParams={queryParams}>
        <MeetingCalendarCard
          meeting={meeting}
          options={{
            containerHeight: containerHeight,
            style: styles[meeting.id],
          }}
        />
      </Meeting>
    );
  });
}
