import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar/Avatar";
import { UsersI } from "@/types/users";
import { getAvatar } from "@/utils/getAvarar";
import { getUserLvl } from "@/utils/getUserLvl";
import { User } from "lucide-react";

export default function UserCard({ user }: { user: UsersI }) {
  const { avatarURL, name, surname, lvl } = user;

  return (
    <div
      className={
        "flex justify-between h-fit items-center gap-3 py-2 rounded-sm group"
      }
    >
      <div className={"flex gap-3 items-center"}>
        {(avatarURL || avatarURL == null) && (
          <Avatar size={"sm"}>
            <AvatarImage src={getAvatar(avatarURL)} alt={"avatar"} />
            <AvatarFallback>
              <User className={"w-4 h-4"} />
            </AvatarFallback>
          </Avatar>
        )}
        <div className={"flex flex-col gap-1"}>
          <h5 className={"w-full truncate text-md text-fg-primary"}>
            {name} {surname}
          </h5>
          <small className={"text-xs text-fg-muted"}>{getUserLvl(lvl)}</small>
        </div>
      </div>
    </div>
  );
}
