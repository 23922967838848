import { FilesI } from "@/types/files";
import { FileTypeE, checkFileType } from "@/utils/checkFileType";

type useMediaTypeProps = File | FilesI;

const imageMimeTypes = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/svg+xml",
  "image/webp",
];

const videoMimeTypes = [
  "video/mp4",
  "video/webm",
  "video/3gp",
  "video/ogg",
  "video/avi",
  "video/mpeg",
];
const audioMimeTypes = [
  "audio/mpeg",
  "audio/ogg",
  "audio/3gp",
  "audio/mp4",
  "audio/webm",
  "audio/flac",
  "audio/object",
];

const VidtackFilterFileType = (type: string, fileType: FileTypeE) => {
  const isVidstackVideo = videoMimeTypes.includes(type);
  const isVidstackAudio = audioMimeTypes.includes(type);
  const isVidstackImage = imageMimeTypes.includes(type);

  if (
    (fileType === FileTypeE.VIDEO && !isVidstackVideo) ||
    (fileType === FileTypeE.AUDIO && !isVidstackAudio) ||
    (fileType === FileTypeE.IMAGE && !isVidstackImage)
  ) {
    return FileTypeE.FILE;
  }
  return fileType;
};

export function getMediaType(file: useMediaTypeProps) {
  const isFileInstance = file instanceof File;
  const type = isFileInstance ? file.type : file.fileType;
  const fileType = checkFileType(type);
  const vidstackFileType = VidtackFilterFileType(type, fileType);

  return {
    fileType: fileType,
    vidstackFileType: vidstackFileType,
    mimeType: type,
  };
}
