import useFSEntryPermissions from "@/components/features/files/hooks/useFSEntryPermissions";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar/Avatar";
import { Button } from "@/components/ui/button/Button";
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer/Drawer";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { PermissionsI } from "@/types/files";
import { getAvatar } from "@/utils/getAvarar";
import {
  RadioGroup,
  RadioGroupItem,
} from "@components/ui/input/radio-group/RadioGroup";
import { Label } from "@radix-ui/react-label";
import { ChevronDown, UserX, Users } from "lucide-react";
import { useMemo, useState } from "react";

type PermissionLiEntityProps = {
  data: {
    id: number;
    title: string;
    detail?: string;
    avatarURL?: string;
  };
  permissions: PermissionsI;
  onRemove: () => void;
  onPermissionChange: (id: number, newValue: string) => void;
};

export default function PermissionLiEntity({
  data: { id, title, detail, avatarURL },
  permissions,
  onRemove,
  onPermissionChange,
}: PermissionLiEntityProps) {
  const breakpoint = useMediaQueryHook("sm");
  const { currentPermission, permissionsArray } = useFSEntryPermissions({
    permissions,
  });

  const [access, setAccess] = useState<string>(currentPermission.type);

  const accessOnChange = (value: string) => {
    onPermissionChange(id, value);
    setAccess(value);
  };

  const content = useMemo(() => {
    return (
      <li
        className={
          "flex justify-between h-fit items-center gap-3 py-2 rounded-sm group sm:cursor-pointer"
        }
      >
        <div className={"flex gap-3 items-center"}>
          {avatarURL !== undefined && (
            <Avatar size={"sm"}>
              <AvatarImage src={getAvatar(avatarURL)} alt="avatar" />
              <AvatarFallback>
                <Users className={"w-4 h-4"} />
              </AvatarFallback>
            </Avatar>
          )}
          <div className={"flex flex-col gap-1"}>
            {title && (
              <h5 className={"w-full truncate text-md text-fg-primary"}>
                {title}
              </h5>
            )}
            <small className={"text-xs text-fg-muted"}>
              {currentPermission.title}
            </small>
          </div>
        </div>
        {breakpoint ? (
          <ChevronDown
            className={"m-3 w-4 min-w-4 h-4 min-h-4 stroke-fg-muted"}
          />
        ) : (
          <DropdownMenuTrigger asChild>
            <Button
              className={"min-w-[18ch] w-[18ch]"}
              variant={"outline"}
              variantColor={"muted"}
              icon={<ChevronDown className={"ml-auto"} />}
              iconPosition={"right"}
              size={"sm"}
            >
              {currentPermission.title}
            </Button>
          </DropdownMenuTrigger>
        )}
      </li>
    );
  }, [breakpoint, currentPermission, title, detail, avatarURL]);

  if (breakpoint) {
    return (
      <Drawer>
        <DrawerTrigger asChild>{content}</DrawerTrigger>
        <DrawerContent>
          <DrawerHeader>
            <DrawerTitle>Poświadczenia</DrawerTitle>
          </DrawerHeader>
          <DrawerBody className={"flex flex-col gap-4"}>
            <RadioGroup value={access} onValueChange={accessOnChange}>
              {permissionsArray.map((permission) => (
                <div
                  key={permission.type}
                  className="flex items-center space-x-2"
                >
                  <RadioGroupItem
                    value={permission.type}
                    id={permission.type}
                  />
                  <Label htmlFor={permission.type}>{permission.title}</Label>
                </div>
              ))}
            </RadioGroup>
            <Button
              className={"w-fit"}
              variant={"flat"}
              variantColor={"destructive"}
              icon={<UserX />}
              iconPosition={"left"}
            >
              Usuń dostęp
            </Button>
          </DrawerBody>
          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    );
  }

  return (
    <DropdownMenu>
      {content}
      <DropdownMenuContent align={"end"}>
        <DropdownMenuRadioGroup value={access} onValueChange={accessOnChange}>
          {permissionsArray.map((permission) => (
            <DropdownMenuRadioItem
              key={permission.type}
              value={permission.type}
            >
              {permission.title}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem
            className={"text-fg-destructive"}
            onClick={onRemove}
          >
            <UserX className={"w-4 h-4"} />
            Usuń dostęp
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
