import Empty from "@/assets/images/empty.svg?react";
import { TableCell, TableRow } from "@/components/ui/table/Table";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";

export default function TanstackTableEmpty({ colSpan }: { colSpan: number }) {
  const breakpoint = useMediaQueryHook("md");

  const content = (
    <div className={"flex h-full w-full flex-col items-center justify-center"}>
      <Empty className={"h-[200px]"} />
      <h2 className={"mt-2 text-2xl font-bold"}>Brak wyników.</h2>
      <p
        className={
          "text-muted-foreground h-6.5 mb-6 mt-2 w-[35ch] whitespace-pre-wrap text-center text-xs"
        }
      >
        Brak wierszy, wprowadź nowe dane lub zmień opcje wyszukiwania{" "}
      </p>
    </div>
  );

  if (breakpoint) {
    return content;
  }
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>{content}</TableCell>
    </TableRow>
  );
}
