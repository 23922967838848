import { ButtonProps, buttonVariants } from "@/components/ui/button/Button";
import { cn } from "@/lib/utils";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import { VariantProps, cva } from "class-variance-authority";
import * as React from "react";

const Tabs = TabsPrimitive.Root;

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      "inline-flex h-fit items-center justify-center gap-0.5 rounded-md bg-bg text-fg-muted",
      className,
    )}
    {...props}
  />
));
TabsList.displayName = TabsPrimitive.List.displayName;

const tabsTriggerVariant = cva("", {
  variants: {
    variant: {
      brand:
        "data-[state=active]:bg-bg-brand-subtle data-[state=active]:text-fg-brand data-[state=active]:shadow-sm",
      muted:
        "data-[state=active]:bg-bg-container data-[state=active]:text-fg-primary border-1 data-[state=active]:border-border border-transparent data-[state=active]:shadow-sm",
    },
  },
  defaultVariants: {
    variant: "muted",
  },
});

export type tabsTriggerProps = React.ComponentPropsWithoutRef<
  typeof TabsPrimitive.Trigger
> &
  VariantProps<typeof tabsTriggerVariant> &
  Omit<ButtonProps, "variant">;
const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  tabsTriggerProps
>(({ children, className, variant, iconPosition, icon, ...props }, ref) => {
  return (
    <TabsPrimitive.Trigger
      ref={ref}
      className={cn(
        buttonVariants({
          variant: "ghost",
          size: "md",
          variantColor: "muted",
          iconPosition: iconPosition,
        }),
        tabsTriggerVariant({ variant }),
        className,
      )}
      {...props}
    >
      {icon && iconPosition === "left" && icon}
      {icon && iconPosition === "only" ? icon : children}
      {icon && iconPosition === "right" && icon}
    </TabsPrimitive.Trigger>
  );
});
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn("focus-visible:outline-none", className)}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent };
