import CreatePost from "@/components/features/manage/manage-posts/CreatePost";
import { Button } from "@/components/ui/button/Button";
import { Dialog } from "@/components/ui/dialog/Dialog";
import { Label } from "@/components/ui/label/Label";
import { FileUp, ImagePlus, Video } from "lucide-react";
import { Fragment, useState } from "react";

export default function HomeCreatePost() {
  const [openPostAdd, setOpenPostAdd] = useState<boolean>(false);
  const [forceFocus, setForceFocus] = useState<
    "input" | "image" | "video" | "file" | undefined
  >(undefined);

  return (
    <Fragment>
      <Dialog open={openPostAdd} onOpenChange={setOpenPostAdd}>
        <CreatePost onOpenChange={setOpenPostAdd} />
      </Dialog>
      <div
        className={
          "flex flex-col p-4 bg-bg-container border-border border-1 rounded-lg gap-5 w-full"
        }
      >
        <Label className={"text-xl text-fg-primary font-semibold"}>
          Utwórz Post
        </Label>
        <div className={"flex flex-col gap-3"}>
          <button
            onClick={() => {
              setForceFocus("input");
              setOpenPostAdd(true);
            }}
            className={
              "inline-flex min-h-13 w-full px-4 py-2 rounded-md border-1 bg-bg-container text-md ring-offset-bg text-fg-muted hover:bg-bg-element/20 focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-ring transition-color duration-100 cursor-pointer"
            }
          >
            Napisz wiadomość...
          </button>
          <div className={"flex w-full gap-2 justify-end"}>
            <Button
              type={"button"}
              size={"sm"}
              variant={"flat"}
              variantColor={"muted"}
              icon={<ImagePlus />}
              iconPosition={"only"}
              onClick={() => {
                setForceFocus("image");
                setOpenPostAdd(true);
              }}
            />
            <Button
              type={"button"}
              size={"sm"}
              variant={"flat"}
              variantColor={"muted"}
              icon={<Video />}
              iconPosition={"only"}
              onClick={() => {
                setForceFocus("video");
                setOpenPostAdd(true);
              }}
            />
            <Button
              type={"button"}
              size={"sm"}
              variant={"flat"}
              variantColor={"muted"}
              icon={<FileUp />}
              iconPosition={"only"}
              onClick={() => {
                setForceFocus("file");
                setOpenPostAdd(true);
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
