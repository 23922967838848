import { httpErrorHandler } from "@/api/api";
import { useEditConversationMutation } from "@/api/queries/chatQueries";
import { Button } from "@/components/ui/button/Button";
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form/Form";
import { Input } from "@/components/ui/input/Input";
import { useToast } from "@/components/ui/toast/useToast";
import { ChatEditSchema, ChatEditSchemaType } from "@/schemas/chat.schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

type ChatConversationEditNameFormProps = {
  id: number;
  name: string;
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
};

export default function ChatEditName({
  id,
  name,
  open,
  onOpenChange,
}: ChatConversationEditNameFormProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { toast } = useToast();
  const { mutateAsync, isPending } = useEditConversationMutation();

  const form = useForm<ChatEditSchemaType>({
    values: {
      id: id,
      name: name,
    },
    mode: "onBlur",
    resolver: yupResolver(ChatEditSchema),
  });

  const handleClose = () => {
    onOpenChange(false);
    form.reset();
  };

  const onSubmit = (data: ChatEditSchemaType) => {
    mutateAsync(data)
      .then((response) => {
        navigate(".", {
          replace: true,
          state: { ...location.state, name: response.name },
        });
        handleClose();
      })
      .catch((error) => {
        const { title, detail } = httpErrorHandler(error);
        toast({
          variant: "destructive",
          title: title,
          description: detail,
        });
      });
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className={"max-w-[35ch]"}>
        <DialogHeader>
          <DialogTitle>Edytuj nazwę</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <DialogBody>
            <form
              className={"flex w-full flex-col gap-3 py-1"}
              onSubmit={form.handleSubmit(onSubmit)}
              noValidate
            >
              <FormField
                name={"name"}
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input {...field} placeholder={"Nazwa konwersacji"} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </DialogBody>
          <DialogFooter>
            <Button
              onClick={handleClose}
              type={"button"}
              variant={"flat"}
              variantColor={"muted"}
              className={"sm:w-full"}
            >
              Anuluj
            </Button>
            <Button
              onClick={form.handleSubmit(onSubmit)}
              isLoading={{
                state: isPending,
              }}
              type={"submit"}
              variant={"flat"}
              variantColor={"brand"}
              className={"sm:w-full"}
            >
              Zapisz
            </Button>
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
