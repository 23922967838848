import { cn } from "@/lib/utils";
import { Time } from "@vidstack/react";
import { Video } from "lucide-react";

type VideoIndicatorProps = {
  variant?: "default" | "minimal";
};

export function VideoIndicator({ variant }: VideoIndicatorProps) {
  return (
    <div
      className={cn(
        "absolute flex gap-1 items-center bg-bg-container/50 px-2 py-0.5 rounded-md text-fg-primary media-buffering:text-fg-muted",
        variant === "minimal"
          ? "left-2/4 -translate-x-2/4 bottom-2"
          : "top-2 left-2",
      )}
    >
      <Video className={"w-4 h-4"} />
      <Time type={"duration"} className={"time text-xs font-medium"} />
    </div>
  );
}
