import CreateMeeting from "@/components/features/calendar/form/CreateMeeting";
import GroupElementHeader from "@/components/features/groups/layout/GroupElementHeader";
import { Button } from "@/components/ui/button/Button";
import { Dialog } from "@/components/ui/dialog/Dialog";
import { Label } from "@/components/ui/label/Label";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { useCredentials } from "@/store/authStore";
import { Book, CalendarPlus, ClipboardSignature, Folder } from "lucide-react";
import { Fragment, RefObject, useState } from "react";

type GroupQuickMenuProps = {
  className?: string;
  refs: {
    lessonsRef: RefObject<HTMLDivElement>;
    filesRef: RefObject<HTMLDivElement>;
    tasksRef: RefObject<HTMLDivElement>;
  };
};

export default function GroupQuickMenu({
  className,
  refs,
}: GroupQuickMenuProps) {
  const [openAddMeeting, setOpenAddMeeting] = useState<boolean>(false);
  const { lvl } = useCredentials();

  const scrollToRef = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Fragment>
      <Dialog open={openAddMeeting} onOpenChange={setOpenAddMeeting}>
        <CreateMeeting onOpenChange={setOpenAddMeeting} />
      </Dialog>

      <div
        className={cn(
          "flex flex-col overflow-auto rounded-lg border-border border-1 bg-bg-container",
          className,
        )}
      >
        <GroupElementHeader label={"Menu"} />
        {lvl > 0 && (
          <div className={"flex flex-col gap-2 px-4 pb-4"}>
            <Label
              className={cn("sm:flex hidden font-medium text-fg-muted mb-1")}
            >
              Szybkie akcje
            </Label>
            <Button
              variant={"outline"}
              variantColor={"muted"}
              icon={<CalendarPlus />}
              iconPosition={"left"}
              className={"justify-start"}
              onClick={() => setOpenAddMeeting(true)}
            >
              Dodaj spotkanie
            </Button>
            <Button
              disabled
              variant={"outline"}
              variantColor={"muted"}
              icon={<Book />}
              iconPosition={"left"}
              className={"justify-start"}
              onClick={() => console.log("Add lesson")}
            >
              Dodaj lekcję
            </Button>
            <Button
              disabled
              variant={"outline"}
              variantColor={"muted"}
              icon={<ClipboardSignature />}
              iconPosition={"left"}
              className={"justify-start"}
              onClick={() => console.log("Add task")}
            >
              Dodaj zadanie
            </Button>
          </div>
        )}
        <div className={"hidden sm:flex flex-col gap-2 px-4 pb-4"}>
          <Label
            className={cn(
              "font-medium text-fg-muted mb-1",
              lvl > 0 || "hidden",
            )}
          >
            Menu
          </Label>
          <Button
            variant={"outline"}
            variantColor={"muted"}
            icon={<Folder />}
            iconPosition={"left"}
            className={"justify-start"}
            onClick={() => scrollToRef(refs.filesRef)}
          >
            Pliki
          </Button>
          <Button
            variant={"outline"}
            variantColor={"muted"}
            icon={<Book />}
            iconPosition={"left"}
            className={"justify-start"}
            onClick={() => scrollToRef(refs.lessonsRef)}
          >
            Lekcje
          </Button>
          <Button
            variant={"outline"}
            variantColor={"muted"}
            icon={<ClipboardSignature />}
            iconPosition={"left"}
            className={"justify-start"}
            onClick={() => scrollToRef(refs.tasksRef)}
          >
            Zadania
          </Button>
        </div>
      </div>
    </Fragment>
  );
}
