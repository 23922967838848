import { axiosInstance } from "@/api/api";
import { UserAddSchemaType } from "@/schemas/user.schema";
import { GroupI } from "@/types/groups";
import { UserI, UsersI } from "@/types/users";

type UserEditT = {
  id: number;
  name?: string;
  surname?: string;
  email?: string;
  lvl?: number;
  tel?: string;
  birthdate?: Date;
  active?: boolean;
  force_password_change?: boolean;
};

export const getManagedUsers = async (): Promise<UsersI[]> => {
  return await axiosInstance.get("/api/user/getManaged");
};

export const getUsers = async ({
  page,
  pageSize,
  name,
}: {
  page?: number;
  pageSize?: number;
  name?: string;
}): Promise<UsersI[]> => {
  return await axiosInstance.get("/api/user/getAll", {
    params: { name, page, pageSize },
  });
};

export const getUser = async (id?: number): Promise<UserI> => {
  return await axiosInstance.get("/api/user", { params: { id } });
};

export const getUserGroup = async (id?: number): Promise<GroupI[]> => {
  return await axiosInstance.get("/api/user/getGroups", { params: { id } });
};

export const postUser = async (data: UserAddSchemaType): Promise<UserI> => {
  return await axiosInstance.put("/api/user", data);
};

export const editUser = async (data: UserEditT): Promise<UserI> => {
  return await axiosInstance.post("/api/user", data);
};

export const deleteUser = async (data: { id: number }): Promise<string> => {
  return await axiosInstance.delete("/api/user", { data: data });
};

export const deleteUserAvatar = async (data: {
  authToken?: string;
}): Promise<string> => {
  return await axiosInstance.delete("/api/user/avatar", { data: data });
};

export const postUserAvatar = async ({
  b64,
  authToken,
}: {
  b64: Blob;
  authToken?: string;
}): Promise<{ avatarURL: string }> => {
  const formData = new FormData();
  formData.set("avatar", b64);
  if (authToken) {
    formData.set("authToken", authToken);
  }
  return await axiosInstance.post("/api/user/avatarUpload", formData);
};

export { UserEditT };
