import { Input, InputActionButton } from "@/components/ui/input/Input";
import { useDebounceValue } from "@/hooks/useDebounceValue";
import { Search, X } from "lucide-react";
import { useEffect, useState } from "react";

export default function TanstackTableSearch({
  setGlobalFilter,
}: {
  setGlobalFilter: (value: string) => void;
}) {
  const [searchValue, setSearchValue] = useState<string>("");
  const searchDebouncedValue = useDebounceValue(searchValue, 400);

  useEffect(() => {
    setGlobalFilter(searchDebouncedValue);
  }, [searchDebouncedValue, setGlobalFilter]);

  return (
    <Input
      startContent={<Search className={"mx-2"} />}
      endContent={
        <InputActionButton
          disabled={!searchValue}
          onClick={() => setSearchValue("")}
          icon={<X />}
        />
      }
      className={"max-w-[20rem]"}
      placeholder={"Wyszykaj..."}
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
    />
  );
}
