import { Button } from "@/components/ui/button/Button";
import { FilesI } from "@/types/files";
import getFileUrl from "@/utils/getFileUrl";
import { Download } from "lucide-react";
import { useRef } from "react";

type MediaFileViewerProps = {
  file: FilesI | File;
};

export default function MediaFileViewer({ file }: MediaFileViewerProps) {
  const anchorRef = useRef<HTMLAnchorElement>(null);

  const handleClick = () => {
    anchorRef.current?.click();
  };

  return (
    <div className="flex flex-col justify-center gap-3 items-center w-full h-full">
      <p className="text-fg-muted">Podgląd niedostępny</p>
      {"id" in file ? (
        <>
          <a ref={anchorRef} href={getFileUrl(file?.id)} className="hidden" />
          <Button
            icon={<Download />}
            iconPosition={"left"}
            onClick={handleClick}
          >
            Pobierz
          </Button>
        </>
      ) : null}
    </div>
  );
}
