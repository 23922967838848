import { filterByT } from "@/components/features/manage/tanstack-table/TanstackTable";
import { Button } from "@/components/ui/button/Button";
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "@/components/ui/drawer/Drawer";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { Separator } from "@/components/ui/separator/Separator";
import {
  Sheet,
  SheetBody,
  SheetContent,
  SheetTrigger,
} from "@/components/ui/sheet/Sheet";
import { Toggle } from "@/components/ui/toggle/Toggle";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { Table as TableT } from "@tanstack/react-table";
import {
  ArrowDownIcon,
  ArrowUpDown,
  ArrowUpIcon,
  ColumnsIcon,
  ListFilter,
} from "lucide-react";
import { useMemo } from "react";

export default function TanstackTableViewOptions<TData>({
  table,
  filterBy,
}: {
  table: TableT<TData>;
  filterBy?: filterByT[];
}) {
  const breakpointMd = useMediaQueryHook("md");
  const breakpointSm = useMediaQueryHook("sm");

  const triggerButton = useMemo(() => {
    if (breakpointMd) {
      return (
        <Button
          variant={"outline"}
          variantColor={"muted"}
          icon={<ListFilter />}
          iconPosition={"left"}
        >
          Filtry
        </Button>
      );
    } else {
      return (
        <Button
          variant={"outline"}
          variantColor={"muted"}
          icon={<ColumnsIcon />}
          iconPosition={"left"}
        >
          Kolumny
        </Button>
      );
    }
  }, [breakpointMd]);

  const content = (
    <div className={"flex flex-col gap-6"}>
      {filterBy && (
        <div className={"flex flex-col gap-3"}>
          <h3 className={"text-lg font-medium"}>Filtry</h3>
          <Separator />
          <div className={"flex flex-col gap-5"}>
            {filterBy.map(({ id, title, options }) => {
              const column = table.getColumn(id);

              let selectedValues: Set<string | number | boolean> = new Set();
              const filterValue = column?.getFilterValue();

              if (Array.isArray(filterValue) && filterValue.length > 0) {
                if (typeof filterValue[0] === "string") {
                  selectedValues = new Set<string>(filterValue as string[]);
                } else if (typeof filterValue[0] === "number") {
                  selectedValues = new Set<number>(filterValue as number[]);
                } else if (typeof filterValue[0] === "boolean") {
                  selectedValues = new Set<boolean>(filterValue as boolean[]);
                }
              }

              return (
                <div key={id} className={"flex flex-col gap-4"}>
                  <div className={"flex justify-between items-center h-10"}>
                    <p className={"text-md font-md"}>{title}</p>
                    {selectedValues.size > 0 && (
                      <Button
                        variant={"flat"}
                        variantColor={"muted"}
                        size={"sm"}
                        onClick={() => column?.setFilterValue(undefined)}
                      >
                        Wyczyść
                      </Button>
                    )}
                  </div>
                  <div className={"flex flex-wrap gap-2"}>
                    {options.map((option) => {
                      const isSelected = selectedValues.has(option.value);
                      return (
                        <Toggle
                          key={option.value.toString()}
                          data-state={isSelected ? "on" : "off"}
                          aria-checked={isSelected}
                          variant={"outline"}
                          onClick={() => {
                            if (isSelected) {
                              selectedValues.delete(option.value);
                            } else {
                              selectedValues.add(option.value);
                            }
                            const filterValues = Array.from(selectedValues);
                            column?.setFilterValue(
                              filterValues.length ? filterValues : undefined,
                            );
                          }}
                        >
                          {option.label.toUpperCase()}
                        </Toggle>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className={"flex flex-col gap-3"}>
        <h3 className={"text-lg font-medium"}>Kolumny</h3>
        <Separator />
        <div className={"flex flex-wrap gap-2"}>
          {table
            .getAllColumns()
            .filter(
              (column) =>
                typeof column.accessorFn !== "undefined" && column.getCanHide(),
            )
            .map((column) => {
              const isSelected = column.getIsVisible();
              return (
                <Toggle
                  key={column.id}
                  data-state={isSelected ? "on" : "off"}
                  aria-checked={isSelected}
                  className={"capitalize"}
                  onClick={() => {
                    column.toggleVisibility();
                  }}
                >
                  {column?.columnDef?.meta?.toString().toUpperCase() ?? ""}
                </Toggle>
              );
            })}
        </div>
      </div>
      <div className={"flex flex-col gap-3"}>
        <h3 className={"text-lg font-medium"}>Sortowanie</h3>
        <Separator />
        <div className={"flex gap-2 flex-wrap"}>
          {table
            .getAllColumns()
            .filter(
              (column) =>
                typeof column.accessorFn !== "undefined" && column.getCanSort(),
            )
            .map((column) => {
              return (
                <Button
                  key={column.id}
                  variant={"outline"}
                  variantColor={"muted"}
                  size={"sm"}
                  className={"w-fit"}
                  iconPosition={"left"}
                  icon={
                    column.getCanSort() && column.getIsSorted() === "desc" ? (
                      <ArrowDownIcon aria-hidden="true" />
                    ) : column.getIsSorted() === "asc" ? (
                      <ArrowUpIcon aria-hidden="true" />
                    ) : (
                      <ArrowUpDown aria-hidden="true" />
                    )
                  }
                  onClick={() => {
                    column.toggleSorting(column.getIsSorted() === "asc");
                  }}
                >
                  {column?.columnDef?.meta?.toString().toUpperCase() ?? ""}
                </Button>
              );
            })}
        </div>
      </div>
    </div>
  );

  if (breakpointSm) {
    return (
      <Drawer>
        <DrawerTrigger asChild>{triggerButton}</DrawerTrigger>
        <DrawerContent>
          <div className={"flex flex-col gap-3 p-4"}>{content}</div>
        </DrawerContent>
      </Drawer>
    );
  }
  if (breakpointMd) {
    return (
      <Sheet>
        <SheetTrigger asChild>{triggerButton}</SheetTrigger>
        <SheetContent className={"max-w-[450px]"}>
          <SheetBody className={"p-4"}>{content}</SheetBody>
        </SheetContent>
      </Sheet>
    );
  }
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{triggerButton}</DropdownMenuTrigger>
      <DropdownMenuContent align={"end"} className={"w-[12rem]"}>
        <DropdownMenuLabel>Kolumny</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          {table
            .getAllColumns()
            .filter(
              (column) =>
                typeof column.accessorFn !== "undefined" && column.getCanHide(),
            )
            .map((column) => {
              return (
                <DropdownMenuCheckboxItem
                  key={column.id}
                  className="capitalize"
                  checked={column.getIsVisible()}
                  onCheckedChange={(value) => {
                    column.toggleVisibility(value);
                  }}
                  onSelect={(e) => e.preventDefault()}
                >
                  {column?.columnDef?.meta?.toString() ?? ""}
                </DropdownMenuCheckboxItem>
              );
            })}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
