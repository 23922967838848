enum FileTypeE {
  IMAGE = "image",
  VIDEO = "video",
  AUDIO = "audio",
  FILE = "file",
}

function checkFileType(fileType: string): FileTypeE {
  if (fileType.includes(FileTypeE.IMAGE)) {
    return FileTypeE.IMAGE;
  } else if (fileType.includes(FileTypeE.VIDEO)) {
    return FileTypeE.VIDEO;
  } else if (fileType.includes(FileTypeE.AUDIO)) {
    return FileTypeE.AUDIO;
  } else {
    return FileTypeE.FILE;
  }
}

export { FileTypeE, checkFileType };
