import { httpErrorHandler } from "@/api/api";
import { usePostUserMutation } from "@/api/queries/usersQueries";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog/Dialog";
import { Spinner } from "@/components/ui/spinner/Spinner";
import { cn } from "@/lib/utils";
import { UsersMassImportRowT } from "@/page/pages/manage/ManageUsersMassImport";
import { UserAddSchemaType } from "@/schemas/user.schema";
import { selectedRowsI } from "@/types/table";
import generatePassword from "@/utils/generatePassword";
import { Check, X } from "lucide-react";
import { Fragment, useEffect, useState } from "react";

export default function UserMassImport({
  data,
  onOpenChange,
}: {
  data: UsersMassImportRowT[];
  onOpenChange: (value: boolean) => void;
}) {
  const { mutateAsync, isPending } = usePostUserMutation();
  const [isFinished, setIsFinished] = useState<boolean>(false);

  const [selectedRows, setSelectedRows] = useState<
    selectedRowsI<UserAddSchemaType>[]
  >([]);
  const [successRows, setSuccessRows] = useState<
    selectedRowsI<UserAddSchemaType>[]
  >([]);

  useEffect(() => {
    setSelectedRows(
      data.map((row) => {
        const userEntity: UserAddSchemaType = {
          ...row,
          lvl: row.lvl,
          birthdate: new Date(row.birthdate),
          password: generatePassword(12),
        };
        return { data: userEntity, status: "isIdle" };
      }),
    );
    setIsFinished(false);
  }, [data]);

  const onClose = () => {
    onOpenChange(false);
    setIsFinished(false);
  };

  const onSubmit = async () => {
    const successfullyAddedUsers: selectedRowsI<UserAddSchemaType>[] = [];

    selectedRows.forEach((row) => {
      const rowIndex = selectedRows.findIndex((r) => r.data === row.data);

      // Set the status of the current row to 'isLoading'
      setSelectedRows((prevRows) => {
        const newRows = [...prevRows];
        newRows[rowIndex].status = "isPending";
        return newRows;
      });

      mutateAsync(row.data)
        .then(() => {
          successfullyAddedUsers.push({ ...row, status: "isSuccess" });

          // If the request is successful, set the status of the current row to 'isSuccess'
          setSelectedRows((prevRows) => {
            const newRows = [...prevRows];
            newRows[rowIndex].status = "isSuccess";
            return newRows;
          });
        })
        .catch((error) => {
          // If the request fails, set the status of the current row to 'isError'
          setSelectedRows((prevRows) => {
            const newRows = [...prevRows];
            newRows[rowIndex].status = "isError";
            newRows[rowIndex].error = error;
            return newRows;
          });
        });
    });

    setIsFinished(true);
    setSuccessRows(successfullyAddedUsers);
  };

  return (
    <DialogContent className={"max-w-[50ch]"}>
      <DialogHeader>
        <DialogTitle>Dodaj użytkowników</DialogTitle>
      </DialogHeader>
      <DialogBody className={"flex flex-col gap-4"}>
        <div className={"flex flex-col gap-1"}>
          {selectedRows.map(({ data, status, error }, index) => {
            const { detail } = httpErrorHandler(error);
            return (
              <div
                key={index}
                className={cn(
                  "border-border- flex h-8 items-center gap-2 border-b-1",
                  status === "isError" && "!text-fg-destructive",
                  status === "isSuccess" && "!text-fg-success",
                )}
              >
                <div className={"h-4 w-4"}>
                  {status === "isError" && <X className={"h-4 w-4"} />}
                  {status === "isPending" && <Spinner size={"sm"} />}
                  {status === "isSuccess" && <Check size={"sm"} />}
                </div>
                <p className={"w-3 text-sm text-fg-muted"}>{index + 1}.</p>
                <p className={"text-sm"}>
                  {data.name} {data.surname}
                </p>

                {status === "isError" && (
                  <Badge
                    variant={"destructive"}
                    size={"sm"}
                    hideDot
                    className={"ml-auto"}
                  >
                    {detail}
                  </Badge>
                )}
                {status === "isSuccess" && (
                  <Badge
                    variant={"muted"}
                    size={"sm"}
                    hideDot
                    className={"ml-auto"}
                  >
                    Pobierz kartę
                  </Badge>
                )}
              </div>
            );
          })}
        </div>
      </DialogBody>
      <DialogFooter>
        {isFinished ? (
          <Button variant={"ghost"} variantColor={"muted"} onClick={onClose}>
            Zamknij
          </Button>
        ) : (
          <Fragment>
            <Button
              variant={"ghost"}
              variantColor={"muted"}
              disabled={isPending}
              onClick={onClose}
            >
              Anuluj
            </Button>
            <Button
              type={"button"}
              variant={"flat"}
              variantColor={"brand"}
              onClick={onSubmit}
              isLoading={{ state: isPending, message: "Tworzenie..." }}
            >
              Dodaj
            </Button>
          </Fragment>
        )}
      </DialogFooter>
    </DialogContent>
  );
}
