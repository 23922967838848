import { NotificationsSheet } from "@/components/features/notifications/NotificationsSheet";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import { useCredentials } from "@/store/authStore";
import { format } from "date-fns";
import { Bell } from "lucide-react";
import { useState } from "react";

export default function HomeHeader() {
  const { name, surname } = useCredentials();
  const [time, setTime] = useState<string>(format(new Date(), "HH:mm"));

  setTimeout(() => {
    setTime(format(new Date(), "HH:mm"));
  }, 15000);

  return (
    <div
      className={
        "flex flex-row relative p-4 rounded-lg w-full bg-bg-container justify-between items-center border border-border"
      }
    >
      <div className={"flex flex-col gap-2"}>
        <h2 className={"text-fg-primary font-semibold text-3xl"}>{time}</h2>
        <Badge variant={"muted"} hideDot>
          {name} {surname}
        </Badge>
      </div>
      <div>
        <NotificationsSheet>
          <Button
            variant={"flat"}
            variantColor={"muted"}
            iconPosition={"left"}
            icon={<Bell />}
            size={"sm"}
          >
            Powiadomienia
          </Button>
        </NotificationsSheet>
      </div>
    </div>
  );
}
