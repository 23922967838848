import { useMeetingListContext } from "@/components/features/calendar/MeetingsList";
import {
  MeetingCard,
  MeetingCardSkeleton,
} from "@/components/features/meeting/MeetingCard";
import { InfiniteScroll } from "@/components/ui/infinite-scroll/InfiniteScroll";
import { cn } from "@/lib/utils";
import { Fragment, ReactNode } from "react";

type MeetingsListContentProps = {
  className?: string;
  children?: ReactNode;
};

export default function MeetingsListContent({
  className,
  children,
}: MeetingsListContentProps) {
  const { meetings, fetchNextPage, isLoading, isFetching, hasNextPage } =
    useMeetingListContext();

  const next = () => {
    if (fetchNextPage) {
      fetchNextPage();
    }
  };

  return (
    <div
      className={cn(
        "flex flex-col gap-2 overflow-y-auto overscroll-contain px-4 grow",
        className,
      )}
    >
      {meetings.map((meeting) => (
        <MeetingCard key={meeting.id} meeting={meeting} />
      ))}
      {isLoading && (
        <Fragment>
          <MeetingCardSkeleton />
          <MeetingCardSkeleton />
          <MeetingCardSkeleton />
        </Fragment>
      )}
      {hasNextPage !== undefined ? (
        <InfiniteScroll
          hasNextPage={hasNextPage}
          isLoading={isFetching}
          isFetching={isFetching}
          next={next}
          threshold={0.65}
          root={null}
        >
          {hasNextPage && <MeetingCardSkeleton />}
        </InfiniteScroll>
      ) : null}

      {children}
    </div>
  );
}
