import { ReactNode } from "react";

function BackgroundWrapper({ children }: { children: ReactNode }) {
  return (
    <div className={"absolute top-0 overflow-hidden w-full h-full"}>
      {children}
    </div>
  );
}

export { BackgroundWrapper };
