import {
  Sheet,
  SheetBody,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet/Sheet";
import React from "react";

interface NotificationSheetProps {
  children: React.ReactNode;
  side?: "left" | "right" | "top" | "bottom";
}

function NotificationsSheet({
  children,
  side,
  ...props
}: NotificationSheetProps) {
  return (
    <Sheet {...props}>
      <SheetTrigger asChild>{children}</SheetTrigger>
      <SheetContent className={"max-w-[450px]"} side={side}>
        <SheetHeader>
          <SheetTitle>Powiadomienia</SheetTitle>
        </SheetHeader>
        <SheetBody>
          <h1>Powiadomia są tymczasowo niedostępne</h1>
        </SheetBody>
      </SheetContent>
    </Sheet>
  );
}
NotificationsSheet.displayName = "NotificationSheet";

export { NotificationsSheet };
