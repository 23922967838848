import { httpErrorHandler } from "@/api/api";
import { usePostPasswordChangeMutation } from "@/api/queries/authQueries";
import Bubble from "@/assets/images/auth/bubble.svg?react";
import LoginId from "@/assets/images/auth/login-id-elements-img.svg?react";
import LoginPhone from "@/assets/images/auth/login-phone-img.svg?react";
import { BackgroundWrapper } from "@/components/ui/background-wrapper/BackgroundWrapper";
import { Button } from "@/components/ui/button/Button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form/Form";
import { Input, InputActionButton } from "@/components/ui/input/Input";
import { useGetURLParams } from "@/hooks/useGetURLParams";
import { cn } from "@/lib/utils";
import {
  PasswordChangeSchema,
  type PasswordChangeSchemaType,
} from "@/schemas/auth.schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { Eye, EyeOff } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

export default function PasswordChangePage() {
  const location = useLocation();
  const navigate = useNavigate();
  const authToken = useGetURLParams().get("t");

  const { mutateAsync, isPending } = usePostPasswordChangeMutation();

  const [InputMode1, setInputMode1] = useState<boolean>(false);
  const [InputMode2, setInputMode2] = useState<boolean>(false);

  const form = useForm<PasswordChangeSchemaType>({
    defaultValues: {
      password: "",
      confirmPassword: "",
      authToken: authToken,
    },
    mode: "all",
    resolver: yupResolver(PasswordChangeSchema),
  });

  const checkIfCanGoBack = (): boolean => {
    return location.key !== "default";
  };

  const onSubmit = async (data: PasswordChangeSchemaType) => {
    mutateAsync(data)
      .then(() => {
        if (authToken) navigate("/");
        else navigate("/settings");
      })
      .catch((error) => {
        const { detail } = httpErrorHandler(error, {
          detail: "Wystąpił błąd podczas zmiany hasła",
        });
        form.setError("password", {
          type: "manual",
          message: detail,
        });
        form.setError("confirmPassword", {
          type: "manual",
          message: detail,
        });
      });
  };

  return (
    <>
      <BackgroundWrapper>
        <LoginId
          className={
            "absolute -left-14 top-2/4 -translate-y-2/4 opacity-60 sm:hidden"
          }
        />
        <LoginPhone
          className={
            "absolute -right-14 top-2/4 -translate-y-2/4 opacity-60 sm:hidden"
          }
        />
        <Bubble
          className={"absolute -right-14 top-0 hidden opacity-60 sm:block"}
        />
      </BackgroundWrapper>
      <div
        className={
          "flex min-h-[100dvh] w-full items-center justify-center p-6 sm:items-end sm:p-0 sm:pt-6"
        }
      >
        <section
          className={
            "z-30 flex min-h-[400px] w-[clamp(380px,50dvw,420px)] flex-col items-center gap-7 overflow-auto rounded-2xl border-1 border-border bg-bg-container px-6 py-6 shadow-md-sharp-border sm:h-[85dvh] sm:w-full sm:rounded-b-none sm:border-0 sm:border-t-1 sm:px-4"
          }
        >
          <Form {...form}>
            <form
              className={"flex w-full flex-col gap-7"}
              onSubmit={form.handleSubmit(onSubmit)}
              noValidate
            >
              <h1 className={"py-3 text-4xl font-semibold text-fg-primary"}>
                Zmień hasło
              </h1>
              <fieldset className={"flex flex-col gap-2"}>
                <FormField
                  name={"password"}
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Hasło</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder={"Hasło"}
                          type={InputMode1 ? "text" : "password"}
                          autoComplete={"current-password"}
                          endContent={
                            <InputActionButton
                              icon={InputMode1 ? <EyeOff /> : <Eye />}
                              onClick={() => setInputMode1(!InputMode1)}
                            />
                          }
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name={"confirmPassword"}
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Hasło</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          type={InputMode2 ? "text" : "password"}
                          autoComplete={"current-password"}
                          placeholder={"Powtórz hasło"}
                          endContent={
                            <InputActionButton
                              icon={InputMode2 ? <EyeOff /> : <Eye />}
                              onClick={() => setInputMode2(!InputMode2)}
                            />
                          }
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </fieldset>
              <footer className={"flex flex-row gap-3"}>
                <Button
                  type={"button"}
                  size={"lg"}
                  variant={"ghost"}
                  variantColor={"muted"}
                  onClick={() => navigate(-1)}
                  className={cn(checkIfCanGoBack() ? "w-full" : "hidden")}
                >
                  Cofnij
                </Button>
                <Button
                  type={"submit"}
                  size={"lg"}
                  isLoading={{
                    state: isPending,
                  }}
                  variant={"solid"}
                  variantColor={"brand"}
                  onClick={form.handleSubmit(onSubmit)}
                  className={"w-full"}
                >
                  Zapisz
                </Button>
              </footer>
            </form>
          </Form>
        </section>
      </div>
    </>
  );
}
