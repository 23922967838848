import TanstackTableActions from "@/components/features/manage/tanstack-table/TanstackTableActions";
import TanstackTableLoading from "@/components/features/manage/tanstack-table/TanstackTableLoading";
import TanstackTableRows from "@/components/features/manage/tanstack-table/TanstackTableRows";
import TanstackTableSearch from "@/components/features/manage/tanstack-table/TanstackTableSearch";
import TanstackTableViewOptions from "@/components/features/manage/tanstack-table/TanstackTableViewOptions";
import { Separator } from "@/components/ui/separator/Separator";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table/Table";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { useMainLayoutPageContext } from "@/page/MainLayout";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ComponentType, ReactElement, useEffect, useState } from "react";

import { TanstackTableFacetedFilters } from "./TanstackTableFacetedFilters";

export type tableAddGroupT = {
  id: number;
  label: string;
  icon?: ReactElement;
  func?: () => void;
};

export type filterByT = {
  id: string;
  title: string;
  options: {
    label: string;
    value: string | number | boolean;
    icon?: ComponentType<{ className?: string }>;
  }[];
};

export type DataTablePropsT<TData, TValue> = {
  name?: string;
  columns: ColumnDef<TData, TValue>[];
  defaultColumnVisibility?: VisibilityState;
  data: TData[];
  isFetching?: boolean;
  isLoading?: boolean;
  addGroup: tableAddGroupT[];
  filterBy?: filterByT[];
  getSelectedRows?: (rows: TData[]) => void;
};

export function TanstackTable<TData, TValue>({
  name,
  columns,
  defaultColumnVisibility = {},
  data,
  getSelectedRows,
  addGroup,
  filterBy,
  isLoading,
}: DataTablePropsT<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    defaultColumnVisibility,
  );
  const [showShadow, setShowShadow] = useState<boolean>(false);

  const breakpoint = useMediaQueryHook("md");
  const { ref } = useMainLayoutPageContext();

  useEffect(() => {
    const element = ref?.current;
    if (element) {
      element.addEventListener("scroll", () => {
        if (element.scrollTop > 44) {
          setShowShadow(true);
        } else {
          setShowShadow(false);
        }
      });
    }
  }, [ref]);

  const handleDropRowsClick = () => {
    getSelectedRows?.(
      table.getSelectedRowModel().rows.map((row) => row.original),
    );
  };
  const table = useReactTable<TData>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    //Row selection
    enableRowSelection: true,
    // onRowSelectionChange: setRowSelection,
    //Columns visibility
    onColumnVisibilityChange: setColumnVisibility,
    //Global filter
    enableFilters: true,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    //Column filters
    onColumnFiltersChange: setColumnFilters,
    //Sorting
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      columnVisibility,
      globalFilter,
      columnFilters,
    },
  });

  return (
    <div
      className={cn(
        "relative flex gap-4 w-full  flex-col",
        breakpoint ? null : "h-full",
      )}
    >
      <header
        className={cn(
          "sticky top-0 z-10 flex flex-col gap-3 p-4 rounded-xl bg-bg-container border-border border-1",
          showShadow ? "shadow-md" : null,
        )}
      >
        <div className={"flex justify-between items-center w-full"}>
          <h2 className={"font-semibold text-xl"}>{name}</h2>
          <div className={"flex gap-3"}>
            {breakpoint ? null : (
              <TanstackTableSearch setGlobalFilter={setGlobalFilter} />
            )}

            <TanstackTableViewOptions table={table} filterBy={filterBy} />
          </div>
        </div>
        <Separator />
        <div className={"flex justify-between gap-2"}>
          {breakpoint ? (
            <TanstackTableSearch setGlobalFilter={setGlobalFilter} />
          ) : (
            <div className="flex shrink-0 items-center space-x-2">
              {filterBy &&
                filterBy.map(({ id, title, options }) => {
                  return (
                    <TanstackTableFacetedFilters
                      key={id}
                      column={table.getColumn(id)}
                      title={title}
                      options={options}
                    />
                  );
                })}
            </div>
          )}
          <TanstackTableActions
            addGroup={addGroup}
            isSelection={table.getSelectedRowModel().rows.length > 0}
            removeRows={handleDropRowsClick}
          />
        </div>
      </header>
      {breakpoint ? (
        <div className={"flex flex-col h-full gap-2"}>
          {isLoading ? (
            <TanstackTableLoading table={table} />
          ) : (
            <TanstackTableRows table={table} colSpan={columns.length} />
          )}
        </div>
      ) : (
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow
                key={headerGroup.id}
                className={"hover:bg-bg-container"}
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead className={"px-3 py-1"} key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TanstackTableLoading table={table} />
            ) : (
              <TanstackTableRows table={table} colSpan={columns.length} />
            )}
          </TableBody>
        </Table>
      )}
    </div>
  );
}
