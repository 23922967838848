import { cva } from "class-variance-authority";

const dialogContentProps = cva("", {
  variants: {
    size: {
      sm: "w-[clamp(0px,20rem,100%)]",
      md: "w-[clamp(0px,28rem,100%)]",
      lg: "w-[clamp(0px,36rem,100%)]",
      "2xl": "w-[clamp(0px,42rem,100%)]",
      "3xl": "w-[clamp(0px,48rem,100%)]",
      "4xl": "w-[clamp(0px,64rem,100%)]",
      full: "w-full",
    },
  },
  defaultVariants: {
    size: "3xl",
  },
});
export { dialogContentProps };
