import { cn } from "@/lib/utils";
import { VariantProps, cva } from "class-variance-authority";
import React from "react";

const badgeVariants = cva(
  "relative inline-flex w-fit h-fit whitespace-nowrap gap-2 font-medium truncate items-center hover:opacity-80 transition-opacity duration-200",
  {
    variants: {
      variant: {
        brand: "[&>span]:bg-fg-brand bg-bg-brand-subtle text-fg-brand",
        brandFilled: "[&>span]:bg-fg-brand-on bg-bg-brand text-fg-brand-on",
        muted: "[&>span]:bg-fg-muted bg-bg-muted-subtle text-fg-muted",
        outline:
          "[&>span]:bg-fg-primary bg-transparent text-fg-primary border-1 border-border",
        accent: "[&>span]:bg-fg-accent bg-bg-accent-subtle text-fg-accent",
        success: "[&>span]:bg-fg-success bg-bg-success-subtle text-fg-success",
        warning: "[&>span]:bg-bg-warning bg-bg-warning-subtle text-fg-warning",
        destructive:
          "[&>span]:bg-bg-destructive bg-bg-destructive-subtle text-fg-destructive",
      },
      size: {
        sm: "px-2 py-0.5 text-xs rounded-sm [&>span]:w-[6px] [&>span]:h-[6px]",
        md: "px-3 py-1 text-xs rounded-sm [&>span]:w-[8px] [&>span]:h-[8px]",
      },
    },
    defaultVariants: {
      size: "sm",
      variant: "brand",
    },
  },
);

interface badgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  children: React.ReactNode;
  className?: string;
  hideDot?: boolean;
}

const Badge = React.forwardRef<HTMLDivElement, badgeProps>(
  ({ children, className, variant, size, hideDot = false, ...props }, ref) => {
    return (
      <div
        role={"button"}
        ref={ref}
        className={cn(badgeVariants({ size, variant }), className)}
        {...props}
      >
        {hideDot || <span className={"rounded-full shrink-0"} />}
        {children}
      </div>
    );
  },
);
Badge.displayName = "Badge";
export { Badge, badgeVariants };
