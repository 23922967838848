import {
  EditMeetingPropsT,
  GetMeetingsPropsT,
  PostMeetingPropsT,
  PostMeetingSchedulePropsT,
  deleteMeeting,
  editMeeting,
  getAssignedMeetings,
  getMeeting,
  postMeeting,
  postMeetingSchedule,
} from "@/api/endpoints/meetings";
import { queryClient } from "@/api/query-client";
import { MeetingI, MeetingsI } from "@/types/meetings";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";

export const useGetAssignedMeetingsQuery = (params: GetMeetingsPropsT) => {
  return useQuery({
    queryKey: ["meetings", params],
    queryFn: () => getAssignedMeetings(params),
  });
};

export const useGetAssignedMeetingsInfiniteQuery = (
  queryParams: GetMeetingsPropsT,
) => {
  return useInfiniteQuery({
    queryKey: ["meetingsInfinite", queryParams],
    queryFn: ({ pageParam }) => {
      return getAssignedMeetings(pageParam);
    },
    initialPageParam: {
      page: 0,
      pageSize: 20,
      name: undefined,
      sortOrder: "ASC",
      ...queryParams,
    },
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length < lastPageParam.pageSize) {
        return undefined;
      }
      return { ...lastPageParam, page: lastPageParam.page + 1 };
    },
  });
};

export const useGetMeetingQuery = (id: number) => {
  return useQuery({
    queryKey: ["meetings", id],
    queryFn: () => getMeeting(id),
  });
};

export const getMeetingPrefetch = ({
  id,
  initialData,
}: {
  id: number;
  initialData: MeetingI;
}) => {
  queryClient.prefetchQuery({
    queryKey: ["meetings", id],
    queryFn: () => getMeeting(id),
    initialData: initialData,
    staleTime: 60000,
  });
};

export const usePostMeetingMutation = () => {
  return useMutation({
    mutationFn: (data: PostMeetingPropsT) => postMeeting(data),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["meetings"] });
      queryClient.invalidateQueries({ queryKey: ["meetingsInfinite"] });
    },
    mutationKey: ["addMeeting"],
  });
};

export const usePostScheduleMeetingMutation = () => {
  return useMutation({
    mutationFn: (data: PostMeetingSchedulePropsT) => postMeetingSchedule(data),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["meetings"] }),
    mutationKey: ["addMeeting"],
  });
};

export const useEditMeetingMutation = (queryParams?: {
  before: string;
  after: string;
}) => {
  return useMutation({
    mutationFn: (data: EditMeetingPropsT) => editMeeting(data),
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: ["meetings", data.id] });
      await queryClient.cancelQueries({ queryKey: ["meetings", queryParams] });
      const previousMeetingData = queryClient.getQueryData<MeetingI>([
        "meetings",
        data.id,
      ]);
      const previousMeetingsData = queryClient.getQueryData<MeetingsI[]>([
        "meetings",
        queryParams,
      ]);
      // queryClient.setQueryData(["meetings", data.id], data);
      queryClient.setQueryData(
        ["meetings", queryParams],
        (curr: MeetingsI[]) => {
          if (!curr) return;
          curr.map((entity) => (data.id === entity.id ? data : entity));
        },
      );

      return { previousMeetingData, previousMeetingsData };
    },
    onError: (err, newMeeting, context) => {
      if (context) {
        queryClient.setQueryData(
          ["meetings", context?.previousMeetingData?.id],
          context.previousMeetingData,
        );
        queryClient.setQueryData(
          ["meetings", queryParams],
          context.previousMeetingsData,
        );
      }
    },
    onSettled: (data) => {
      queryClient.invalidateQueries({ queryKey: ["meetings", data?.id] });
      queryClient.invalidateQueries({ queryKey: ["meetings", queryParams] });
    },
  });
};

export const useDeleteMeetingMutation = (queryParams: {
  before: string;
  after: string;
}) => {
  return useMutation({
    mutationFn: (id: number) => deleteMeeting(id),
    onMutate: async (id: number) => {
      await queryClient.cancelQueries({ queryKey: ["meetings"] });
      const previousData = queryClient.getQueryData<MeetingsI[]>([
        "meetings",
        queryParams,
      ]);

      queryClient.setQueryData(["meetings", queryParams], (prev: MeetingsI[]) =>
        prev.filter((m: MeetingsI) => m.id !== id),
      );
      return { previousData };
    },
    onError: (err, id, context) => {
      if (context) {
        queryClient.setQueryData(
          ["meetings", queryParams],
          context.previousData,
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["meetings"] });
    },
  });
};
