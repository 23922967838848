import { selectedRowsI } from "@/types/table";
import { Dispatch, SetStateAction } from "react";

type ProcessMultipleMutationsPropsT<TData> = {
  selectedRows: selectedRowsI<TData>[];
  setSelectedRows: Dispatch<SetStateAction<selectedRowsI<TData>[]>>;
  mutateAsync: (data: { id: number }) => Promise<any>;
};

async function ProcessMultipleMutations<TData extends { id: number }>({
  selectedRows,
  setSelectedRows,
  mutateAsync,
}: ProcessMultipleMutationsPropsT<TData>) {
  for (const row of selectedRows) {
    const rowIndex = selectedRows.findIndex((r) => r.data.id === row.data.id);

    // Set the status of the current row to 'isLoading'
    setSelectedRows((prevRows) => {
      const newRows = [...prevRows];
      newRows[rowIndex].status = "isPending";
      return newRows;
    });
    await mutateAsync({ id: row.data.id })
      .then(() => {
        // If the request is successful, set the status of the current row to 'isSuccess'
        setSelectedRows((prevRows) => {
          const newRows = [...prevRows];
          newRows[rowIndex].status = "isSuccess";
          return newRows;
        });
      })
      .catch((error) => {
        // If the request fails, set the status of the current row to 'isError'
        setSelectedRows((prevRows) => {
          const newRows = [...prevRows];
          newRows[rowIndex].status = "isError";
          newRows[rowIndex].error = error;
          return newRows;
        });
      });
  }
}
export { ProcessMultipleMutations };
