import { useStateStoreBase } from "@/store/stateStore";
import { MessageConfirmationI, MessageI } from "@/types/chat";
import {
  ZustandHookSelectors,
  createSelectorHooks,
} from "auto-zustand-selectors-hook";
import { create } from "zustand";

type State = {
  isConnected: boolean;
  isLoading: boolean;
  isDataLoading: boolean;
  isError: boolean;
  replyingTo: MessageI | undefined;
  last: number | undefined;
  hasPreviousPage: boolean;
  newest: number | undefined;
  messages: MessageI[];
};

type Action = {
  setDefault: () => void;
  setIsConnected: (isConnected: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsDataLoading: (isDataLoading: boolean) => void;
  setIsError: (isError: boolean) => void;
  setReplyingTo: (replyingTo: MessageI | undefined) => void;
  setLast: (last: number) => void;
  setHasPreviousPage: (hasPreviousPage: boolean) => void;
  setNewest: (newest: number) => void;
  appendMessages: (messages: MessageI[]) => void;
  prependMessages: (messages: MessageI[]) => void;
  confirmMessage: (res: MessageConfirmationI) => void;
  deleteMessage: (message: { id: number; content: string }) => void;
};

const useChatBase = create<State & Action>((set) => ({
  isConnected: false,
  isLoading: true,
  isDataLoading: false,
  isError: false,
  replyingTo: undefined,
  last: undefined,
  hasPreviousPage: true,
  newest: undefined,
  messages: [],
  setIsConnected: (value: boolean) => set({ isConnected: value }),
  setIsLoading: (value: boolean) => set({ isLoading: value }),
  setIsDataLoading: (value: boolean) => set({ isDataLoading: value }),
  setIsError: (value: boolean) => set({ isError: value }),
  setReplyingTo: (replyingTo: MessageI | undefined) => set({ replyingTo }),
  setLast: (last: number) => set({ last }),
  setHasPreviousPage: (hasPreviousPage: boolean) => set({ hasPreviousPage }),
  setNewest: (newest: number) => set({ newest }),
  deleteMessage: ({ id, content }) => {
    set(({ messages }) => ({
      messages: messages.map((message) =>
        message.id === id ? { ...message, content: content } : message,
      ),
    }));
  },
  confirmMessage: ({ action, id, tempUUID, content }) => {
    switch (action) {
      case "sent":
        set(({ messages }) => ({
          messages: messages.map((message) =>
            message.tempUUID === tempUUID
              ? { ...message, id: id, tempUUID: undefined }
              : message,
          ),
          newest: id,
        }));
        break;
      case "deleted":
        set(({ messages }) => ({
          messages: messages.map((message) =>
            message.id === id
              ? {
                  ...message,
                  type: "deleted",
                  content: content as string,
                }
              : message,
          ),
        }));
        break;
      case "failed":
        set(({ messages }) => ({
          messages: messages.map((message) =>
            message.tempUUID === tempUUID
              ? {
                  ...message,
                  type: "error",
                  content: content as string,
                  tempUUID: undefined,
                }
              : message,
          ),
        }));
        break;
    }
  },
  appendMessages: (newMessages: MessageI[]) =>
    set(({ messages }) => {
      const copyNewMessages = [...newMessages];
      const avatars = useStateStoreBase.getState().avatarMap;
      if (avatars) {
        copyNewMessages.map((message) => {
          const senderId = message.sender?.id;
          let senderAvatar = undefined;
          if (senderId) {
            senderAvatar = avatars.get(senderId);
          }

          if (senderId && senderAvatar) {
            return {
              ...message,
              sender: {
                ...message.sender,
                avatarURL: senderAvatar,
              },
            };
          }
          return message;
        });
        console.log(copyNewMessages);
      }
      return {
        messages: [...messages, ...copyNewMessages],
      };
    }),
  prependMessages: (newMessages: MessageI[]) =>
    set(({ messages }) => {
      const copyNewMessages = [...newMessages];
      const avatars = useStateStoreBase.getState().avatarMap;
      if (avatars) {
        copyNewMessages.map((message) => {
          const senderId = message.sender?.id;
          let senderAvatar = undefined;
          if (senderId) {
            senderAvatar = avatars.get(senderId);
          }
          if (senderId && senderAvatar) {
            return {
              ...message,
              sender: {
                ...message.sender,
                avatarURL: senderAvatar,
              },
            };
          }
          return message;
        });
      }
      return {
        messages: [...copyNewMessages, ...messages],
      };
    }),
  setDefault: () =>
    set({
      isConnected: false,
      isLoading: true,
      isDataLoading: false,
      isError: false,
      replyingTo: undefined,
      last: undefined,
      hasPreviousPage: true,
      newest: undefined,
      messages: [],
    }),
}));

const useChatStore = createSelectorHooks(useChatBase) as typeof useChatBase &
  ZustandHookSelectors<State & Action>;
export { useChatBase, useChatStore };
