import { coordinatesRegex } from "@lib/regex";
import { InferType, number, object, string } from "yup";

const AddLocationSchema = object({
  name: string()
    .required("Nazwa jest wymagana")
    .min(2, "Nazwa jest zbyt krótka (min 2 znaki)")
    .max(20, "Nazwa jest zbyt długa (max 20 znaków)")
    .trim(),
  desc: string().max(100, "Opis jest zbyt długi (max 100 znaków)").optional(),
  coordinates: string()
    .matches(coordinatesRegex, {
      message: "Niepoprawne współrzędne",
    })
    .required("Współrzędne są wymagane"),
});

type AddLocationSchemaType = InferType<typeof AddLocationSchema>;

const EditLocationSchema = object({
  id: number().required(),
  name: string()
    .required("Nazwa jest wymagana")
    .min(2, "Nazwa jest zbyt krótka (min 2 znaki)")
    .max(20, "Nazwa jest zbyt długa (max 20 znaków)")
    .trim(),
  desc: string().max(100, "Opis jest zbyt długi (max 100 znaków)").optional(),
  coordinates: string()
    .matches(coordinatesRegex, {
      message: "Niepoprawne współrzędne",
    })
    .required("Współrzędne są wymagane"),
});

type EditLocationSchemaType = InferType<typeof EditLocationSchema>;

export { AddLocationSchema, EditLocationSchema };
export type { AddLocationSchemaType, EditLocationSchemaType };
