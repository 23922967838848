import { axiosInstance } from "@/api/api";
import { GetMeetingsPropsT } from "@/api/endpoints/meetings";
import { AssignedGroupI, GroupI, GroupsI } from "@/types/groups";
import { MeetingsI } from "@/types/meetings";

type memberChangesT = {
  userIds: number[];
  add?: boolean;
  remove?: boolean;
};
type EditGroupT = {
  id: number;
  name?: string;
  desc?: string;
  memberChanges?: memberChangesT[];
};
type AddGroupT = {
  name: string;
  desc?: string;
};
type GroupUsersT = {
  id: number;
  lvl: number;
  name: string;
  surname: string;
  avatarURL: string;
  email: string;
};

export const getGroups = async (params?: {
  page?: number;
  pageSize?: number;
  name?: string;
}): Promise<GroupsI[]> => {
  return await axiosInstance.get("/api/group/getAll", {
    params: params,
  });
};

export const getAssignedGroups = async (params?: {
  page?: number;
  pageSize?: number;
  name?: string;
}): Promise<AssignedGroupI[]> => {
  return await axiosInstance.get("/api/group/getAssigned", {
    params: params,
  });
};

export const getGroup = async (id?: number): Promise<GroupI> => {
  return await axiosInstance.get("/api/group", { params: { id } });
};

export const getGroupUsers = async (id?: number): Promise<GroupUsersT[]> => {
  return await axiosInstance.get("/api/group/getUsers", { params: { id } });
};

export const getGroupMeetings = async (
  params: GetMeetingsPropsT,
): Promise<MeetingsI[]> => {
  return await axiosInstance.get("/api/group/getMeetings", {
    params: params,
  });
};

export const addGroup = async (data: AddGroupT): Promise<GroupI> => {
  return await axiosInstance.put("/api/group", data);
};

export const editGroup = async (data: EditGroupT): Promise<GroupI> => {
  return await axiosInstance.post("/api/group", data);
};

export const deleteGroup = async (data: { id: number }): Promise<string> => {
  return await axiosInstance.delete("/api/group/", { data: data });
};

export type { AddGroupT, EditGroupT, GroupUsersT };
