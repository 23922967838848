import { DirIcon } from "@/components/features/files/FilesIcon";
import { useFSEntry } from "@/components/features/files/hooks/useFSEntry";
import { Button } from "@/components/ui/button/Button";
import { DropdownMenuTrigger } from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { Progress } from "@/components/ui/progress/Progress";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { cn } from "@/lib/utils";
import { DirectoryDirI } from "@/types/files";
import { UseMutationResult } from "@tanstack/react-query";
import { MoreVertical } from "lucide-react";
import {
  HTMLAttributes,
  ReactElement,
  forwardRef,
  isValidElement,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";

interface DirectoryBasicDataI {
  name: string;
}

type DirectoryCardProps = HTMLAttributes<HTMLDivElement> & {
  directory: DirectoryDirI | DirectoryBasicDataI;
  className?: string;
  menuButton?: ReactElement | boolean;
  variant?: "default" | "upload";
  progress?: number;
  status?: UseMutationResult["status"];
  error?: Error;
  abort?: () => void;
};

type DirectorySmallCardProps = DirectoryCardProps & {
  icon?: ReactElement;
};

const DirectoryCard = forwardRef<HTMLDivElement, DirectoryCardProps>(
  (props, ref) => {
    const navigate = useNavigate();

    const {
      directory,
      variant = "default",
      className,
      onDoubleClick,
      menuButton,
      progress,
      status,
      error,
      abort,
      ...rest
    } = props;

    const isDirectoryDirI = (
      dir: DirectoryDirI | DirectoryBasicDataI,
    ): dir is DirectoryDirI => {
      return (dir as DirectoryDirI).id !== undefined;
    };

    const { statusProps } = useFSEntry({
      variant: "directory",
      abort,
      status,
      progress,
      error,
    });

    const dirNavigate = useCallback(() => {
      if (isDirectoryDirI(directory)) {
        navigate(`/fs/dir/${directory.id}`);
      }
    }, [directory, navigate]);

    return (
      <div
        ref={ref}
        className={cn(
          "relative flex flex-col w-full gap-2 p-2 rounded-md ease-out duration-100 transition-all opacity-100 border-border border-1 hover:bg-bg-muted-subtle/45 focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-ring disabled:opacity-50",
          className,
        )}
        onDoubleClick={onDoubleClick || dirNavigate}
        {...rest}
      >
        <div className={"flex flex-col gap-2 w-full"}>
          <div className={"flex w-full h-10 gap-2 items-center"}>
            <DirIcon />
            <div className={"flex flex-col gap-0.5 justify-center w-full"}>
              <p className={"truncate select-none"}>{directory.name}</p>
              {status ? (
                <p className={statusProps.className}>{statusProps.children}</p>
              ) : null}
            </div>
            <div className={"flex shrink-0"}>
              {status ? (
                <Button
                  onClick={statusProps?.callback}
                  variant={"ghost"}
                  variantColor={"muted"}
                  icon={statusProps.icon}
                  iconPosition={"only"}
                  size={"sm"}
                />
              ) : null}
              {menuButton && isValidElement(menuButton) ? menuButton : null}
              {menuButton === true && (
                <DropdownMenuTrigger asChild>
                  <Button
                    variant={"ghost"}
                    variantColor={"muted"}
                    icon={<MoreVertical />}
                    iconPosition={"only"}
                    size={"sm"}
                  />
                </DropdownMenuTrigger>
              )}
            </div>
          </div>
          {variant === "upload" && status === "pending" ? (
            <Progress value={progress} valueClassName={"hidden"} />
          ) : null}
        </div>
      </div>
    );
  },
);
DirectoryCard.displayName = "DirectoryCard";

export const DirectorySmallCard = forwardRef<
  HTMLDivElement,
  DirectorySmallCardProps
>((props, ref) => {
  const { menuButton, className, icon, directory, ...rest } = props;

  return (
    <div
      ref={ref}
      className={cn(
        "relative p-2 rounded-md cursor-pointer ease-out duration-100 transition-all opacity-100 hover:bg-bg-muted-subtle/45 focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-ring disabled:opacity-50",
        className,
      )}
      {...rest}
    >
      <div className={"flex w-full h-10 gap-2 items-center"}>
        <div className={"flex gap-2 w-full items-center"}>
          {icon}
          <p className={"truncate select-none"}>{directory?.name}</p>
        </div>
        <div className={"shrink-0"}>{menuButton}</div>
      </div>
    </div>
  );
});
DirectorySmallCard.displayName = "DirectorySmallCard";

export function DirectorySmallCardSkeleton() {
  return (
    <div className={"relative p-2 rounded-md opacity-100"}>
      <div className={"flex w-full h-10 gap-2 items-center"}>
        <div className={"flex gap-2 w-full items-center"}>
          <Skeleton className="w-4 h-4 rounded-xs" />
          <Skeleton className="h-3 w-14 rounded-sm" />
        </div>
        <Skeleton className="h-10 w-10 rounded-sm" />
      </div>
    </div>
  );
}

export function DirectoryCardSkeleton() {
  return (
    <div
      className={
        "inline-flex w-full gap-2 p-2 items-center rounded-md bg-bg-container border-border border-1 opacity-50"
      }
    >
      <Skeleton className={"rounded-sm shrink-0 w-11 h-11"} />
      <div className={"w-full"}>
        <Skeleton className={"rounded-md w-14 h-3"} />
      </div>
    </div>
  );
}

export default DirectoryCard;
