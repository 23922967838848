import { cn } from "@/lib/utils";
import { VariantProps, cva } from "class-variance-authority";
import { ExternalLink } from "lucide-react";
import React, { forwardRef } from "react";

const hyperlinkVariants = cva(
  "relative flex gap-2 truncate cursor-pointer group items-center transition-all duration-400 ease-in-out hover:opacity-90 disabled:opacity-50 outline-none focus-visible:outline-none focus-visible:underline hover:underline disabled:cursor-not-allowed",
  {
    variants: {
      variantColor: {
        brand: "text-fg-brand",
        accent: "text-fg-accent",
        muted: "text-fg-primary",
      },
      size: {
        sm: "text-sm gap-1",
        md: "text-md",
        lg: "text-lg",
      },
    },
    defaultVariants: {
      variantColor: "brand",
      size: "md",
    },
  },
);

const hyperlinkIconVariants = cva("currentColor", {
  variants: {
    size: {
      sm: "h-3 w-3",
      md: "h-4 w-4",
      lg: "h-5 w-5",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

interface linkProps extends VariantProps<typeof hyperlinkVariants> {
  children: React.ReactNode;
  href: string;
  showLinkIcon?: boolean;
  openInNewTab?: boolean;
  className?: string;
}

const Link = forwardRef<HTMLAnchorElement, linkProps>(
  (
    {
      children,
      className,
      variantColor,
      size,
      showLinkIcon = false,
      openInNewTab,
      ...props
    },
    ref,
  ) => {
    return (
      <a
        ref={ref}
        target={openInNewTab ? "_blank" : undefined}
        className={cn(hyperlinkVariants({ variantColor, size }), className)}
        {...props}
      >
        {children}
        {showLinkIcon && (
          <ExternalLink className={hyperlinkIconVariants({ size })} />
        )}
      </a>
    );
  },
);

Link.displayName = "Link";

export { Link };
