import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar/Avatar";
import { cn } from "@/lib/utils";
import { VariantProps, cva } from "class-variance-authority";
import { Camera, ImagePlus, Pencil, User } from "lucide-react";
import React from "react";

/**
 * EditAvatar component.
 *
 * @component
 *
 * @param {object} props - Component props.
 * @param {string} props.src - Source for the avatar image.
 * @param {function} props.onClick - Function to handle click events.
 * @param {React.ReactNode} [props.fallback=<User />] - Fallback form-pages when the avatar image is not available.
 * @param {string} props.size - Size of the avatar. Can be 'sm' or 'md'.
 *
 * @returns {React.ElementType} Returns an avatar component with an edit icon overlay on hover.
 */

const AvatarEditVariants = cva("", {
  variants: {
    size: {
      sm: "w-13 h-13 rounded-md data-[overlay=true]:rounded-md",
      md: "w-14 h-14 rounded-lg data-[overlay=true]:rounded-lg",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

interface EditAvatarProps extends VariantProps<typeof AvatarEditVariants> {
  src: string | undefined;
  onClick: () => void;
  fallback?: React.ReactNode;
}

const EditAvatar = ({
  size,
  onClick,
  src,
  fallback = <User />,
}: EditAvatarProps) => {
  return (
    <div className={"relative"}>
      <div
        className={
          "z-10 bg-bg-muted w-6 h-6 flex justify-center items-center rounded-full absolute -bottom-1 -right-1"
        }
      >
        <Camera className={"w-3 h-3 text-fg-primary"} />
      </div>
      <Avatar
        className={cn(
          AvatarEditVariants({ size }),
          "group cursor-pointer relative",
        )}
        onClick={onClick}
      >
        <AvatarImage src={src} />
        <AvatarFallback>{fallback}</AvatarFallback>
        <div
          data-overlay={true}
          className={cn(
            AvatarEditVariants({ size }),
            "absolute hidden group-hover:flex backdrop-blur-[2px] items-center justify-center w-full h-full",
          )}
        >
          <ImagePlus className={"w-6 h-6 text-fg-primary"} />
        </div>
      </Avatar>
    </div>
  );
};

export { EditAvatar };
