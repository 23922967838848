import { FileIcon } from "@/components/features/files/FilesIcon";
import { useFSEntry } from "@/components/features/files/hooks/useFSEntry";
import { Button } from "@/components/ui/button/Button";
import { DropdownMenuTrigger } from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { Progress } from "@/components/ui/progress/Progress";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { cn } from "@/lib/utils";
import { FilesI } from "@/types/files";
import { UseMutationResult } from "@tanstack/react-query";
import { MoreVertical } from "lucide-react";
import {
  HTMLAttributes,
  ReactElement,
  forwardRef,
  isValidElement,
} from "react";

type FileCardProps = HTMLAttributes<HTMLDivElement> & {
  file: FilesI | File;
  variant?: "default" | "upload";
  progress?: number;
  abort?: () => void;
  retry?: () => void;
  status?: UseMutationResult["status"];
  error?: Error;
  className?: string;
  menuButton?: ReactElement | boolean;
};

const FileCard = forwardRef<HTMLDivElement, FileCardProps>((props, ref) => {
  const {
    file,
    variant = "default",
    progress,
    abort,
    retry,
    status,
    className,
    menuButton,
    error,
    ...rest
  } = props;

  const { statusProps } = useFSEntry({
    variant: "file",
    retry,
    abort,
    status,
    file,
    progress,
    error,
  });
  return (
    <div
      ref={ref}
      className={cn(
        "relative flex flex-col w-full gap-2 p-2 rounded-md ease-out duration-100 transition-all opacity-100 border-border border-1 hover:bg-bg-muted-subtle/45 focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-ring disabled:opacity-50",
        className,
      )}
      {...rest}
    >
      <div className={"flex flex-col gap-2 w-full"}>
        <div className={"flex w-full h-10 gap-2 items-center"}>
          <FileIcon />
          <div className={"flex flex-col gap-0.5 justify-center w-full"}>
            <p className={"truncate select-none"}>{file.name}</p>
            {status ? (
              <p className={statusProps.className}>{statusProps.children}</p>
            ) : null}
          </div>

          <div className={"flex shrink-0"}>
            {status ? (
              <Button
                onClick={statusProps?.callback}
                variant={"ghost"}
                variantColor={"muted"}
                icon={statusProps.icon}
                iconPosition={"only"}
                size={"sm"}
              />
            ) : null}
            {menuButton && isValidElement(menuButton) ? menuButton : null}
            {menuButton === true && (
              <DropdownMenuTrigger asChild>
                <Button
                  variant={"ghost"}
                  variantColor={"muted"}
                  icon={<MoreVertical />}
                  iconPosition={"only"}
                  size={"sm"}
                />
              </DropdownMenuTrigger>
            )}
          </div>
        </div>
        {variant === "upload" && status === "pending" ? (
          <Progress value={progress} valueClassName={"hidden"} />
        ) : null}
      </div>
    </div>
  );
});
FileCard.displayName = "FileCard";

export function FIleCardSkeleton() {
  return (
    <div
      className={
        "inline-flex w-full gap-2 p-2 items-center rounded-md bg-bg-container border-border border-1 opacity-50"
      }
    >
      <Skeleton className={"rounded-sm shrink-0 w-11 h-11"} />
      <div className={"w-full"}>
        <Skeleton className={"rounded-md w-14 h-3"} />
      </div>
    </div>
  );
}

export default FileCard;
