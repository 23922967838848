import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { ArrowRight } from "lucide-react";

export default function MeetingArrowTime({
  startDate,
  endDate,
  className,
}: {
  startDate: string;
  endDate: string;
  className?: string;
}) {
  return (
    <p
      className={cn(
        "flex flex-row h-fit gap-0.5 items-center text-fg-muted truncate text-[clamp(0.6rem,1.5vw,0.875rem)]",
        className,
      )}
    >
      {format(new Date(startDate), "H:mm")}
      <ArrowRight className={"w-3 h-3"} />
      {format(new Date(endDate), "H:mm")}
    </p>
  );
}
