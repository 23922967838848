import { cn } from "@/lib/utils";
import { formatDate } from "@/utils/date-format";
import { parseISO } from "date-fns";
import { useEffect, useState } from "react";

export default function ChatTimeToPresent({
  createdAt,
  className,
}: {
  createdAt?: string;
  className?: string;
}) {
  const [data, setData] = useState<string | null>(null);

  useEffect(() => {
    if (createdAt) {
      setData(formatDate(parseISO(createdAt)));
    }
  }, [createdAt]);

  return <p className={cn(className)}>{data}</p>;
}
