import { httpErrorHandler } from "@/api/api";
import { useInfiniteQueryResult } from "@/api/api-utils";
import { GroupUsersT } from "@/api/endpoints/groups";
import {
  useEditGroupMutation,
  useGetGroupUsersQuery,
} from "@/api/queries/groupsQueries";
import { useGetUsersInfiniteQuery } from "@/api/queries/usersQueries";
import { Button } from "@/components/ui/button/Button";
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog/Dialog";
import { Input, InputActionButton } from "@/components/ui/input/Input";
import {
  LiElement,
  LiElementSeparator,
} from "@/components/ui/list/ListElement";
import SelectDataList from "@/components/ui/list/SelectDataList";
import { Spinner } from "@/components/ui/spinner/Spinner";
import { useToast } from "@/components/ui/toast/useToast";
import { useDebounceValue } from "@/hooks/useDebounceValue";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { Search, X } from "lucide-react";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

type EditGroupUsersProps = {
  callback?: () => void;
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  id?: number;
};

export default function EditGroupUsers({
  id,
  callback,
  open,
  onOpenChange,
}: EditGroupUsersProps) {
  const breakpoint = useMediaQueryHook("sm");
  const { toast } = useToast();
  const rootRef = useRef<HTMLDivElement>(null);

  const [searchUserValue, setSearchUserValue] = useState<string>("");
  const searchUserDebouncedValue = useDebounceValue(searchUserValue, 400);
  const [selectedUsers, setSelectedUsers] = useState<GroupUsersT[]>([]);
  const { data: groupUsers = [], ...groupUsersResults } =
    useGetGroupUsersQuery(id);

  const getUsersInfiniteQuery = useGetUsersInfiniteQuery({
    name: searchUserDebouncedValue,
  });

  const {
    data: users,
    isEmpty: usersIsEmpty,
    noResults: usersNoResults,
  } = useInfiniteQueryResult(
    getUsersInfiniteQuery.data,
    searchUserDebouncedValue,
  );

  const { mutateAsync: groupEditMutation, isPending } = useEditGroupMutation();

  useEffect(() => {
    if (groupUsersResults.isSuccess && open) {
      setSelectedUsers(groupUsers);
    }
  }, [groupUsersResults.isSuccess, open]);

  useEffect(() => {
    if (groupUsersResults.isError) {
      handleClose();
      const { detail } = httpErrorHandler(groupUsersResults.error);
      toast({
        variant: "destructive",
        title: "Błąd",
        description: detail,
      });
    }
  }, [groupUsersResults.isError, groupUsersResults.error]);

  const onSubmit = async () => {
    if (!id) return;

    const usersToAdd = selectedUsers.filter(
      (user) => !groupUsers.some((groupUser) => groupUser.id === user.id),
    );
    const usersToRemove = groupUsers.filter(
      (user) =>
        !selectedUsers.some((selectedUser) => selectedUser.id === user.id),
    );

    const memberChanges = [];
    if (usersToAdd.length) {
      memberChanges.push({
        userIds: usersToAdd.map((user) => user.id),
        add: true,
      });
    }
    if (usersToRemove.length) {
      memberChanges.push({
        userIds: usersToRemove.map((user) => user.id),
        remove: true,
      });
    }

    await groupEditMutation({
      id: id,
      memberChanges: memberChanges,
    })
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        const { title, detail } = httpErrorHandler(error);
        toast({
          variant: "destructive",
          title: title,
          description: detail,
        });
      });
  };

  const handleClose = () => {
    onOpenChange(false);
    callback?.();
    setSearchUserValue("");
    setSelectedUsers([]);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        className={cn(!breakpoint && "h-[40rem] max-w-[45ch]")}
        onCloseAutoFocus={handleClose}
        onEscapeKeyDown={handleClose}
      >
        {isPending && (
          <div
            className={
              "absolute inset-0 z-20 m-auto flex flex-wrap items-center justify-center gap-3 bg-bg-container"
            }
          >
            <Spinner size={"lg"} />
            <h5 className={"font-medium"}>Trwa zapisywanie zmian...</h5>
          </div>
        )}
        {groupUsersResults.isFetching && (
          <div
            className={
              "absolute inset-0 z-20 m-auto flex flex-wrap items-center justify-center gap-3 bg-bg-container"
            }
          >
            <Spinner size={"lg"} />
            <h5 className={"font-medium"}>Trwa ładowanie danych...</h5>
          </div>
        )}
        <DialogHeader>
          <DialogTitle>Edytuj użytkowników grupy</DialogTitle>
        </DialogHeader>
        <DialogBody className={"flex h-auto flex-col gap-4"}>
          <div
            className={cn(
              "z-10 flex flex-col gap-2 bg-bg-container py-1",
              !breakpoint && "sticky top-0",
            )}
          >
            <Input
              placeholder={"Wyszukaj..."}
              value={searchUserValue}
              onChange={(e) => setSearchUserValue(e.target.value)}
              startContent={<Search className={"ml-2"} />}
              endContent={
                <InputActionButton
                  disabled={!searchUserValue.length}
                  onClick={() => setSearchUserValue("")}
                  icon={<X />}
                />
              }
            />
          </div>
          <div ref={rootRef}>
            <SelectDataList
              root={rootRef.current}
              query={getUsersInfiniteQuery}
              selected={selectedUsers}
              setSelected={setSelectedUsers}
              isEmpty={usersIsEmpty}
              isNoResults={usersNoResults}
              emptyMessage={"Wyszukaj użytkownika"}
              searchNoResultsMessage={"Nie znaleziono grupy o podanej nazwie"}
              loadingElementPros={{
                hasAvatar: true,
                hasDetail: true,
                hasTitle: true,
              }}
            >
              {!searchUserDebouncedValue && (
                <>
                  {selectedUsers.map((entity) => (
                    <LiElement
                      key={entity.id}
                      entity={entity}
                      data={{
                        title: entity.name + " " + entity.surname,
                        detail: entity.email,
                        avatarURL: entity.avatarURL,
                      }}
                    />
                  ))}
                  <LiElementSeparator />
                </>
              )}
              {users.map((entity) => (
                <LiElement
                  key={entity.id}
                  entity={entity}
                  data={{
                    title: entity.name + " " + entity.surname,
                    detail: entity.email,
                    avatarURL: entity.avatarURL,
                  }}
                />
              ))}
            </SelectDataList>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            onClick={handleClose}
            type={"button"}
            variant={"flat"}
            variantColor={"muted"}
            className={"sm:w-full"}
          >
            Anuluj
          </Button>
          <Button
            isLoading={{ state: isPending }}
            onClick={onSubmit}
            type={"submit"}
            variant={"flat"}
            variantColor={"brand"}
            className={"sm:w-full"}
          >
            Zapisz
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
