export function SerializeData(data: any) {
  if (typeof data !== "object") {
    return undefined;
  }
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [
      key,
      value === null ? undefined : value,
    ]),
  );
}
