import { GetMeetingsPropsT } from "@/api/endpoints/meetings";
import { useGetGroupMeetingsInfiniteQuery } from "@/api/queries/groupsQueries";
import { startOfDay } from "date-fns";
import { useState } from "react";

import CalendarCard from "../calendar/CalendarCard";

type GroupMeetingsProps = {
  groupId: number;
  className?: string;
};

export default function GroupMeetings({
  groupId,
  className,
}: GroupMeetingsProps) {
  const [queryParams, setQueryParams] = useState<GetMeetingsPropsT>({
    id: groupId,
    after: startOfDay(new Date()).toISOString(),
    sortOrder: "ASC",
    page: 0,
    pageSize: 15,
  });

  const query = useGetGroupMeetingsInfiniteQuery(queryParams);
  return (
    <CalendarCard
      setQueryParams={setQueryParams}
      query={query}
      className={className}
      defaultType={"schedule"}
    />
  );
}
