import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastIndicator,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@/components/ui/toast/Toast";
import { TOAST_REMOVE_DELAY, useToast } from "@/components/ui/toast/useToast";

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider duration={TOAST_REMOVE_DELAY}>
      {toasts.map(function ({
        id,
        title,
        description,
        action,
        variant,
        duration,
        ...props
      }) {
        return (
          <Toast key={id} duration={duration} {...props}>
            <ToastIndicator variant={variant} />
            <div className={"flex flex-col gap-1"}>
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && (
                <ToastDescription>{description}</ToastDescription>
              )}
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
