import MediaAudioViewer from "@/components/features/media/MediaAudioViewer";
import MediaFileViewer from "@/components/features/media/MediaFileViewer";
import MediaImageViewer from "@/components/features/media/MediaImageViewer";
import MediaVideoViewer from "@/components/features/media/MediaVideoViewer";
import { Button } from "@/components/ui/button/Button";
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "@/components/ui/dialog/Dialog";
import { Link } from "@/components/ui/link/Link";
import { cn } from "@/lib/utils";
import { DirectoryFileI } from "@/types/files";
import { FileTypeE } from "@/utils/checkFileType";
import getFileUrl from "@/utils/getFileUrl";
import { getMediaType } from "@/utils/getMediaType";
import { DialogTitle } from "@radix-ui/react-dialog";
import { Download } from "lucide-react";
import { Dispatch, SetStateAction, useMemo } from "react";

type MediaViewerProps = {
  file?: DirectoryFileI;
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>> | ((bool: boolean) => void);
};

export default function MediaViewer({
  file,
  open,
  onOpenChange,
}: MediaViewerProps) {
  const [content, styles] = useMemo(() => {
    if (!file) return ["Brak pliku", "h-full"];
    const { vidstackFileType } = getMediaType(file);

    switch (vidstackFileType) {
      case FileTypeE.IMAGE:
        return [<MediaImageViewer file={file} />, "h-full"];
      case FileTypeE.VIDEO:
        return [<MediaVideoViewer file={file} />, "h-full"];
      case FileTypeE.AUDIO:
        return [<MediaAudioViewer file={file} />, "h-fit"];
      default:
        return [<MediaFileViewer file={file} />, "h-fit"];
    }
  }, [file]);

  return (
    <MediaViewerWrapper
      open={open}
      onOpenChange={onOpenChange}
      file={file}
      className={styles}
    >
      {content}
    </MediaViewerWrapper>
  );
}

type MediaViewerWrapperProps = {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>> | ((bool: boolean) => void);
  file?: DirectoryFileI;
  className?: string;
  children: React.ReactNode;
};

export function MediaViewerWrapper({
  open,
  onOpenChange,
  file,
  className,
  children,
}: MediaViewerWrapperProps) {
  const { name, id } = useMemo(() => {
    if (!file) return { name: "", id: "" };

    const isFilesInstance = "id" in file;

    return { name: file.name, id: isFilesInstance ? file.id : undefined };
  }, [file]);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className={cn(className, "")}>
        <DialogHeader>
          <DialogTitle>{name}</DialogTitle>
        </DialogHeader>
        <DialogBody>{children}</DialogBody>
        <DialogFooter>
          <Link href={getFileUrl(id as string)}>
            <Button
              variant={"ghost"}
              variantColor={"muted"}
              className={"rounded-sm"}
              icon={<Download />}
              iconPosition={"only"}
            />
          </Link>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
