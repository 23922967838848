// Inspired by react-hot-toast library
import type {
  ToastActionElement,
  ToastProps,
} from "@/components/ui/toast/Toast";
import { VariantProps, cva } from "class-variance-authority";
import * as React from "react";

const TOAST_LIMIT = 3;
export const TOAST_REMOVE_DELAY = 5000;
const TOAST_PROMISE_RESOLVE_DELAY = 800;
const toastVariants = cva("relative w-9 h-9 flex-shrink-0 p-2 rounded-full", {
  variants: {
    variant: {
      default: null,
      success: "bg-bg-success-subtle [&>div]:bg-bg-success",
      warning: "bg-bg-warning-subtle [&>div]:bg-bg-warning ",
      destructive: "bg-bg-destructive-subtle [&>div]:bg-bg-destructive ",
      promise: "bg-transparent",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

type ToasterToast = ToastProps &
  VariantProps<typeof toastVariants> & {
    id: string;
    action?: ToastActionElement;
    duration?: number;
    title?: React.ReactNode;
    description?: React.ReactNode;
    promise?: Promise<any>;
    loading?: { title?: string; description?: string } | string;
    success?: { title?: string; description?: string } | string;
    error?: { title?: string; description?: string } | string;
  };

type ToasterPromiseToast = ToasterToast;

interface State {
  toasts: ToasterToast[];
}

type ToastBaseProps = Omit<
  ToasterToast,
  "id" | "loading" | "success" | "error"
>;
type ToastPromiseProps = Omit<ToasterPromiseToast, "id">;

const actionTypes = {
  ADD_TOAST: "ADD_TOAST",
  UPDATE_TOAST: "UPDATE_TOAST",
  DISMISS_TOAST: "DISMISS_TOAST",
  REMOVE_TOAST: "REMOVE_TOAST",
} as const;

let count = 0;

function genId() {
  count = (count + 1) % Number.MAX_VALUE;
  return count.toString();
}

type ActionType = typeof actionTypes;

type Action =
  | {
      type: ActionType["ADD_TOAST"];
      toast: ToasterToast | ToasterPromiseToast;
    }
  | {
      type: ActionType["UPDATE_TOAST"];
      toast: Partial<ToasterToast> | Partial<ToasterPromiseToast>;
    }
  | {
      type: ActionType["DISMISS_TOAST"];
      toastId?: ToasterToast["id"] | ToasterPromiseToast["id"];
    }
  | {
      type: ActionType["REMOVE_TOAST"];
      toastId?: ToasterToast["id"] | ToasterPromiseToast["id"];
    };

const toastTimeouts = new Map<string, ReturnType<typeof setTimeout>>();
const addToRemoveQueue = (toastId: string) => {
  if (toastTimeouts.has(toastId)) {
    return;
  }

  const timeout = setTimeout(() => {
    toastTimeouts.delete(toastId);
    dispatch({
      type: "REMOVE_TOAST",
      toastId: toastId,
    });
  }, TOAST_REMOVE_DELAY);

  toastTimeouts.set(toastId, timeout);
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "ADD_TOAST":
      return {
        ...state,
        toasts: [action.toast, ...state.toasts].slice(0, TOAST_LIMIT),
      };

    case "UPDATE_TOAST":
      return {
        ...state,
        toasts: state.toasts.map((t) =>
          t.id === action.toast.id ? { ...t, ...action.toast } : t,
        ),
      };

    case "DISMISS_TOAST": {
      const { toastId } = action;

      // ! Side effects ! - This could be extracted into a dismissToast() action,
      // but I'll keep it here for simplicity
      if (toastId) {
        addToRemoveQueue(toastId);
      } else {
        state.toasts.forEach((toast) => {
          addToRemoveQueue(toast.id);
        });
      }

      return {
        ...state,
        toasts: state.toasts.map((t) =>
          t.id === toastId || toastId === undefined
            ? {
                ...t,
                open: false,
              }
            : t,
        ),
      };
    }
    case "REMOVE_TOAST":
      if (action.toastId === undefined) {
        return {
          ...state,
          toasts: [],
        };
      }
      return {
        ...state,
        toasts: state.toasts.filter((t) => t.id !== action.toastId),
      };
  }
};

const listeners: Array<(state: State) => void> = [];

let memoryState: State = { toasts: [] };

function dispatch(action: Action) {
  memoryState = reducer(memoryState, action);
  listeners.forEach((listener) => {
    listener(memoryState);
  });
}

function toastCore() {
  const id = genId();
  const update = (props: ToasterToast | ToasterPromiseToast) =>
    dispatch({
      type: "UPDATE_TOAST",
      toast: { ...props, id },
    });
  const dismiss = () => dispatch({ type: "DISMISS_TOAST", toastId: id });

  return {
    id: id,
    dismiss,
    update,
  };
}

function toastPromise({
  promise,
  loading,
  success,
  error,
  ...props
}: ToastPromiseProps) {
  const { id, dismiss, update } = toastCore();
  const delayDuration = promise ? Infinity : TOAST_REMOVE_DELAY;

  const successMessage = {
    title: typeof success === "string" ? "" : success?.title,
    description: typeof success === "string" ? success : success?.description,
  };
  const errorMessage = {
    title: typeof error === "string" ? "" : error?.title,
    description: typeof error === "string" ? error : error?.description,
  };
  const loadingMessage = {
    title: typeof loading === "string" ? "" : loading?.title,
    description: typeof loading === "string" ? loading : loading?.description,
  };

  dispatch({
    type: "ADD_TOAST",
    toast: {
      ...props,
      id,
      variant: "promise",
      duration: delayDuration,
      title: loadingMessage.title,
      description: loadingMessage.description,
      open: true,
      onOpenChange: (open) => {
        if (!open) dismiss();
      },
    },
  });

  if (promise) {
    promise.then(
      () => {
        update({
          ...props,
          id,
          variant: "success",
          title: successMessage.title,
          description: successMessage.description,
          open: true,
        });
        setTimeout(() => {
          dismiss();
        }, TOAST_PROMISE_RESOLVE_DELAY);
      },
      () => {
        update({
          ...props,
          id,
          variant: "destructive",
          title: errorMessage.title,
          description: errorMessage.description,
          open: true,
        });
        setTimeout(() => {
          dismiss();
        }, TOAST_PROMISE_RESOLVE_DELAY);
      },
    );
  }

  return {
    id: id,
    dismiss,
    update,
  };
}

function toast({ ...props }: ToastBaseProps) {
  const { id, update, dismiss } = toastCore();

  dispatch({
    type: "ADD_TOAST",
    toast: {
      ...props,
      id,
      duration: TOAST_REMOVE_DELAY,
      open: true,
      onOpenChange: (open) => {
        if (!open) dismiss();
      },
    },
  });

  return {
    id: id,
    dismiss,
    update,
  };
}

function useToast() {
  const [state, setState] = React.useState<State>(memoryState);

  React.useEffect(() => {
    listeners.push(setState);
    return () => {
      const index = listeners.indexOf(setState);
      if (index > -1) {
        listeners.splice(index, 1);
      }
    };
  }, [state]);

  return {
    ...state,
    toast,
    toastPromise,
    dismiss: (toastId?: string) => dispatch({ type: "DISMISS_TOAST", toastId }),
  };
}

export { useToast, toast, toastPromise, toastVariants };
