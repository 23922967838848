import { httpErrorHandler } from "@/api/api";
import { useInfiniteQueryResult } from "@/api/api-utils";
import {
  useGetCreateConversationUsersInfiniteQuery,
  usePostConversationMutation,
} from "@/api/queries/chatQueries";
import { useGetAssignedGroupsInfiniteQuery } from "@/api/queries/groupsQueries";
import { Button } from "@/components/ui/button/Button";
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog/Dialog";
import { Input, InputActionButton } from "@/components/ui/input/Input";
import { LiElement } from "@/components/ui/list/ListElement";
import SelectDataList from "@/components/ui/list/SelectDataList";
import { Separator } from "@/components/ui/separator/Separator";
import { Spinner } from "@/components/ui/spinner/Spinner";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs/Tabs";
import { useToast } from "@/components/ui/toast/useToast";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "@/components/ui/toggle/ToggleGroup";
import { useDebounceValue } from "@/hooks/useDebounceValue";
import { AssignedGroupI } from "@/types/groups";
import { UsersI } from "@/types/users";
import { getUserLvl } from "@/utils/getUserLvl";
import { Search, X } from "lucide-react";
import { Dispatch, SetStateAction, useState } from "react";

type CreateConversationFormProps = {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
};

export default function CreateChat({
  open,
  onOpenChange,
}: CreateConversationFormProps) {
  const { toast } = useToast();
  const [searchValue, setSearchValue] = useState<string>("");
  const searchDebouncedValue = useDebounceValue(searchValue, 400);
  const [selectedUsers, setSelectedUsers] = useState<UsersI[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<AssignedGroupI[]>([]);

  const getUsersInfiniteQuery = useGetCreateConversationUsersInfiniteQuery({
    name: searchDebouncedValue,
  });

  const {
    data: users,
    isEmpty: usersIsEmpty,
    noResults: usersNoResults,
  } = useInfiniteQueryResult(getUsersInfiniteQuery.data, searchDebouncedValue);

  const getAssignedGroupsInfiniteQuery = useGetAssignedGroupsInfiniteQuery({
    name: searchDebouncedValue,
  });

  const {
    data: groups,
    isEmpty: groupsIsEmpty,
    noResults: groupsNoResults,
  } = useInfiniteQueryResult(
    getAssignedGroupsInfiniteQuery.data,
    searchDebouncedValue,
  );

  const { mutateAsync, isPending } = usePostConversationMutation();

  const onClose = () => {
    onOpenChange(false);
    setSelectedUsers([]);
    setSelectedGroups([]);
  };
  const onSubmit = async () => {
    const userIds = selectedUsers.map((user) => user.id);
    const groupIds = selectedGroups.map((group) => group.id);
    await mutateAsync({ userIds: userIds, groupIds: groupIds })
      .then((response) => {
        console.log(response);
        onClose();
      })
      .catch((error) => {
        const { title, detail } = httpErrorHandler(error);
        toast({
          title: title,
          description: detail,
          variant: "destructive",
        });
      });
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className={"max-w-[45ch] h-[600px] max-h-[700px]"}>
        {isPending && (
          <div
            className={
              "z-20 absolute inset-0 m-auto flex justify-center items-center gap-3 flex-wrap bg-bg-container"
            }
          >
            <Spinner size={"lg"} />
            <h5 className={"font-medium"}>Trwa tworzenie chatu...</h5>
          </div>
        )}
        <DialogHeader>
          <DialogTitle>Utwórz konwersację</DialogTitle>
        </DialogHeader>
        <DialogBody className={"flex flex-col gap-3"}>
          <Tabs className={"flex flex-col gap-3"} defaultValue={"users"}>
            <div className={"flex flex-col gap-4"}>
              <TabsList className={"grid w-full grid-cols-2"}>
                <TabsTrigger value={"users"}>Użytkownicy</TabsTrigger>
                <TabsTrigger value={"groups"}>Grupy</TabsTrigger>
              </TabsList>
              <Input
                placeholder={"Wyszukaj..."}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                startContent={<Search className={"ml-2"} />}
                endContent={
                  <InputActionButton
                    disabled={!searchValue.length}
                    onClick={() => setSearchValue("")}
                    icon={<X />}
                  />
                }
              />
              <ToggleGroup
                type={"multiple"}
                variant={"default"}
                className={"flex-wrap justify-start empty:hidden"}
                removable={true}
                value={[
                  ...selectedUsers.map((user) => `user-${user.id}`),
                  ...selectedGroups.map((group) => `group-${group.id}`),
                ]}
                onValueChange={(selectedIds) => {
                  const newSelectedUsers = users.filter((user) =>
                    selectedIds.includes(`user-${user.id.toString()}`),
                  );
                  const newSelectedGroups = groups.filter((group) =>
                    selectedIds.includes(`group-${group.id.toString()}`),
                  );
                  setSelectedUsers(newSelectedUsers);
                  setSelectedGroups(newSelectedGroups);
                }}
              >
                {selectedUsers.map(({ id, name, surname }) => (
                  <ToggleGroupItem
                    size={"sm"}
                    variant={"outline"}
                    key={`user-${id.toString()}`}
                    value={`user-${id.toString()}`}
                  >
                    {name + " " + surname.at(0) + "."}
                  </ToggleGroupItem>
                ))}
                {selectedGroups.map(({ id, name }) => (
                  <ToggleGroupItem
                    size={"sm"}
                    variant={"outline"}
                    key={`group-${id.toString()}`}
                    value={`group-${id.toString()}`}
                  >
                    {name}
                  </ToggleGroupItem>
                ))}
              </ToggleGroup>
            </div>
            <Separator />
            <TabsContent value={"users"}>
              <SelectDataList
                query={getUsersInfiniteQuery}
                selected={selectedUsers}
                setSelected={setSelectedUsers}
                isEmpty={usersIsEmpty}
                isNoResults={usersNoResults}
                emptyMessage={"Wyszukaj użytkownika"}
                searchNoResultsMessage={"Nie znaleziono uzytkownika"}
                loadingElementPros={{
                  hasAvatar: true,
                  hasDetail: false,
                  hasTitle: true,
                }}
              >
                {users.map((entity) => (
                  <LiElement
                    key={entity.id}
                    entity={entity}
                    data={{
                      title: entity.name + " " + entity.surname,
                      avatarURL: entity.avatarURL,
                      detail: getUserLvl(entity.lvl),
                    }}
                  />
                ))}
              </SelectDataList>
            </TabsContent>
            <TabsContent value={"groups"}>
              <SelectDataList
                query={getAssignedGroupsInfiniteQuery}
                selected={selectedGroups}
                setSelected={setSelectedGroups}
                isEmpty={groupsIsEmpty}
                isNoResults={groupsNoResults}
                emptyMessage={"Wyszukaj grupy"}
                searchNoResultsMessage={"Nie znaleziono grupy"}
                loadingElementPros={{
                  hasAvatar: true,
                  hasDetail: false,
                  hasTitle: true,
                }}
              >
                {groups.map((entity) => (
                  <LiElement
                    key={entity.id}
                    entity={entity}
                    data={{
                      title: entity.name,
                      avatarURL: "",
                    }}
                  />
                ))}
              </SelectDataList>
            </TabsContent>
          </Tabs>
        </DialogBody>
        <DialogFooter>
          <Button variant={"outline"} variantColor={"muted"} onClick={onClose}>
            Anuluj
          </Button>
          <Button
            variant={"flat"}
            variantColor={"brand"}
            disabled={selectedUsers.length === 0 && selectedGroups.length === 0}
            onClick={onSubmit}
          >
            Utwórz
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
