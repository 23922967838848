import type { CalendarDate } from "@internationalized/date";
import { DateValue, startOfYear } from "@internationalized/date";
import { useDateFormatter } from "@react-aria/i18n";
import type { PressEvent } from "@react-types/shared";
import { debounce } from "lodash";
import { MutableRefObject, useCallback, useEffect, useRef } from "react";
import { CalendarState } from "react-stately";
import scrollIntoView from "scroll-into-view-if-needed";

function addMonths(date: DateValue, months: number): DateValue {
  return date.add({ months });
}

function getYearRange(start?: DateValue, end?: DateValue): DateValue[] {
  const years: DateValue[] = [];
  if (!start || !end) {
    return years;
  }

  let current = startOfYear(start);

  while (current.compare(end) <= 0) {
    years.push(current);
    // Move to the first day of the next year
    current = startOfYear(current.add({ years: 1 }));
  }

  return years;
}

function getMonthsInYear(year: DateValue) {
  const firstMonth = startOfYear(year);
  const months = [firstMonth];

  while (months.length < 12) {
    const prevMonth = months[months.length - 1];

    months.push(addMonths(prevMonth, 1));
  }

  return months;
}

function areRectsIntersecting(rect1: DOMRect, rect2: DOMRect) {
  return (
    rect1 &&
    rect2 &&
    rect1.x < rect2.x + rect2.width &&
    rect1.x + rect1.width > rect2.x &&
    rect1.y < rect2.y + rect2.height &&
    rect1.y + rect1.height > rect2.y
  );
}

export type PickerValue = {
  value: string;
  label: string;
};
export interface CalendarPickerProps {
  state: CalendarState;
  date: CalendarDate;
  currentMonth: CalendarDate;
  isHeaderExpanded: boolean;
  setIsHeaderExpanded: (expanded: boolean) => void;
  headerRef: MutableRefObject<HTMLButtonElement | null>;
}

type ItemsRefMap = Map<number, HTMLElement>;
type CalendarPickerListType = "months" | "years";

const SCROLL_DEBOUNCE_TIME = 200;

export function useDatetimePicker({
  state,
  isHeaderExpanded,
  setIsHeaderExpanded,
  headerRef,
  ...props
}: CalendarPickerProps) {
  const { date } = props;

  const highlightRef = useRef<HTMLDivElement>(null);
  const yearsListRef = useRef<HTMLDivElement>(null);
  const monthsListRef = useRef<HTMLDivElement>(null);

  const monthsItemsRef = useRef<ItemsRefMap>();
  const yearsItemsRef = useRef<ItemsRefMap>();

  const monthDateFormatter = useDateFormatter({
    month: "long",

    timeZone: state.timeZone,
  });

  const yearDateFormatter = useDateFormatter({
    year: "numeric",
    timeZone: state.timeZone,
  });

  // Used for the year/month pickers
  const years = getYearRange(state.minValue, state.maxValue)?.map((y) => ({
    value: y.year,
    label: yearDateFormatter.format(y.toDate(state.timeZone)),
  }));

  const months = getMonthsInYear(date).map((m) => ({
    value: m.month,
    label: monthDateFormatter.format(m.toDate(state.timeZone)),
  }));

  function getItemsRefMap(
    itemsRef: React.MutableRefObject<ItemsRefMap | undefined>,
  ) {
    if (!itemsRef.current) {
      // Initialize the Map on first usage.
      itemsRef.current = new Map();
    }

    return itemsRef.current;
  }

  function getItemRef(
    node: HTMLElement | null,
    value: number,
    list: CalendarPickerListType,
  ) {
    const map = getItemsRefMap(
      list === "months" ? monthsItemsRef : yearsItemsRef,
    );

    if (node) {
      map.set(value, node);
    } else {
      map.delete(value);
    }
  }

  const handleListScroll = useCallback(
    (
      e: Event,
      highlightEl: HTMLElement | null,
      list: CalendarPickerListType,
    ) => {
      if (!(e.target instanceof HTMLElement)) return;

      const map = getItemsRefMap(
        list === "months" ? monthsItemsRef : yearsItemsRef,
      );

      const items = Array.from(map.values());

      const item = items.find((itemEl) => {
        const rect1 = itemEl.getBoundingClientRect();
        const rect2 = highlightEl?.getBoundingClientRect();

        if (!rect2) {
          return false;
        }

        return areRectsIntersecting(rect1, rect2);
      });

      const itemValue = Number(item?.getAttribute("data-value"));

      if (!itemValue) return;

      const date = state.focusedDate.set(
        list === "months" ? { month: itemValue } : { year: itemValue },
      );

      state.setFocusedDate(date);
    },
    [state, isHeaderExpanded],
  );

  // scroll to the selected month/year when the component is mounted/opened/closed
  useEffect(() => {
    scrollTo(date.month, "months", false);
    scrollTo(date.year, "years", false);
  }, [isHeaderExpanded]);

  useEffect(() => {
    // add scroll event listener to monthsListRef
    const monthsList = monthsListRef.current;
    const yearsList = yearsListRef.current;
    const highlightEl = highlightRef.current;

    if (!highlightEl) return;

    const debouncedHandleMonthsScroll = debounce(
      (e: Event) => handleListScroll(e, highlightEl, "months"),
      SCROLL_DEBOUNCE_TIME,
    );
    const debouncedHandleYearsScroll = debounce(
      (e: Event) => handleListScroll(e, highlightEl, "years"),
      SCROLL_DEBOUNCE_TIME,
    );

    monthsList?.addEventListener("scroll", debouncedHandleMonthsScroll);
    yearsList?.addEventListener("scroll", debouncedHandleYearsScroll);

    return () => {
      if (debouncedHandleMonthsScroll) {
        monthsList?.removeEventListener("scroll", debouncedHandleMonthsScroll);
      }
      if (debouncedHandleYearsScroll) {
        yearsList?.removeEventListener("scroll", debouncedHandleYearsScroll);
      }
    };
  }, [handleListScroll]);

  function scrollTo(
    value: number,
    list: CalendarPickerListType,
    smooth = true,
  ) {
    const mapListRef = list === "months" ? monthsItemsRef : yearsItemsRef;
    const listRef = list === "months" ? monthsListRef : yearsListRef;

    const map = getItemsRefMap(mapListRef);

    const node = map.get(value);

    if (!node) return;

    // scroll picker list to the selected item
    scrollIntoView(node, {
      scrollMode: "always",
      behavior: smooth ? "smooth" : "auto",
      boundary: listRef.current,
    });
  }

  const onPickerItemPressed = useCallback(
    (e: PressEvent, list: CalendarPickerListType) => {
      const target = e.target as HTMLElement;
      const value = Number(target.getAttribute("data-value"));

      if (!value) return;

      scrollTo(value, list);
    },
    [state],
  );

  const onPickerItemKeyDown = useCallback(
    (
      e: React.KeyboardEvent<HTMLElement>,
      value: number,
      list: CalendarPickerListType,
    ) => {
      const map = getItemsRefMap(
        list === "months" ? monthsItemsRef : yearsItemsRef,
      );

      const node = map.get(value);

      if (!node) return;

      let nextValue = value;

      switch (e.key) {
        case "ArrowDown":
          nextValue = value + 1;
          break;
        case "ArrowUp":
          nextValue = value - 1;
          break;
        case "Home":
          nextValue = 0;
          break;
        case "End":
          nextValue = months.length - 1;
          break;
        case "PageUp":
          nextValue = value - 3;
          break;
        case "PageDown":
          nextValue = value + 3;
          break;
        case "Escape":
        case "Enter":
        case " ":
          setIsHeaderExpanded?.(false);
          headerRef?.current?.focus();

          return;
      }

      const nextItem = map.get(nextValue);

      nextItem?.focus();
    },
    [state],
  );

  return {
    state,
    years,
    months,
    highlightRef,
    monthsListRef,
    yearsListRef,
    getItemRef,
    isHeaderExpanded,
    onPickerItemPressed,
    onPickerItemKeyDown,
  };
}
export type UseCalendarPickerReturn = ReturnType<typeof useDatetimePicker>;
