import { cn } from "@/lib/utils";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { VariantProps, cva } from "class-variance-authority";
import { Check } from "lucide-react";
import React from "react";

const checkboxVariants = cva(
  "peer relative cursor-pointer h-6 w-6 shrink-0 rounded-sm border-border border-1 bg-bg-container hover:bg-bg-muted-subtle transition-colors ring-offset-bg focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      variantColor: {
        brand:
          "data-[state=checked]:bg-bg-brand data-[state=checked]:text-fg-brand-on data-[state=checked]:border-bg-brand",
        accent:
          "data-[state=checked]:bg-bg-accent data-[state=checked]:text-fg-accent-on data-[state=checked]:border-bg-accent",
      },
    },
    defaultVariants: {
      variantColor: "brand",
    },
  },
);

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> &
    VariantProps<typeof checkboxVariants>
>(({ className, variantColor = "brand", ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(checkboxVariants({ variantColor }), className)}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn("flex items-center justify-center text-current")}
    >
      <Check className={"h-4 w-4"} />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
