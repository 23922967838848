import { Button } from "@/components/ui/button/Button";
import { ArrowUpRight } from "lucide-react";

type GroupElementHeaderProps = {
  callback?: () => void;
  label: string;
};

export default function GroupElementHeader({
  callback,
  label,
}: GroupElementHeaderProps) {
  return (
    <header className={"flex gap-2 p-4 justify-between items-center"}>
      <h3 className={"text-lg font-semibold truncate w-full"}>{label}</h3>
      {callback && (
        <Button
          size={"sm"}
          variant={"ghost"}
          variantColor={"muted"}
          onClick={callback}
          icon={<ArrowUpRight />}
          iconPosition={"only"}
        />
      )}
    </header>
  );
}
