import DirectoryCard from "@/components/features/files/DirectoryCard";
import FSEntitiesMenu from "@/components/features/files/FSEntitiesMenu";
import { Button } from "@/components/ui/button/Button";
import {
  ContextMenu,
  ContextMenuTrigger,
} from "@/components/ui/context-menu/ContextMenu";
import {
  DropdownMenu,
  DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import useLongPress from "@/hooks/useLongPress";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { useFilesStorage } from "@/store/filesStore";
import { DirectoryDirI } from "@/types/files";
import { MoreVertical } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

type FileStorageFileElementProps = {
  directory: DirectoryDirI;
};

export default function Directory({ directory }: FileStorageFileElementProps) {
  const breakpoint = useMediaQueryHook("sm");
  const [openDropdownMenu, setOpenDropdownMenu] = useState<boolean>(false);
  const [openContextMenu, setOpenContextMenu] = useState<boolean>(false);

  const navigate = useNavigate();
  const { OnFSEntityClick, isSelected, selectedSize } = useFilesStorage();

  const size = selectedSize();

  const isSelectedDirectory = isSelected(directory.id, "directory");

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && directory.permissions.read) {
      navigate(`/fs/dir/${directory?.id}`, { state: directory?.id });
    }
  };

  const onDbClick = () => {
    if (directory.permissions.read) {
      navigate(`/fs/dir/${directory?.id}`, { state: directory?.id });
    }
  };

  const onClick = (event: React.PointerEvent, isTouch: boolean) => {
    if ((isTouch && size > 0) || !isTouch) {
      OnFSEntityClick({ directory: directory, event: event });
    } else if (isTouch) {
      navigate(`/fs/dir/${directory?.id}`, { state: directory?.id });
    }
  };

  const onHold = (event: React.PointerEvent, isTouch: boolean) => {
    OnFSEntityClick({ directory: directory, event: event });
  };

  const { onPointerDown, onPointerMove, onPointerUp } = useLongPress({
    onClick,
    onHold,
    options: { preventDefault: true },
  });

  const handleOpenDropdownMenu = (bool: boolean) => {
    setOpenDropdownMenu(bool);
    OnFSEntityClick({ directory: directory });
  };
  const handleOpenContextMenu = (bool: boolean) => {
    setOpenContextMenu(bool);
    OnFSEntityClick({ directory: directory });
  };

  return (
    <ContextMenu onOpenChange={handleOpenContextMenu}>
      <DropdownMenu
        onOpenChange={handleOpenDropdownMenu}
        open={openDropdownMenu}
      >
        <ContextMenuTrigger disabled={breakpoint} asChild>
          <DirectoryCard
            tabIndex={0}
            aria-selected={isSelectedDirectory}
            onDoubleClick={onDbClick}
            onKeyDown={onKeyDown}
            onPointerDown={onPointerDown}
            onPointerMove={onPointerMove}
            onPointerUp={onPointerUp}
            directory={directory}
            menuButton={
              <DropdownMenuTrigger asChild>
                <Button
                  disabled={size > 1}
                  variant={"ghost"}
                  variantColor={"muted"}
                  icon={<MoreVertical />}
                  iconPosition={"only"}
                  size={"sm"}
                />
              </DropdownMenuTrigger>
            }
            className={cn(
              "bg-bg-container",
              isSelectedDirectory && "border-fg-brand bg-bg-element-selected",
            )}
          />
        </ContextMenuTrigger>
        <FSEntitiesMenu
          element={directory}
          type={"directory"}
          showContextMenu={!breakpoint}
          showDropdownMenu={true}
        />
      </DropdownMenu>
    </ContextMenu>
  );
}
