import { axiosInstance } from "@/api/api";
import { ChatEditSchemaType } from "@/schemas/chat.schema";
import { ChatI, ConversationI, MessageConfirmationI } from "@/types/chat";
import { UsersI } from "@/types/users";

type postConversationPropsT = {
  userIds: number[];
  groupIds: number[];
  name?: string;
};

type postFileUploadPropsT = {
  file: File;
  content?: string;
  id: number;
  tempUUID: string;
  replyingToId?: number;
  type: string;
  socketId: string | undefined;
};

export { postConversationPropsT, postFileUploadPropsT };

export const getChat = async (id: number): Promise<ChatI> => {
  return await axiosInstance.get("/api/chat", { params: { id: id } });
};

export const getCreateConversationUsers = async (params: {
  page?: number;
  pageSize?: number;
  name?: string;
}): Promise<UsersI[]> => {
  return await axiosInstance.get("/api/chat/getCreateConversationUsers", {
    params: params,
  });
};

export const getLastConversations = async (params: {
  page?: number;
  pageSize?: number;
  name?: string;
}): Promise<ConversationI[]> => {
  return await axiosInstance.get("/api/chat/getLastConversations", {
    params: params,
  });
};

export const postConversation = async (
  data: postConversationPropsT,
): Promise<any> => {
  return await axiosInstance.put("/api/chat", data);
};
export const postChatFileUpload = async ({
  file,
  type,
  content,
  tempUUID,
  replyingToId,
  id,
  socketId,
}: postFileUploadPropsT): Promise<MessageConfirmationI> => {
  const form = new FormData();
  if (content) form.append("content", content);
  if (replyingToId) form.append("replyingToId", String(replyingToId));
  if (socketId) form.append("socketId", socketId);
  form.append("file", file);
  form.append("id", String(id));
  form.append("tempUUID", tempUUID);
  form.append("type", type);

  return await axiosInstance.put("/api/chat/fileUpload", form, {
    headers: { "content-type": "multipart/form-data" },
  });
};

export const editConversation = async (
  data: ChatEditSchemaType,
): Promise<any> => {
  return await axiosInstance.post("/api/chat", data);
};

export const deleteConversation = async (id: number): Promise<string> => {
  return await axiosInstance.delete("/api/chat", { data: { id: id } });
};
