import { cn } from "@/lib/utils";
import * as AvatarPrimitive from "@radix-ui/react-avatar";
import { VariantProps, cva } from "class-variance-authority";
import React from "react";

const avatarVariants = cva(
  "relative overflow-hidden bg-bg-element flex flex-shrink-0 border-1 border-border",
  {
    variants: {
      size: {
        sm: "max-h-10 h-10 min-h-10 min-w-10 w-10 max-w-10 rounded-sm [&>svg]:w-4 [&>svg]:h-4",
        md: "max-h-11 h-11 min-h-11 min-w-11 w-11 max-w-11 rounded-md [&>svg]:w-5 [&>svg]:h-5",
        lg: "max-h-12 h-12 min-h-12 min-w-12 w-12 max-w-12 rounded-lg [&>svg]:w-6 [&>svg]:h-6",
      },
      indicator: {
        true: "after:form-pages-[''] after:absolute after:w-3 after:h-3 after:bottom-[-4px] after:right-[-4px] after:rounded-full after:bg-bg-success after:border-border after:border-1 after:z-10",
        false: "",
      },
    },
    defaultVariants: {
      size: "md",
      indicator: false,
    },
  },
);

interface AvatarProps
  extends React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>,
    VariantProps<typeof avatarVariants> {
  className?: string;
  indicator?: boolean;
}

const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  AvatarProps
>(({ className, size, indicator, ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    className={cn(avatarVariants({ size, indicator }), className)}
    {...props}
  />
));
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, src, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    className={cn("aspect-square h-full w-full", className)}
    src={src ? src : undefined}
    {...props}
  />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cn(
      "flex h-full w-full items-center justify-center text-fg-muted",
      className,
    )}
    {...props}
  />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

export { Avatar, AvatarImage, AvatarFallback, avatarVariants, AvatarProps };
