import { useGetDirectoryQuery } from "@/api/queries/directoryQueries";
import FilePageForms from "@/components/features/files/layouts/files-page/FilePageForms";
import FilesPageContent from "@/components/features/files/layouts/files-page/FilesPageContent";
import FilesPageHeader from "@/components/features/files/layouts/files-page/FilesPageHeader";
import MediaViewer from "@/components/features/media/MediaViewer";
import { Tabs } from "@/components/ui/tabs/Tabs";
import { useCredentials } from "@/store/authStore";
import { useFilesStorage } from "@/store/filesStore";
import { Fragment, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

export default function FilesPage() {
  const {
    fileToOpen,
    setModel,
    model,
    resetSelected,
    openViewer,
    setOpenViewer,
  } = useFilesStorage();
  const { directoryId: defaultDirectoryId } = useCredentials();
  const { id } = useParams();
  const directoryId = useMemo(() => {
    return id ? id : defaultDirectoryId;
  }, [id, defaultDirectoryId]);

  useEffect(() => {
    resetSelected();
  }, [directoryId]);

  const {
    data: directory,
    isSuccess,
    isPending,
    isLoading,
    isError,
  } = useGetDirectoryQuery(directoryId, model === "my_drive");

  return (
    <Fragment>
      <FilePageForms directoryId={directoryId} />
      <MediaViewer
        file={fileToOpen}
        open={openViewer}
        onOpenChange={setOpenViewer}
      />
      <Tabs
        value={model}
        onValueChange={(value) =>
          setModel(value as "my_drive" | "shared_with_me")
        }
        className={"flex flex-col gap-4"}
      >
        <FilesPageHeader
          showTabsList={!id}
          fsEntry={directory}
          isLoading={isLoading}
          isError={isError}
        />
        <FilesPageContent
          files={directory?.files}
          directories={directory?.childDirs}
          isSuccess={isSuccess}
          isLoading={isPending}
          isError={isError}
        />
      </Tabs>
    </Fragment>
  );
}
