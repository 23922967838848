import { usePostLogoutMutation } from "@/api/queries/authQueries";
import { Navbar } from "@/components/ui/navigation/Navbar";
import { Sidebar } from "@/components/ui/navigation/Sidebar";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar/Avatar";
import {
  Button,
  ButtonProps,
  buttonVariants,
} from "@/components/ui/button/Button";
import useAvatarQuery from "@/hooks/useAvatarQuery";
import { useLvl } from "@/hooks/useLvl";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { useCredentials, useRemoveCredentials } from "@/store/authStore";
import { useStateStore } from "@/store/stateStore";
import { CredentialsI } from "@/types/credentials";
import { ChevronDown, User } from "lucide-react";
import {
  ReactElement,
  Ref,
  cloneElement,
  createContext,
  forwardRef,
  useContext,
} from "react";
import { NavLink, useNavigate } from "react-router-dom";

//Navigation context for close/open navigation component
const NavigationContext = createContext({ open: false });

function NavigationAvatarImage({
  avatarURL,
}: {
  avatarURL: string | undefined;
}) {
  const src = useAvatarQuery(avatarURL);
  return (
    <Avatar size={"md"}>
      <AvatarImage src={src} alt={"avatar"} />
      <AvatarFallback>
        <User className={"w-4 h-4"} />
      </AvatarFallback>
    </Avatar>
  );
}

NavigationAvatarImage.displayName = "NavigationAvatarImage";

interface NavigationItemProps
  extends Omit<
    ButtonProps,
    "iconPosition" | "children" | "asChild" | "showSpinner"
  > {
  text: string;
  to?: string;
  isNavigation?: boolean;
  type?: "list" | "default";
  chevron?: ReactElement;
  preventHideText?: boolean;
  onClick?: () => void;
}

const NavigationItem = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  NavigationItemProps
>(
  (
    {
      icon,
      text,
      to,
      isNavigation = true,
      type = "default",
      chevron,
      onClick,
      size = "md",
      variant = "ghost",
      variantColor = "muted",
      preventHideText = false,
      ...props
    },
    ref,
  ) => {
    const { open } = useContext(NavigationContext);
    const showText = preventHideText ? true : open;
    const content = (
      <>
        {icon}
        <span
          className={cn(
            "overflow-hidden text-start duration-200 transition-all",
            showText ? "w-full" : "w-0",
          )}
        >
          {text}
        </span>
        {type === "list" ? (
          chevron ? (
            cloneElement(chevron, { className: "ml-auto" })
          ) : (
            <ChevronDown className={"ml-auto"} />
          )
        ) : null}
      </>
    );

    const commonProps = {
      onClick,
      className: cn(
        buttonVariants({
          size: size,
          variant: variant,
          variantColor: variantColor,
          iconPosition: "none",
        }),
        "w-full px-3 justify-start overflow-hidden",
      ),
      ...props,
      children: content,
    };

    return isNavigation ? (
      <NavLink to={to!} ref={ref as Ref<HTMLAnchorElement>} {...commonProps} />
    ) : (
      <Button
        size={"md"}
        variant={"ghost"}
        variantColor={"muted"}
        iconPosition={"none"}
        ref={ref as Ref<HTMLButtonElement>}
        {...commonProps}
      />
    );
  },
);

NavigationItem.displayName = "NavigationItem";

interface NavigationProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  logout: () => void;
  isLoading: boolean;
  userData: Omit<
    CredentialsI,
    "directoryId" | "id" | "email" | "phone" | "birthdate"
  > & {
    userRole: string;
  };
}

function Navigation() {
  const navigate = useNavigate();
  const mediaQueryBreakpoint = useMediaQueryHook("sm");
  const removeCredentials = useRemoveCredentials();
  const { name, surname, lvl, avatarURL } = useCredentials();
  const { isNavbarOpen, updateNavbarState } = useStateStore();
  const userRole = useLvl(lvl);

  const { mutateAsync, isPending } = usePostLogoutMutation();

  const handleLogOut = async () => {
    await mutateAsync();
    removeCredentials();
    navigate("/login");
  };

  if (mediaQueryBreakpoint) {
    return (
      <Navbar
        open={isNavbarOpen}
        onOpenChange={updateNavbarState}
        logout={handleLogOut}
        isLoading={isPending}
        userData={{ name, surname, userRole, lvl, avatarURL }}
      />
    );
  } else {
    return (
      <Sidebar
        open={isNavbarOpen}
        onOpenChange={updateNavbarState}
        logout={handleLogOut}
        isLoading={isPending}
        userData={{ name, surname, userRole, lvl, avatarURL }}
      />
    );
  }
}

Navigation.displayName = "Navigation";

export {
  Navigation,
  NavigationAvatarImage,
  NavigationItem,
  NavigationContext,
  NavigationProps,
};
