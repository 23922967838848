import { Separator } from "@/components/ui/separator/Separator";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs/Tabs";
import { GroupsI } from "@/types/groups";
import { UsersI } from "@/types/users";

import UserCard from "../user/UserCard";
import GroupCardSmall from "./GroupCardSmall";

export default function GroupMembers({
  groups,
  users,
}: {
  groups: GroupsI[];
  users: UsersI[];
}) {
  return (
    <div className={"flex flex-col w-full h-full grow overflow-auto"}>
      <header className={"flex gap-2 justify-between items-center p-4"}>
        <div className={"h-10 flex items-center"}>
          <h3 className={"text-lg font-semibold truncate w-full"}>
            Goście spotkania
          </h3>
        </div>
      </header>
      <Separator />
      <Tabs
        defaultValue={"users"}
        className={"flex flex-col w-full grow overflow-auto"}
      >
        <div className={"p-4"}>
          <TabsList className={"grid w-full grid-cols-2"}>
            <TabsTrigger value={"users"}>Użytkownicy</TabsTrigger>
            <TabsTrigger value={"groups"}>Grupy</TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value={"users"} className={"h-full px-4 pb-4"}>
          {users.map((user) => (
            <UserCard key={user.id} user={user} />
          ))}
          {users.length === 0 && (
            <p className={"text-fg-muted text-sm text-center"}>
              Brak użytknowników
            </p>
          )}
        </TabsContent>
        <TabsContent value={"groups"} className={"h-full o px-4 pb-4"}>
          {groups.map((group) => (
            <GroupCardSmall key={group.id} group={group} />
          ))}
          {groups.length === 0 && (
            <p className={"text-fg-muted text-sm text-center"}>Brak grup</p>
          )}
        </TabsContent>
      </Tabs>
    </div>
  );
}
