import { useInfiniteQueryResult } from "@/api/api-utils";
import {
  useEditDirectoryMutation,
  useGetDirectoryPermissionsQuery,
} from "@/api/queries/directoryQueries";
import {
  useEditFileMutation,
  useGetFilePermissionsQuery,
} from "@/api/queries/filesQueries";
import { useGetGroupsInfiniteQuery } from "@/api/queries/groupsQueries";
import { useGetUsersInfiniteQuery } from "@/api/queries/usersQueries";
import PermissionLiEntity from "@/components/features/files/PermissionLiEntity";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog/Dialog";
import { Input, InputActionButton } from "@/components/ui/input/Input";
import { LiElement } from "@/components/ui/list/ListElement";
import SelectDataList from "@/components/ui/list/SelectDataList";
import { Tabs, TabsContent } from "@/components/ui/tabs/Tabs";
import { useToast } from "@/components/ui/toast/useToast";
import { useDebounceValue } from "@/hooks/useDebounceValue";
import {
  DirectoryDirI,
  DirectoryFileI,
  GetPermissionsGroupI,
  GetPermissionsUserI,
} from "@/types/files";
import { getIsFSEntityFile } from "@/utils/files";
import { ChevronLeft, Plus, Search, X } from "lucide-react";
import { useCallback, useEffect, useState } from "react";

type StorageElementShareFormProps = {
  element?: DirectoryDirI | DirectoryFileI;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  submitCallback?: () => void;
  directoryId: string;
};

type permissionChangesProps = {
  selectedUsers?: GetPermissionsUserI[];
  selectedGroups?: GetPermissionsGroupI[];
  defaultUsers?: GetPermissionsUserI[];
  defaultGroups?: GetPermissionsGroupI[];
};

function permissionChanges({
  selectedUsers,
  selectedGroups,
  defaultUsers,
  defaultGroups,
}: permissionChangesProps) {
  const usersToRemove = (defaultUsers || [])
    .filter((user) => !selectedUsers?.some((element) => element.id === user.id))
    .map((user) => user.id);
  const groupsToRemove = (defaultGroups || [])
    .filter(
      (group) => !selectedGroups?.some((element) => element.id === group.id),
    )
    .map((group) => group.id);

  const usersToAdd = selectedUsers?.filter(
    (user) =>
      !defaultUsers?.some(
        (element) =>
          element.id === user.id && element.permissions !== user.permissions,
      ),
  );
  const groupsToAdd = selectedGroups?.filter(
    (group) =>
      !defaultGroups?.some(
        (element) =>
          element.id === group.id && element.permissions !== group.permissions,
      ),
  );

  const readPermissionsUsers: number[] = [];
  const writePermissionsUsers: number[] = [];
  const editPermissionsUsers: number[] = [];
  const readPermissionsGroups: number[] = [];
  const writePermissionsGroups: number[] = [];
  const editPermissionsGroups: number[] = [];

  groupsToAdd?.forEach((group) => {
    if (group.permissions.edit) {
      editPermissionsGroups.push(group.id);
      return;
    }
    if (group.permissions.write) {
      writePermissionsGroups.push(group.id);
      return;
    }
    if (group.permissions.read) {
      readPermissionsGroups.push(group.id);
      return;
    }
  });

  usersToAdd?.forEach((user) => {
    if (user.permissions.edit) {
      editPermissionsUsers.push(user.id);
      return;
    }
    if (user.permissions.write) {
      writePermissionsUsers.push(user.id);
      return;
    }
    if (user.permissions.read) {
      readPermissionsUsers.push(user.id);
      return;
    }
  });

  return [
    {
      userIds: usersToRemove,
      groupIds: groupsToRemove,
      remove: true,
    },
    {
      userIds: readPermissionsUsers,
      groupIds: readPermissionsGroups,
      add: true,
      read: true,
      write: false,
      edit: false,
    },
    {
      userIds: writePermissionsUsers,
      groupIds: writePermissionsGroups,
      add: true,
      read: true,
      write: true,
      edit: false,
    },
    {
      userIds: editPermissionsUsers,
      groupIds: editPermissionsGroups,
      add: true,
      read: true,
      write: true,
      edit: true,
    },
  ];
}

export default function ShareFSEntry({
  element,
  open,
  directoryId,
  onOpenChange,
  submitCallback,
}: StorageElementShareFormProps) {
  const { toast } = useToast();
  const [selectedTab, setSelectedTab] = useState<string>("main_menu");
  //search value
  const [searchUserValue, setSearchUserValue] = useState<string>("");
  const searchUserDebouncedValue = useDebounceValue(searchUserValue, 400);

  const [searchGroupValue, setSearchGroupValue] = useState<string>("");
  const searchGroupDebouncedValue = useDebounceValue(searchGroupValue, 400);

  //selected Entities
  const [selectedUsers, setSelectedUsers] = useState<GetPermissionsUserI[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<GetPermissionsGroupI[]>(
    [],
  );

  const isFile = getIsFSEntityFile(element);

  const {
    mutateAsync: directoriesMutation,
    isPending: isDirectoryMutationPending,
  } = useEditDirectoryMutation(directoryId);

  const { mutateAsync: filesMutation, isPending: isFileMutationPending } =
    useEditFileMutation();

  const filePermissionsQuery = useGetFilePermissionsQuery(
    element?.id,
    open && isFile,
  );
  const directoryPermissionsQuery = useGetDirectoryPermissionsQuery(
    element?.id,
    open && !isFile,
  );

  const getUsersInfiniteQuery = useGetUsersInfiniteQuery({
    name: searchUserDebouncedValue,
  });

  const {
    data: users,
    isEmpty: usersIsEmpty,
    noResults: usersNoResults,
  } = useInfiniteQueryResult(
    getUsersInfiniteQuery.data,
    searchUserDebouncedValue,
  );

  const getGroupsInfiniteQuery = useGetGroupsInfiniteQuery({
    name: searchGroupDebouncedValue,
  });

  const {
    data: groups,
    isEmpty: groupsIsEmpty,
    noResults: groupsNoResults,
  } = useInfiniteQueryResult(
    getGroupsInfiniteQuery.data,
    searchGroupDebouncedValue,
  );

  const setSelectedCallback = useCallback(
    (value: any[], variant: "users" | "groups") => {
      if (variant === "users") {
        setSelectedUsers(() => {
          if (value) {
            return value.map((user): GetPermissionsUserI => {
              if ("permissions" in user && "source" in user) {
                return user as GetPermissionsUserI;
              }
              return {
                ...user,
                source: [],
                permissions: {
                  read: true,
                  write: false,
                  edit: false,
                },
              } as GetPermissionsUserI;
            });
          }
          return [];
        });
      }
      if (variant === "groups") {
        setSelectedGroups(() => {
          if (value) {
            return value.map((group): GetPermissionsGroupI => {
              if ("permissions" in group) {
                return group as GetPermissionsGroupI;
              }
              return {
                ...group,
                permissions: {
                  read: true,
                  write: false,
                  edit: false,
                },
              } as GetPermissionsGroupI;
            });
          }
          return [];
        });
      }
    },
    [],
  );

  const handleClose = () => {
    setSelectedUsers([]);
    setSelectedGroups([]);
    setSearchUserValue("");
    setSearchGroupValue("");
    onOpenChange(false);
  };

  const handleSubmit = async () => {
    if (!element) return;

    const permissions = permissionChanges({
      defaultUsers: isFile
        ? filePermissionsQuery.data?.users
        : directoryPermissionsQuery.data?.users,
      defaultGroups: isFile
        ? filePermissionsQuery.data?.groups
        : directoryPermissionsQuery.data?.groups,
      selectedUsers,
      selectedGroups,
    });

    if (isFile) {
      await filesMutation({
        id: element.id,
        permissionChanges: permissions,
      }).then(() => {
        handleClose();
        submitCallback?.();
      });
    } else {
      await directoriesMutation({
        id: element.id,
        permissionChanges: permissions,
      }).then(() => {
        handleClose();
        submitCallback?.();
      });
    }
  };

  useEffect(() => {
    if (isFile) {
      setSelectedUsers(filePermissionsQuery.data?.users || []);
      setSelectedGroups(filePermissionsQuery.data?.groups || []);

      if (filePermissionsQuery.isError) {
        handleClose();
        toast({
          title: "Błąd",
          description: "Nie udało się pobrać uprawnień do pliku",
          variant: "destructive",
        });
      }
    } else {
      if (directoryPermissionsQuery.isError) {
        handleClose();
        toast({
          title: "Błąd",
          description: "Nie udało się pobrać uprawnień do folderu",
          variant: "destructive",
        });
      }
      setSelectedUsers(directoryPermissionsQuery.data?.users || []);
      setSelectedGroups(directoryPermissionsQuery.data?.groups || []);
    }
  }, [filePermissionsQuery.data, directoryPermissionsQuery.data]);

  const handleRemoveEntity = (id: number, type: "user" | "group") => {
    if ("user" === type) {
      setSelectedUsers((prev) => prev.filter((user) => user.id !== id));
    }
    if ("group" === type) {
      setSelectedGroups((prev) => prev.filter((group) => group.id !== id));
    }
  };
  const handlePermissionChange = (
    id: number,
    newValue: string,
    type: "user" | "group",
  ) => {
    if ("user" === type) {
      setSelectedUsers((prev) =>
        prev.map((user) =>
          user.id === id
            ? {
                ...user,
                permissions: {
                  read: false,
                  write: false,
                  edit: false,
                  [newValue]: true,
                },
              }
            : user,
        ),
      );
    }
    if ("group" === type) {
      setSelectedGroups((prev) =>
        prev.map((group) =>
          group.id === id
            ? {
                ...group,
                permissions: {
                  read: false,
                  write: false,
                  edit: false,
                  [newValue]: true,
                },
              }
            : group,
        ),
      );
    }
  };

  return (
    <Dialog onOpenChange={onOpenChange} open={open}>
      <Tabs
        asChild
        defaultValue={"main_menu"}
        onValueChange={setSelectedTab}
        value={selectedTab}
      >
        <DialogContent
          className={"w-[55ch] h-[664px] sm:w-full"}
          onInteractOutside={(e) => e.preventDefault()}
          onPointerDownOutside={(e) => e.preventDefault()}
        >
          <TabsContent
            value={"main_menu"}
            className={"flex flex-col data-[state=active]:h-full"}
          >
            <DialogHeader>
              <DialogTitle>Dostęp</DialogTitle>
            </DialogHeader>
            <DialogBody className={"flex flex-col gap-4 h-auto"}>
              <div
                className={"flex flex-col gap-4 border-b-1 border-border pb-4"}
              >
                <div className={"flex justify-between items-center"}>
                  <h5 className={"text-sm text-fg-muted font-medium"}>
                    Element:
                  </h5>
                </div>
                <div className="flex gap-2 flex-wrap">
                  <Badge variant={"outline"} hideDot>
                    {element?.name}
                  </Badge>
                </div>
              </div>
              <div className={"flex flex-col gap-4"}>
                <div className={"flex justify-between  items-center"}>
                  <h5 className={"text-lg font-medium"}>Użytkownicy:</h5>
                  <Button
                    onClick={() => setSelectedTab("users_add_menu")}
                    size={"sm"}
                    variant={"flat"}
                    variantColor={"muted"}
                    icon={<Plus />}
                    iconPosition={"left"}
                  >
                    Użytkownicy
                  </Button>
                </div>
                <ul>
                  {selectedUsers.length === 0 && (
                    <div className="flex py-4">
                      <p className="text-sm text-fg-muted italic">
                        Dodaj użytkowników
                      </p>
                    </div>
                  )}
                  {selectedUsers.map((entity) => (
                    <PermissionLiEntity
                      key={entity.id}
                      data={{
                        id: entity.id,
                        title: entity.name + " " + entity.surname,
                        detail: entity.source?.[0],
                        avatarURL: entity.avatarURL,
                      }}
                      permissions={entity.permissions}
                      onPermissionChange={(id, value) =>
                        handlePermissionChange(id, value, "user")
                      }
                      onRemove={() => {
                        handleRemoveEntity(entity.id, "user");
                      }}
                    />
                  ))}
                </ul>
              </div>
              <div className={"flex flex-col gap-4"}>
                <div className={"flex justify-between  items-center"}>
                  <h5 className={"text-lg font-medium"}>Grupy:</h5>
                  <Button
                    onClick={() => setSelectedTab("groups_add_menu")}
                    size={"sm"}
                    variant={"flat"}
                    variantColor={"muted"}
                    icon={<Plus />}
                    iconPosition={"left"}
                  >
                    Grupy
                  </Button>
                </div>
                <ul>
                  {selectedGroups.length === 0 && (
                    <div className="flex py-4">
                      <p className="text-sm text-fg-muted italic">
                        Dodaj grupy
                      </p>
                    </div>
                  )}
                  {selectedGroups.map((entity) => (
                    <PermissionLiEntity
                      key={entity.id}
                      permissions={entity.permissions}
                      data={{
                        id: entity.id,
                        title: entity.name,
                        avatarURL: "",
                      }}
                      onRemove={() => {
                        handleRemoveEntity(entity.id, "group");
                      }}
                      onPermissionChange={(id, value) =>
                        handlePermissionChange(id, value, "group")
                      }
                    />
                  ))}
                </ul>
              </div>
            </DialogBody>
            <DialogFooter>
              <Button
                onClick={handleClose}
                variant={"flat"}
                variantColor={"muted"}
                className={"sm:w-full"}
              >
                Anuluj
              </Button>
              <Button
                isLoading={{
                  state: isFileMutationPending || isDirectoryMutationPending,
                }}
                onClick={handleSubmit}
                variant={"flat"}
                variantColor={"brand"}
                className={"sm:w-full"}
              >
                Zapisz
              </Button>
            </DialogFooter>
          </TabsContent>
          <TabsContent
            value={"users_add_menu"}
            className={"flex flex-col data-[state=active]:h-full"}
          >
            <DialogBody className="h-auto">
              <header
                className={
                  "flex gap-3 py-4 z-20 sticky top-0 pb-2 bg-bg-container"
                }
              >
                <Button
                  variant={"outline"}
                  variantColor={"muted"}
                  icon={<ChevronLeft />}
                  iconPosition={"only"}
                  onClick={() => {
                    setSelectedTab("main_menu");
                  }}
                />
                <Input
                  placeholder={"Wyszukaj..."}
                  value={searchUserValue}
                  onChange={(e) => setSearchUserValue(e.target.value)}
                  startContent={<Search className={"ml-2"} />}
                  endContent={
                    <InputActionButton
                      disabled={!searchUserValue.length}
                      onClick={() => setSearchUserValue("")}
                      icon={<X />}
                    />
                  }
                />
              </header>
              <SelectDataList
                query={getUsersInfiniteQuery}
                selected={selectedUsers}
                setSelected={(value) => setSelectedCallback(value, "users")}
                isEmpty={usersIsEmpty}
                isNoResults={usersNoResults}
                emptyMessage={"Wyszukaj użytkownika"}
                searchNoResultsMessage={"Nie znaleziono użytkownika"}
                loadingElementPros={{
                  hasAvatar: true,
                  hasDetail: false,
                  hasTitle: true,
                }}
              >
                {users.map((entity) => (
                  <LiElement
                    key={entity.id}
                    entity={entity}
                    data={{
                      title: entity?.name + " " + entity?.surname,
                      detail: entity?.email,
                      avatarURL: entity?.avatarURL,
                    }}
                  />
                ))}

                {usersIsEmpty &&
                  selectedUsers.map((entity) => (
                    <LiElement
                      key={entity.id}
                      entity={entity}
                      data={{
                        title: entity.name + " " + entity.surname,
                        detail: "",
                        avatarURL: entity.avatarURL,
                      }}
                    />
                  ))}
              </SelectDataList>
            </DialogBody>
            <DialogFooter></DialogFooter>
          </TabsContent>
          <TabsContent
            value={"groups_add_menu"}
            className={"flex flex-col data-[state=active]:h-full"}
          >
            <DialogBody className="h-auto">
              <header className={"flex gap-3 py-4 "}>
                <Button
                  variant={"outline"}
                  variantColor={"muted"}
                  icon={<ChevronLeft />}
                  iconPosition={"only"}
                  onClick={() => {
                    setSelectedTab("main_menu");
                  }}
                />

                <Input
                  placeholder={"Wyszukaj..."}
                  value={searchGroupValue}
                  onChange={(e) => setSearchGroupValue(e.target.value)}
                  startContent={<Search className={"ml-2"} />}
                  endContent={
                    <InputActionButton
                      disabled={!searchGroupValue.length}
                      onClick={() => setSearchGroupValue("")}
                      icon={<X />}
                    />
                  }
                />
              </header>
              <SelectDataList
                query={getGroupsInfiniteQuery}
                selected={selectedGroups}
                setSelected={(value) => setSelectedCallback(value, "groups")}
                isEmpty={groupsIsEmpty}
                isNoResults={groupsNoResults}
                emptyMessage={"Wyszukaj grupy"}
                searchNoResultsMessage={"Nie znaleziono grupy o podanej nazwie"}
                loadingElementPros={{
                  hasAvatar: true,
                  hasDetail: false,
                  hasTitle: true,
                }}
              >
                {groups.map((entity) => (
                  <LiElement
                    key={entity.id}
                    entity={entity}
                    data={{
                      title: entity.name,
                      detail: entity.users.length + " użytkownik/ów",
                    }}
                  />
                ))}
                {groupsIsEmpty &&
                  selectedGroups.map((entity) => (
                    <LiElement
                      key={entity.id}
                      entity={entity}
                      data={{
                        title: entity.name,
                        avatarURL: "",
                      }}
                    />
                  ))}
              </SelectDataList>
            </DialogBody>
            <DialogFooter></DialogFooter>
          </TabsContent>
        </DialogContent>
      </Tabs>
    </Dialog>
  );
}
