import ErrorBoundaryFallback from "@/dev/error-boundary/ErrorBoundaryFallback";
import { ErrorInfo, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet } from "react-router";

export function ErrorBoundaryWrapper() {
  useEffect(() => {
    window.addEventListener("error", (event: ErrorEvent) => {
      // Log the error detail or send them to a logging service
      console.log("Error:", event.message);
      console.log("Line Number:", event.lineno);
      console.log("Column Number:", event.colno);
      console.log("Error Object:", event.error);
      console.log("event:", event);

      // Return true to prevent the default browser error handling
      return true;
    });
  }, []);

  const logError = (error: Error, info: ErrorInfo) => {
    console.error(error, info.componentStack);
  };

  return (
    <ErrorBoundary onError={logError} FallbackComponent={ErrorBoundaryFallback}>
      <Outlet />
    </ErrorBoundary>
  );
}
