import { useLocalStorage } from "@/hooks/useLocalStorage";
import React from "react";
import { createContext, useEffect } from "react";

const enum ThemeMode {
  Dark = "dark",
  Light = "light",
}

interface ThemeContextType {
  theme: ThemeMode;
  SwitchTheme: () => void;
  setTheme: (theme: ThemeMode) => void;
}

const ThemeContext = createContext<ThemeContextType>({
  theme: ThemeMode.Light,
  SwitchTheme: () => {},
  setTheme: () => {},
});

function ProviderTheme({ children }: { children: React.ReactNode }) {
  const defaultDark = window.matchMedia(
    `(prefers-color-scheme: ${ThemeMode.Dark})`,
  ).matches;

  const [theme, setTheme] = useLocalStorage(
    "theme",
    defaultDark ? ThemeMode.Dark : ThemeMode.Light,
  );

  const SwitchTheme = () => {
    setTheme(theme === ThemeMode.Light ? ThemeMode.Dark : ThemeMode.Light);
  };

  useEffect(() => {
    document.body.setAttribute("data-theme", theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, SwitchTheme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

const useThemeContext = () => {
  return React.useContext(ThemeContext);
};

export { ProviderTheme, ThemeMode, useThemeContext };
export default ThemeContext;
