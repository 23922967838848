import { httpErrorHandler } from "@/api/api";
import { useInfiniteQueryResult } from "@/api/api-utils";
import {
  useEditGroupMutation,
  useGetGroupsInfiniteQuery,
} from "@/api/queries/groupsQueries";
import {
  useEditUserMutation,
  useGetUserGroupsQuery,
  useGetUserQuery,
} from "@/api/queries/usersQueries";
import { Button } from "@/components/ui/button/Button";
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form/Form";
import { Input, InputActionButton } from "@/components/ui/input/Input";
import { DatetimePicker } from "@/components/ui/input/datetime-picker/DatetimePicker";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/input/select/Select";
import {
  LiElement,
  LiElementSeparator,
} from "@/components/ui/list/ListElement";
import SelectDataList from "@/components/ui/list/SelectDataList";
import { Separator } from "@/components/ui/separator/Separator";
import { Spinner } from "@/components/ui/spinner/Spinner";
import { useToast } from "@/components/ui/toast/useToast";
import { useDebounceValue } from "@/hooks/useDebounceValue";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import {
  UserEditSchema,
  UserEditSchemaCast,
  UserEditSchemaType,
} from "@/schemas/user.schema";
import { useCredentials } from "@/store/authStore";
import { GroupI } from "@/types/groups";
import { UsersI } from "@/types/users";
import { SerializeData } from "@/utils/serializeData";
import { yupResolver } from "@hookform/resolvers/yup";
import { Search, X } from "lucide-react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

type UserEditFormPropsT = {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  callback?: () => void;
  user?: UsersI;
};

export default function EditUser({
  user,
  open,
  onOpenChange,
  callback,
}: UserEditFormPropsT) {
  const breakpoint = useMediaQueryHook("sm");
  const { toast } = useToast();
  const [isPending, setIsPending] = useState<boolean>(false);
  const [searchGroupValue, setSearchGroupValue] = useState<string>("");
  const searchGroupDebouncedValue = useDebounceValue(searchGroupValue, 400);
  const [selectedGroups, setSelectedGroups] = useState<GroupI[]>([]);

  const { lvl } = useCredentials();

  const { data: userData, ...userResults } = useGetUserQuery(user?.id);
  const { data: userGroups = [], ...userGroupsResults } = useGetUserGroupsQuery(
    user?.id,
  );

  const { mutateAsync: userEditMutation } = useEditUserMutation();
  const { mutate: groupEditMutation } = useEditGroupMutation();

  useEffect(() => {
    if (userGroupsResults.isSuccess && open && userGroups) {
      console.log("userGroups: ", userGroups);
      setSelectedGroups(userGroups);
    }
  }, [userGroupsResults.isSuccess, open, userGroups]);

  useEffect(() => {
    if (userResults.isError) {
      const { title, detail } = httpErrorHandler(userResults.error);
      handleClose();
      toast({
        variant: "destructive",
        title: title,
        description: detail,
      });
    } else if (userGroupsResults.isError) {
      const { title, detail } = httpErrorHandler(userGroupsResults.error);
      handleClose();
      toast({
        variant: "destructive",
        title: title,
        description: detail,
      });
    }
  }, [
    userResults.isError,
    userGroupsResults.isError,
    userGroupsResults.error,
    userResults.error,
  ]);

  const getGroupsInfiniteQuery = useGetGroupsInfiniteQuery({
    name: searchGroupDebouncedValue,
  });

  const {
    data: groups,
    isEmpty: groupsIsEmpty,
    noResults: groupsNoResults,
  } = useInfiniteQueryResult(
    getGroupsInfiniteQuery.data,
    searchGroupDebouncedValue,
  );

  const form = useForm<UserEditSchemaType>({
    mode: "onBlur",
    defaultValues: {
      name: "",
      surname: "",
      email: "",
      lvl: "0",
      tel: "",
      birthdate: undefined,
      active: true,
    },
    values: userData && UserEditSchema.cast(SerializeData(userData)),
    resolver: yupResolver(UserEditSchema),
  });

  const onSubmit = async (data: UserEditSchemaType) => {
    setIsPending(true);

    const groupsToAdd = selectedGroups.filter((selectedGroup) =>
      userGroups.every((userGroup) => userGroup.id !== selectedGroup.id),
    );
    const groupsToRemove = userGroups.filter(
      (userGroup) =>
        !selectedGroups.find(
          (selectedGroup) => selectedGroup.id === userGroup.id,
        ),
    );

    if (groupsToAdd) {
      groupsToAdd.forEach(({ id }) => {
        groupEditMutation({
          id: id,
          memberChanges: [
            {
              userIds: user?.id ? [user.id] : [],
              add: true,
            },
          ],
        });
      });
    }
    if (groupsToRemove) {
      groupsToRemove.forEach(({ id }) => {
        groupEditMutation({
          id: id,
          memberChanges: [
            {
              userIds: user?.id ? [user.id] : [],
              remove: true,
            },
          ],
        });
      });
    }

    const serializedData = UserEditSchemaCast.cast(SerializeData(data));

    await userEditMutation(serializedData)
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        const { title, detail } = httpErrorHandler(error);
        toast({
          variant: "destructive",
          title: title,
          description: detail,
        });
      });

    setIsPending(false);
  };

  const handleClose = () => {
    onOpenChange(false);
    callback?.();
    setSearchGroupValue("");
    setSelectedGroups([]);
    form.reset();
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        className={cn(breakpoint ? "max-w-[120ch]" : "h-[46rem]")}
        onCloseAutoFocus={handleClose}
        onEscapeKeyDown={handleClose}
      >
        {isPending && (
          <div
            className={
              "absolute inset-0 z-20 m-auto flex flex-wrap items-center justify-center gap-3 bg-bg-container"
            }
          >
            <Spinner size={"lg"} />
            <h5 className={"font-medium"}>Trwa zapisywanie zmian...</h5>
          </div>
        )}
        {(userResults.isFetching || userGroupsResults.isFetching) && (
          <div
            className={
              "absolute inset-0 z-20 m-auto flex flex-wrap items-center justify-center gap-3 bg-bg-container"
            }
          >
            <Spinner size={"lg"} />
            <h5 className={"font-medium"}>Trwa ładowanie danych...</h5>
          </div>
        )}
        <DialogHeader>
          <DialogTitle>Edytuj użytkownika</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <DialogBody className={"flex h-auto gap-4 sm:flex-col"}>
            <form
              className={cn(
                "grid h-fit w-full grid-cols-2 gap-3",
                !breakpoint && "sticky top-0",
              )}
              onSubmit={form.handleSubmit(onSubmit)}
              noValidate
            >
              <div className={"col-span-2 flex h-10 min-h-10 items-center"}>
                <h3 className={"text-lg font-medium"}>Dane:</h3>
              </div>
              <FormField
                name={"name"}
                control={form.control}
                render={({ field }) => (
                  <FormItem className={"col-span-1"}>
                    <FormLabel>Imię</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder={"Imię"} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name={"surname"}
                control={form.control}
                render={({ field }) => (
                  <FormItem className={"col-span-1"}>
                    <FormLabel>Nazwisko</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder={"Nazwisko"} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name={"email"}
                control={form.control}
                render={({ field }) => (
                  <FormItem className={"col-span-2"}>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder={"Email"} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name={"lvl"}
                control={form.control}
                render={({ field }) => (
                  <FormItem className={"col-span-2"}>
                    <FormLabel>Poświadczenia</FormLabel>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={"Poświadczenia"} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value={"0"}>Uczeń</SelectItem>
                          <SelectItem value={"1"}>Nauczyciel</SelectItem>
                          <SelectItem disabled={lvl != 2} value={"2"}>
                            Administrator
                          </SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name={"tel"}
                control={form.control}
                render={({ field }) => (
                  <FormItem className={"col-span-2"}>
                    <FormLabel>
                      Numer telefonu{" "}
                      <small className={"text-muted-foreground text-sm"}>
                        (opcionalne)
                      </small>
                    </FormLabel>
                    <FormControl>
                      <Input {...field} placeholder={"Numer telefonu"} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name={"birthdate"}
                control={form.control}
                render={({ field }) => {
                  return (
                    <FormItem className={"col-span-2"}>
                      <FormLabel>
                        Data urodzenia{" "}
                        <small className={"text-sm text-fg-muted"}>
                          (opcionalne)
                        </small>
                      </FormLabel>
                      <FormControl>
                        <DatetimePicker
                          jsDate={field.value}
                          onJsDateChange={field.onChange}
                          aria-label={"birthdate-picker"}
                          max={new Date()}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            </form>
            <Separator orientation={"vertical"} />
            <div className={"flex w-full flex-col gap-2"}>
              <div
                className={cn(
                  "z-10 flex flex-col gap-2 bg-bg-container pb-2",
                  !breakpoint && "sticky top-0",
                )}
              >
                <div className={"col-span-2 flex h-10 min-h-10 items-center"}>
                  <h3 className={"text-lg font-medium"}>Grupy:</h3>
                </div>
                <Input
                  placeholder={"Wyszukaj..."}
                  value={searchGroupValue}
                  onChange={(e) => setSearchGroupValue(e.target.value)}
                  startContent={<Search className={"ml-2"} />}
                  endContent={
                    <InputActionButton
                      disabled={!searchGroupValue.length}
                      onClick={() => setSearchGroupValue("")}
                      icon={<X />}
                    />
                  }
                />
              </div>
              <div>
                <SelectDataList
                  query={getGroupsInfiniteQuery}
                  selected={selectedGroups}
                  setSelected={setSelectedGroups}
                  isEmpty={groupsIsEmpty}
                  isNoResults={groupsNoResults}
                  searchNoResultsMessage={
                    "Nie znaleziono grupy o podanej nazwie"
                  }
                  emptyMessage={"Wyszukaj grupy"}
                  loadingElementPros={{
                    hasAvatar: true,
                    hasDetail: false,
                    hasTitle: true,
                  }}
                >
                  {!searchGroupDebouncedValue && (
                    <>
                      {selectedGroups.map((entity) => (
                        <LiElement
                          key={entity.id}
                          entity={entity}
                          data={{
                            title: entity.name,
                            avatarURL: "",
                          }}
                        />
                      ))}
                      <LiElementSeparator />
                    </>
                  )}
                  {groups.map((entity) => (
                    <LiElement
                      key={entity.id}
                      entity={entity}
                      data={{
                        title: entity.name,
                        avatarURL: "",
                      }}
                    />
                  ))}
                </SelectDataList>
              </div>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              onClick={handleClose}
              type={"button"}
              variant={"flat"}
              variantColor={"muted"}
              className={"sm:w-full"}
            >
              Anuluj
            </Button>
            <Button
              onClick={form.handleSubmit(onSubmit)}
              type={"submit"}
              variant={"flat"}
              variantColor={"brand"}
              className={"sm:w-full"}
            >
              Zapisz
            </Button>
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
