import {
  DirectoryDirI,
  DirectoryElementsI,
  DirectoryFileI,
} from "@/types/files";
import {
  ZustandHookSelectors,
  createSelectorHooks,
} from "auto-zustand-selectors-hook";
import { create } from "zustand";
import { persist } from "zustand/middleware";

type Action = {
  setModel: (payload: "my_drive" | "shared_with_me") => void;
  setSelected: (payload: {
    files: DirectoryFileI[];
    directories: DirectoryDirI[];
  }) => void;
  setFileToOpen: (payload: DirectoryFileI) => void;
  resetSelected: () => void;
  isSelected: (id: string, variant?: "file" | "directory") => boolean;
  selectedSize: (variant?: "file" | "directory") => number;
  setOpenViewer: (open: boolean) => void;
  setOpenEditName: (open: boolean) => void;
  setOpenShare: (open: boolean) => void;
  setOpenMove: (open: boolean) => void;
  setOpenDownload: (open: boolean) => void;
  setOpenMoreInfo: (open: boolean) => void;
  setOpenDelete: (open: boolean) => void;
  setOpenCreateFile: (open: boolean) => void;
  setOpenCreateFolder: (open: boolean) => void;
  setOpenCreateDir: (open: boolean) => void;
  OnFSEntityClick: (params: {
    file?: DirectoryFileI;
    directory?: DirectoryDirI;
    event?: React.PointerEvent;
  }) => void;
};
type State = {
  model: "my_drive" | "shared_with_me";
  selected: DirectoryElementsI;
  fileToOpen?: DirectoryFileI;
  openViewer: boolean;
  openDelete: boolean;
  openEditName: boolean;
  openShare: boolean;
  openMove: boolean;
  openDownload: boolean;
  openMoreInfo: boolean;
  openCreateFile: boolean;
  openCreateFolder: boolean;
  openCreateDir: boolean;
};

const useFilesStorageBase = create<Action & State>()(
  persist(
    (set, get) => ({
      model: "my_drive",
      selected: { files: [], directories: [] },
      openDelete: false,
      fileToOpen: undefined,
      openViewer: false,
      openEditName: false,
      openShare: false,
      openMove: false,
      openDownload: false,
      openMoreInfo: false,
      openCreateFile: false,
      openCreateFolder: false,
      openCreateDir: false,
      setFileToOpen: (payload) => set({ fileToOpen: payload }),
      setSelected: (payload) => set({ selected: payload }),
      resetSelected: () => set({ selected: { files: [], directories: [] } }),
      isSelected: (id: string, variant) => {
        const { selected } = get();
        switch (variant) {
          case "file":
            return selected.files.some((element) => element.id === id);
          case "directory":
            return selected.directories.some((element) => element.id === id);
          default:
            return (
              selected.files.some((element) => element.id === id) ||
              selected.directories.some((element) => element.id === id)
            );
        }
      },
      selectedSize: (variant) => {
        const { selected } = get();
        switch (variant) {
          case "file":
            return selected.files.length || 0;
          case "directory":
            return selected.directories.length || 0;
          default:
            return selected.files.length + selected.directories.length || 0;
        }
      },
      setModel: (payload) => set({ model: payload }),
      setOpenViewer: (open: boolean) => set({ openViewer: open }),
      setOpenEditName: (open: boolean) => set({ openEditName: open }),
      setOpenShare: (open: boolean) => set({ openShare: open }),
      setOpenMove: (open: boolean) => set({ openMove: open }),
      setOpenDownload: (open: boolean) => set({ openDownload: open }),
      setOpenMoreInfo: (open: boolean) => set({ openMoreInfo: open }),
      setOpenDelete: (open: boolean) => set({ openDelete: open }),
      setOpenCreateFile: (open: boolean) => set({ openCreateFile: open }),
      setOpenCreateFolder: (open: boolean) => set({ openCreateFolder: open }),
      setOpenCreateDir: (open: boolean) => set({ openCreateDir: open }),
      OnFSEntityClick: ({ file, directory, event }) => {
        const { isSelected, selected, setSelected } = get();
        const isTouch = event?.pointerType === "touch";
        const selectedCopy = { ...selected };

        const isFile = !!file;
        const isDirectory = !!directory;

        const isFileSelected = isFile && isSelected(file.id, "file");
        const isDirectorySelected =
          isDirectory && isSelected(directory.id, "directory");

        const element = file || directory;
        const selectedVariant = file ? "files" : "directories";
        const isSelectedElement = isFileSelected || isDirectorySelected;

        if (!element) return;

        if (isTouch) {
          if (isSelectedElement) {
            const newSelected = selectedCopy[selectedVariant].filter(
              (el: DirectoryFileI | DirectoryDirI) => el.id !== element.id,
            );
            setSelected({ ...selectedCopy, [selectedVariant]: newSelected });
          } else {
            if (file) {
              (selectedCopy[selectedVariant] as DirectoryFileI[]).push(file);
            } else if (directory) {
              (selectedCopy[selectedVariant] as DirectoryDirI[]).push(
                directory,
              );
            }
            setSelected({ ...selectedCopy });
          }
          return;
        }

        if (event?.ctrlKey) {
          if (isSelectedElement) {
            const newSelected = selectedCopy[selectedVariant].filter(
              (el: DirectoryFileI | DirectoryDirI) => el.id !== element.id,
            );
            setSelected({ ...selectedCopy, [selectedVariant]: newSelected });
          } else {
            if (file) {
              (selectedCopy[selectedVariant] as DirectoryFileI[]).push(file);
            } else if (directory) {
              (selectedCopy[selectedVariant] as DirectoryDirI[]).push(
                directory,
              );
            }
            setSelected({ ...selectedCopy });
          }
        } else if (event?.shiftKey) {
          //select elements between the last selected and the current selected
        } else {
          setSelected({
            files:
              selectedVariant === "files" ? [element as DirectoryFileI] : [],
            directories:
              selectedVariant === "directories"
                ? [element as DirectoryDirI]
                : [],
          });
        }
      },
    }),
    {
      name: "files-storage",
      partialize: (state) => ({
        foo: state.model,
      }),
    },
  ),
);

const useFilesStorage = createSelectorHooks(
  useFilesStorageBase,
) as typeof useFilesStorageBase & ZustandHookSelectors<Action & State>;

export { useFilesStorage };
