import { Button } from "@/components/ui/button/Button";
import { LocationI } from "@/types/locations";
import { MapPin } from "lucide-react";
import { Link } from "react-router-dom";

export default function LocationLink({
  location,
  className,
}: {
  location: LocationI;
  className?: string;
}) {
  return (
    <Link
      to={`https://www.google.com/maps/place/${location.coordinates}`}
      target={"_blank"}
      className={className}
    >
      <Button icon={<MapPin />} iconPosition={"left"}>
        Nawiguj do
      </Button>
    </Link>
  );
}
