import { cn } from '@/lib/utils'
import { Check } from 'lucide-react'
import React from 'react'

interface ThemeModeButtonProps {
    className?: string,
    label: string, 
    active: boolean, 
    themeMode: string, 
    setThemeMode: () => void
} 

export default function ThemeModeButton({className, label, active, themeMode, setThemeMode}: ThemeModeButtonProps) {
    return (
        <div className={"flex flex-col gap-2 w-[12rem] h-[8rem] shrink"}>
            <button 
                data-theme={themeMode}
                onClick={setThemeMode}
                className={cn(
                    "w-full h-full bg-bg relative cursor-pointer flex items-end justify-end rounded-lg border border-border",
                    active && "border-fg-brand",
                    className
                )}
            >
                <div className={"w-[7rem] h-[5rem] shrink flex p-3 mt-4 ml-4 bg-bg-container rounded-lg border border-border-layout"}>
                    <p className={"font-medium text-md text-fg-primary"}>Aa</p>
                </div>
                {active &&
                    <span className={"absolute -top-1 -right-1 p-1 rounded-full bg-bg-brand"}>
                        <Check className={"w-3 h-3 stroke-fg-brand-on"}/>
                    </span>
                }
            </button>
            <p className={"text-sm text-fg-secondary"}>
                {label}
            </p>
        </div>
    )
}