import { Input } from "@/components/ui/input/Input";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { useMainLayoutPageContext } from "@/page/MainLayout";
import { Search } from "lucide-react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

type GroupsHeaderProps = {
  onSearchChange: Dispatch<SetStateAction<string>>;
  searchValue: string;
};

export default function GroupsHeader({
  onSearchChange,
  searchValue,
}: GroupsHeaderProps) {
  const breakpoint = useMediaQueryHook("sm");
  const { ref } = useMainLayoutPageContext();

  const [showShadow, setShowShadow] = useState<boolean>(false);

  useEffect(() => {
    const element = ref?.current;
    if (element) {
      element.addEventListener("scroll", () => {
        if (element.scrollTop > 44) {
          setShowShadow(true);
        } else {
          setShowShadow(false);
        }
      });
    }
  }, [ref]);

  return (
    <div
      className={cn(
        "grid grid-cols-3 grid-rows-2 gap-3 p-4 rounded-lg border-border border-1 bg-bg-container sticky top-0 z-10",
        showShadow ? "shadow-md" : null,
      )}
    >
      <h2 className={"font-semibold text-xl text-fg-primary self-center"}>
        Grupy
      </h2>
      <div
        className={cn(
          breakpoint
            ? "col-[1_/_4] row-[2_/_2]"
            : "col-[2_/_span_2] justify-self-end",
        )}
      >
        <Input
          className={cn(breakpoint ? "w-full" : "max-w-[30ch]")}
          value={searchValue}
          onChange={(e) => onSearchChange(e.target.value)}
          placeholder={"Szukaj..."}
          startContent={<Search className={"ml-2"} />}
        />
      </div>
    </div>
  );
}
