import { buttonVariants } from "@/components/ui/button/Button";
import { Spinner } from "@/components/ui/spinner/Spinner";
import { toastVariants } from "@/components/ui/toast/useToast";
import { cn } from "@/lib/utils";
import * as ToastPrimitives from "@radix-ui/react-toast";
import { type VariantProps } from "class-variance-authority";
import { AlertCircle, Check, X, XCircle } from "lucide-react";
import * as React from "react";

const ToastProvider = ToastPrimitives.Provider;

const ToastViewport = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Viewport>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    className={cn(
      "fixed bottom-0 right-0 top-auto z-50 flex gap-1 max-h-screen w-full flex-col-reverse p-6 sm:flex-col max-w-[420px]",
      className,
    )}
    {...props}
  />
));
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;

const Toast = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root> &
    VariantProps<typeof toastVariants>
>(({ className, variant, ...props }, ref) => {
  return (
    <ToastPrimitives.Root
      ref={ref}
      className={cn(
        "relative group bg-bg-container text-fg-primary text-sm pointer-events-auto flex flex-row gap-3 w-full overflow-hidden rounded-lg border-1 border-border-layout px-4 py-3 pr-11 shadow-md transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full",
        className,
      )}
      {...props}
    />
  );
});
Toast.displayName = ToastPrimitives.Root.displayName;

const ToastIndicator = React.forwardRef<
  HTMLDivElement,
  VariantProps<typeof toastVariants> & {
    children?: React.ReactNode;
    className?: string;
    classNames?: { outerStylesClass?: string; innerStylesClass?: string };
  }
>(({ className, classNames, children, variant }, ref) => {
  const indicatorIcon = React.useMemo(() => {
    switch (variant) {
      case "success":
        return <Check className={"w-3 h-3 stroke-bg-success-subtle"} />;
      case "warning":
        return <AlertCircle className={"w-3 h-3 stroke-bg-warning-subtle"} />;
      case "destructive":
        return <XCircle className={"w-3 h-3 stroke-bg-destructive-subtle"} />;
      case "promise":
        return <Spinner />;
      default:
        return null;
    }
  }, [variant]);

  if (indicatorIcon) {
    return (
      <div
        ref={ref}
        className={cn(
          toastVariants({ variant }),
          classNames?.outerStylesClass,
          className,
        )}
      >
        <div
          className={cn(
            "flex justify-center items-center w-full h-full rounded-full",
            classNames?.innerStylesClass,
          )}
        >
          {children ? children : indicatorIcon}
        </div>
      </div>
    );
  }
  return null;
});

const ToastAction = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Action>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Action>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Action
    ref={ref}
    className={cn(
      buttonVariants({
        variant: "flat",
        variantColor: "muted",
        size: "sm",
      }),
      "h-9 min-h-9",
      className,
    )}
    {...props}
  />
));
ToastAction.displayName = ToastPrimitives.Action.displayName;

const ToastClose = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Close>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Close>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Close
    ref={ref}
    className={cn(
      buttonVariants({
        variant: "ghost",
        variantColor: "muted",
        size: "sm",
        iconPosition: "only",
      }),
      "absolute top-2 right-2 min-w-8 w-8 min-h-8 h-8",
    )}
    toast-close={""}
    {...props}
  >
    <X className={"h-5 w-5"} />
  </ToastPrimitives.Close>
));
ToastClose.displayName = ToastPrimitives.Close.displayName;

const ToastTitle = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Title>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Title>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Title
    ref={ref}
    className={cn(
      "text-fg-primary font-medium text-md sm:text-sm w-full overflow-hidden text-ellipsis",
      className,
    )}
    {...props}
  />
));
ToastTitle.displayName = ToastPrimitives.Title.displayName;

const ToastDescription = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Description>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Description
    ref={ref}
    className={cn(
      "text-fg-secondary text-sm sm:text-xs w-full overflow-hidden break-all text-ellipsis",
      className,
    )}
    {...props}
  />
));
ToastDescription.displayName = ToastPrimitives.Description.displayName;

type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>;

type ToastActionElement = React.ReactElement<typeof ToastAction>;

export {
  type ToastProps,
  type ToastActionElement,
  ToastProvider,
  ToastViewport,
  Toast,
  ToastTitle,
  ToastDescription,
  ToastClose,
  ToastAction,
  ToastIndicator,
};
