import {
  deleteConversation,
  editConversation,
  getChat,
  getCreateConversationUsers,
  getLastConversations,
  postChatFileUpload,
  postConversation,
  postConversationPropsT,
  postFileUploadPropsT,
} from "@/api/endpoints/chat";
import { queryClient } from "@/api/query-client";
import { ChatEditSchemaType } from "@/schemas/chat.schema";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";

export const useGetChatQuery = (id: number) => {
  return useQuery({
    queryKey: ["chat", id],
    queryFn: () => getChat(id),
  });
};

export const useGetCreateConversationUsersInfiniteQuery = ({
  name,
}: {
  name?: string;
}) => {
  return useInfiniteQuery({
    queryKey: ["conversationUsersInfinite", name],
    queryFn: ({ pageParam }) =>
      getCreateConversationUsers({ ...pageParam, name }),
    initialPageParam: { page: 0, pageSize: 20, name: undefined },
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length < lastPageParam.pageSize) {
        return undefined;
      }
      return { ...lastPageParam, page: lastPageParam.page + 1 };
    },
  });
};

export const useGetLastConversationsInfiniteQuery = ({
  name,
}: {
  name?: string;
}) => {
  return useInfiniteQuery({
    queryKey: ["lastConversationsInfinite", name],
    queryFn: ({ pageParam }) => getLastConversations({ ...pageParam, name }),
    initialPageParam: { page: 0, pageSize: 20, name: undefined },
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length < lastPageParam.pageSize) {
        return undefined;
      }
      return { ...lastPageParam, page: lastPageParam.page + 1 };
    },
  });
};

export const usePostConversationMutation = () => {
  return useMutation({
    mutationFn: (data: postConversationPropsT) => postConversation(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["lastConversationsInfinite"],
      });
    },
  });
};

export const useEditConversationMutation = () => {
  return useMutation({
    mutationFn: (data: ChatEditSchemaType) => editConversation(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["lastConversationsInfinite"],
      });
    },
  });
};

export const useDeleteConversationMutation = () => {
  return useMutation({
    mutationFn: (id: number) => deleteConversation(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["lastConversationsInfinite"],
      });
    },
  });
};

export const usePostFileUploadMutation = () => {
  return useMutation({
    mutationKey: ["postChatFileUpload"],
    mutationFn: (data: postFileUploadPropsT) => postChatFileUpload(data),
  });
};
