import { permissionChangesI } from "@/types/files";
import { InferType, object, string } from "yup";

const CreateDirectorySchema = object({
  name: string().required("Nazwa folderu jest wymagana"),
  parentDirId: string().required(),
});

type CreateDirectorySchemaType = InferType<typeof CreateDirectorySchema>;

const EditDirectorySchema = object({
  id: string().required(),
  name: string().required("Nazwa folderu jest wymagana"),
  parentDirId: string().optional(),
});

type EditDirectorySchemaType = InferType<typeof EditDirectorySchema>;

type EditDirectoryType = Omit<EditDirectorySchemaType, "name"> & {
  name?: string | undefined;
  permissionChanges?: permissionChangesI[];
};

export { CreateDirectorySchema, EditDirectorySchema };
export type {
  CreateDirectorySchemaType,
  EditDirectorySchemaType,
  EditDirectoryType,
};
