import { RippleType } from "@/components/ui/ripple/useRipple";
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import React from "react";

export interface RippleProps extends React.ComponentPropsWithoutRef<"div"> {
  ripples: RippleType[];
  color?: string;
  motionProps?: HTMLMotionProps<"span">;
  style?: React.CSSProperties;
  onClear: (key: React.Key) => void;
}

const clamp = (value: number, min: number, max: number) => {
  return Math.min(Math.max(value, min), max);
};

function Ripple(props: RippleProps) {
  const {
    ripples = [],
    motionProps,
    color = "currentColor",
    style,
    onClear,
  } = props;

  return (
    <>
      {ripples.map((ripple) => {
        const duration = clamp(
          0.01 * ripple.size,
          0.4,
          ripple.size > 100 ? 0.75 : 0.5,
        );

        return (
          <AnimatePresence key={ripple.key} mode={"popLayout"}>
            <motion.span
              animate={{ transform: "scale(2)", opacity: 0 }}
              exit={{ opacity: 0 }}
              initial={{ transform: "scale(0)", opacity: 0.25 }}
              style={{
                position: "absolute",
                backgroundColor: color,
                borderRadius: "100%",
                transformOrigin: "center",
                pointerEvents: "none",
                zIndex: 10,
                top: ripple.y,
                left: ripple.x,
                width: `${ripple.size}px`,
                height: `${ripple.size}px`,
                ...style,
              }}
              transition={{ duration }}
              onAnimationComplete={() => {
                onClear(ripple.key);
              }}
              {...motionProps}
            />
          </AnimatePresence>
        );
      })}
    </>
  );
}

Ripple.displayName = "Ripple";

export default Ripple;
