import ChatEditName from "@/components/features/chat/form/ChatEditName";
import ChatUsersEdit from "@/components/features/chat/form/ChatUsersEdit";
import DeleteChat from "@/components/features/chat/form/DeleteChat";
import { Button } from "@/components/ui/button/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { useCredentials } from "@/store/authStore";
import { useChatStore } from "@/store/chatStore";
import { conversationTypeT } from "@/types/chat";
import { motion } from "framer-motion";
import {
  ArrowLeft,
  MoreVertical,
  RotateCw,
  TextCursorInput,
  Trash2,
  UserPlus,
} from "lucide-react";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

type ChatConversationHeaderProps = {
  id: number;
  name: string;
  conversationType: conversationTypeT;
  onReconnect: () => void;
};

export default function ChatHeader({
  id,
  name,
  conversationType,
  onReconnect,
}: ChatConversationHeaderProps) {
  const navigate = useNavigate();
  const { lvl } = useCredentials();
  const isError = useChatStore.useIsError();
  const isLoading = useChatStore.useIsLoading();
  const breakpoint = useMediaQueryHook("lg");

  const [openEditNameForm, setOpenEditNameForm] = useState<boolean>(false);
  const [openEditUsersForm, setOpenEditUsersForm] = useState<boolean>(false);
  const [openDeleteForm, setOpenDeleteForm] = useState<boolean>(false);

  const handleGoBack = () => {
    navigate("/chat");
  };

  return (
    <Fragment>
      {lvl > 0 && (
        <Fragment>
          <ChatUsersEdit
            id={id}
            open={openEditUsersForm}
            onOpenChange={setOpenEditUsersForm}
          />
          <ChatEditName
            id={id}
            name={name}
            open={openEditNameForm}
            onOpenChange={setOpenEditNameForm}
          />
          <DeleteChat
            name={name}
            id={id}
            open={openDeleteForm}
            onOpenChange={setOpenDeleteForm}
          />
        </Fragment>
      )}

      <header
        className={"relative p-4 border-b-1 border-border items-center gap-3"}
      >
        <div className={"flex items-center gap-3 h-11"}>
          <ChatConnectionError
            isError={isError}
            onReconnect={onReconnect}
            isLoading={isLoading}
          />
          {breakpoint && (
            <Button
              variant={"ghost"}
              variantColor={"muted"}
              icon={<ArrowLeft />}
              iconPosition={"only"}
              onClick={handleGoBack}
            />
          )}

          <h2 className={"font-medium text-fg-primary truncate w-full"}>
            {name}
          </h2>

          {lvl > 0 && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant={"ghost"}
                  variantColor={"muted"}
                  icon={<MoreVertical />}
                  iconPosition={"only"}
                />
              </DropdownMenuTrigger>
              <DropdownMenuContent align={"end"}>
                {conversationType === "mixed" && (
                  <Fragment>
                    <DropdownMenuGroup>
                      <DropdownMenuItem
                        onClick={() => setOpenEditNameForm(true)}
                      >
                        <TextCursorInput className={"h-4 w-4"} />
                        Zmień nazwę
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => setOpenEditUsersForm(true)}
                      >
                        <UserPlus className={"h-4 w-4"} />
                        Edytuj uczestników
                      </DropdownMenuItem>
                    </DropdownMenuGroup>
                    <DropdownMenuSeparator />
                  </Fragment>
                )}

                <DropdownMenuGroup>
                  <DropdownMenuItem
                    className={"text-fg-destructive"}
                    onClick={() => setOpenDeleteForm(true)}
                  >
                    <Trash2 className={"h-4 w-4"} />
                    Usuń
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      </header>
    </Fragment>
  );
}

type ChatConnectionErrorProps = {
  isError: boolean;
  isLoading: boolean;
  onReconnect: () => void;
};

function ChatConnectionError({
  isError,
  isLoading,
  onReconnect,
}: ChatConnectionErrorProps) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: isError ? 1 : 0,
      }}
      transition={{ ease: "easeOut", duration: 0.2 }}
      className={cn(
        "overflow-hidden absolute z-50 -bottom-13 left-2/4 -translate-x-2/4 tra flex h-fit items-center gap-1 w-fit pl-3 bg-bg-destructive-subtle rounded-lg",
        isError ? "flex" : "hidden",
      )}
    >
      <p className={"text-fg-destructive text-xs text-nowrap"}>
        Wystąpił błąd połączenia
      </p>
      <Button
        onClick={onReconnect}
        size={"sm"}
        variant={"ghost"}
        variantColor={"destructive"}
        icon={
          <RotateCw className={cn(isLoading && "animate-spinner-ease-spin")} />
        }
        iconPosition={"only"}
        className={"rounded-none"}
      />
    </motion.div>
  );
}
