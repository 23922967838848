import { cn } from "@/lib/utils";
import { motion } from "framer-motion";
import { ArrowLeft } from "lucide-react";
import { useRef, useState } from "react";

export function MeetingLink({
  meetingURL,
  className,
}: {
  meetingURL: string;
  className?: string;
}) {
  const anchorRef = useRef<HTMLAnchorElement>(null);

  const [isHovered, setHovered] = useState<boolean>(false);

  const arrowVariants = {
    hidden: { opacity: 0, x: -8 },
    visible: { opacity: 1, x: 4 },
  };

  return (
    <motion.button
      className={cn(
        "flex justify-start items-center truncate text-start text-fg-muted text-[clamp(0.6rem,1.5vw,0.875rem)] hover:underline",
        className,
      )}
      onClick={() => anchorRef.current?.click()}
      onHoverStart={() => setHovered(true)}
      onHoverEnd={() => setHovered(false)}
    >
      Otwórz link
      <motion.span
        variants={arrowVariants}
        initial="hidden"
        animate={isHovered ? "visible" : "hidden"}
        transition={{ duration: 0.2 }}
      >
        <ArrowLeft className={"w-3 h-3"} />
      </motion.span>
      <a
        ref={anchorRef}
        href={meetingURL}
        target={"_blank"}
        className={"hidden"}
        rel={"noreferrer"}
      />
    </motion.button>
  );
}
