import FilesStorageContentGrid from "@/components/features/files/layouts/files-page/FilesPageContentGrid";
import FilesStorageEmpty from "@/components/features/files/layouts/files-page/FilesPageEmpty";
import { DirectoryDirI, DirectoryFileI } from "@/types/files";
import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { useRef } from "react";

type FilesStorageContentProps = {
  files?: Array<DirectoryFileI>;
  directories?: Array<DirectoryDirI>;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
};

export default function FilesPageContent({
  files,
  directories,
  isLoading,
  isError,
  isSuccess,
}: FilesStorageContentProps) {
  const contentRef = useRef<HTMLDivElement>(null);

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 100,
      tolerance: 15,
    },
  });
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      delay: 100,
      tolerance: 15,
    },
  });
  const sensors = useSensors(touchSensor, mouseSensor);

  let content;

  if (isSuccess && !files?.length && !directories?.length) {
    content = <FilesStorageEmpty />;
  } else if (isError) {
    content = (
      <div className={"flex justify-center py-4"}>
        <p className={"text-sm text-fg-muted italic"}>
          Wystąpił błąd podczas ładowania folderu
        </p>
      </div>
    );
  } else {
    content = (
      <FilesStorageContentGrid
        files={files}
        directories={directories}
        isSuccess={isSuccess}
        isLoading={isLoading}
        isError={isError}
      />
    );
  }
  return (
    <DndContext sensors={sensors}>
      <div
        ref={contentRef}
        className={"flex flex-col gap-4 overscroll-contain"}
      >
        {content}
      </div>
    </DndContext>
  );
}
