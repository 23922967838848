import { flatQueryPages, useInfiniteQueryResult } from "@/api/api-utils";
import { useGetLastConversationsInfiniteQuery } from "@/api/queries/chatQueries";
import {
  ConversationsListItem,
  ConversationsListSkeletonItem,
} from "@/components/features/chat/conversations-list/ConversationsListItem";
import { Button } from "@/components/ui/button/Button";
import { InfiniteScroll } from "@/components/ui/infinite-scroll/InfiniteScroll";
import { Input, InputActionButton } from "@/components/ui/input/Input";
import { useDebounceValue } from "@/hooks/useDebounceValue";
import { cn } from "@/lib/utils";
import { Search, Settings2, X } from "lucide-react";
import { Fragment, useState } from "react";

type ChatConversationsListProps = {
  openCreateConversation: () => void;
  className?: string;
};

export default function ConversationsList({
  className,
  openCreateConversation,
}: ChatConversationsListProps) {
  const [searchValue, setSearchValue] = useState<string>("");
  const searchDebouncedValue = useDebounceValue(searchValue, 400);

  const getLastConversationsQuery = useGetLastConversationsInfiniteQuery({
    name: searchDebouncedValue,
  });

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } =
    getLastConversationsQuery;

  const {
    data: conversations,
    isEmpty: conversationsIsEmpty,
    noResults: conversationsNoResults,
  } = useInfiniteQueryResult(data, searchDebouncedValue);

  const next = async () => {
    await fetchNextPage();
  };

  const handleOpenCreateConversation = () => {
    openCreateConversation();
  };
  return (
    <section
      className={cn(
        "flex flex-col border-border border-1 bg-bg-container rounded-lg h-full",
        className,
      )}
    >
      <div className={"flex gap-2 p-4 border-b-1 border-border"}>
        <Input
          placeholder={"Wyszukaj..."}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          startContent={<Search className={"ml-2"} />}
          endContent={
            <InputActionButton
              disabled={!searchValue.length}
              onClick={() => setSearchValue("")}
              icon={<X />}
            />
          }
        />
        <Button
          variant={"outline"}
          variantColor={"muted"}
          icon={<Settings2 />}
          iconPosition={"only"}
          onClick={handleOpenCreateConversation}
        />
      </div>
      <div className={"flex flex-col h-full grow overflow-auto p-4"}>
        {isLoading && (
          <Fragment>
            <ConversationsListSkeletonItem />
            <ConversationsListSkeletonItem />
            <ConversationsListSkeletonItem />
            <ConversationsListSkeletonItem />
          </Fragment>
        )}
        {conversationsNoResults && !isFetching ? (
          <div className={"flex flex-col gap-4 items-center py-11"}>
            <h5 className={"text-fg-muted text-sm text-center"}>
              Brak konwersacji o podanej nazwie
            </h5>
          </div>
        ) : null}
        {conversationsIsEmpty && !isFetching ? (
          <div className={"flex flex-col gap-4 items-center py-11"}>
            <h5 className={"text-fg-muted text-sm text-center"}>
              Brak konwersacji
            </h5>
          </div>
        ) : null}
        {conversations.map((conversation) => (
          <ConversationsListItem key={conversation.id} {...conversation} />
        ))}
        <InfiniteScroll
          hasNextPage={hasNextPage}
          isLoading={isLoading}
          isFetching={isFetching}
          next={next}
          threshold={0.65}
        >
          {hasNextPage && <ConversationsListSkeletonItem />}
        </InfiniteScroll>
      </div>
    </section>
  );
}
