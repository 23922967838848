import { buttonVariants } from "@/components/ui/button/Button";
import { Spinner } from "@/components/ui/spinner/Spinner";
import { cn } from "@/lib/utils";
import {
  FullscreenButton,
  MuteButton,
  PIPButton,
  PlayButton,
  useMediaState,
} from "@vidstack/react";
import {
  Minimize as FullscreenExitIcon,
  Maximize as FullscreenIcon,
  VolumeX as MuteIcon,
  PauseIcon,
  PictureInPictureIcon as PictureInPictureExitIcon,
  PictureInPicture2 as PictureInPictureIcon,
  PlayIcon,
  Volume2 as VolumeHighIcon,
  Volume1 as VolumeLowIcon,
} from "lucide-react";
import { useMemo } from "react";

export interface MediaButtonProps {
  className?: string;
}

export function Play({ className }: MediaButtonProps) {
  const isPaused = useMediaState("paused");
  const buffered = useMediaState("buffered");

  const PlayButtonIcon = useMemo(() => {
    if (buffered.length) {
      return isPaused ? (
        <PlayIcon className={"min-w-5 min-h-5 translate-x-px"} />
      ) : (
        <PauseIcon className={"min-w-5 min-h-5"} />
      );
    } else {
      return <Spinner variant={"muted"} />;
    }
  }, [isPaused, buffered]);

  return (
    <PlayButton
      className={cn(
        buttonVariants({
          variant: "ghost",
          variantColor: "muted",
          iconPosition: "only",
          size: "sm",
        }),
        className,
      )}
    >
      {PlayButtonIcon}
    </PlayButton>
  );
}

export function Mute() {
  const volume = useMediaState("volume"),
    isMuted = useMediaState("muted");
  return (
    <MuteButton
      className={buttonVariants({
        variant: "ghost",
        variantColor: "muted",
        iconPosition: "only",
        size: "sm",
      })}
    >
      {isMuted || volume == 0 ? (
        <MuteIcon className={"min-w-5 min-h-5"} />
      ) : volume < 0.5 ? (
        <VolumeLowIcon className={"min-w-5 min-h-5"} />
      ) : (
        <VolumeHighIcon className={"min-w-5 min-h-5"} />
      )}
    </MuteButton>
  );
}

export function Pip() {
  const isActive = useMediaState("pictureInPicture");
  return (
    <PIPButton
      className={buttonVariants({
        variant: "ghost",
        variantColor: "muted",
        iconPosition: "only",
        size: "sm",
      })}
    >
      {isActive ? (
        <PictureInPictureExitIcon className={"min-w-5 min-h-5"} />
      ) : (
        <PictureInPictureIcon className={"min-w-5 min-h-5"} />
      )}
    </PIPButton>
  );
}

export function Fullscreen() {
  const isActive = useMediaState("fullscreen");
  return (
    <FullscreenButton
      className={buttonVariants({
        variant: "ghost",
        variantColor: "muted",
        iconPosition: "only",
        size: "sm",
      })}
    >
      {isActive ? (
        <FullscreenExitIcon className={"min-w-5 min-h-5"} />
      ) : (
        <FullscreenIcon className={"min-w-5 min-h-5"} />
      )}
    </FullscreenButton>
  );
}
