import { useCallback } from "react";
import { useMediaQuery } from "react-responsive";

function useMediaQueryHook(size: "sm" | "md" | "lg" | "xl") {
  const sizeOfMediaQuery = {
    sm: "640",
    md: "959",
    lg: "1200",
    xl: "1440",
  } as const;
  const response = useCallback(() => {
    return useMediaQuery({ query: `(max-width: ${sizeOfMediaQuery[size]}px)` });
  }, [size]);
  return response();
}

export default useMediaQueryHook;
