import { cn } from "@/lib/utils";
import { format } from "date-fns";

export default function MeetingStartTime({
  startDate,
  className,
}: {
  startDate: string;
  className?: string;
}) {
  return (
    <p
      className={cn(
        "text-fg-muted truncate text-[clamp(0.6rem,1.5vw,0.875rem)]",
        className,
      )}
    >
      {format(new Date(startDate), "H:mm")}
    </p>
  );
}
