import {
  CHAT_MAX_FILE_SIZE,
  CHAT_MAX_MESSAGE_SIZE,
  messageFormT,
} from "@/components/features/chat/layout/Chat";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import FileInputCard from "@/components/ui/input/file-input/FileInputCard";
import {
  AutosizeTextAreaRef,
  AutosizeTextarea,
} from "@/components/ui/input/textarea/Textarea";
import { useToast } from "@/components/ui/toast/useToast";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip/Tooltip";
import { formatBytes } from "@/lib/utils";
import { useChatStore } from "@/store/chatStore";
import { ImagePlus, Paperclip, Send, X } from "lucide-react";
import { ChangeEvent, KeyboardEvent, useRef, useState } from "react";

type ChatConversationFooterProps = {
  onSubmit: (props: messageFormT) => void;
};

export default function ChatFooter({ onSubmit }: ChatConversationFooterProps) {
  const { toast } = useToast();
  const isConnected = useChatStore.useIsConnected();
  const replyTo = useChatStore.useReplyingTo();
  const setReplyTo = useChatStore.useSetReplyingTo();

  const textAreaRef = useRef<AutosizeTextAreaRef>(null);
  const attachmentsInputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [text, setText] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && event.shiftKey) {
      return event;
    } else if (event.key === "Enter") {
      event.preventDefault();
      handleOnSubmit();
    }
  };

  const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const text = event.target.value;
    if (text.trim().length > CHAT_MAX_MESSAGE_SIZE) {
      setError(`Wiadomość jest za długa,  ${text.trim().length}/2056 znaków`);
    } else {
      setError(null);
    }
    setText(text);
  };

  const handleAddFiles = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const newFiles = Array.from(e.target.files).map((file) => {
      if (file.size > CHAT_MAX_FILE_SIZE) {
        toast({
          variant: "destructive",
          title: "Błąd",
          description: "Maksymalny rozmiar pliku to 500MB",
        });
      }
      return file;
    });
    setFiles([...files, ...newFiles]);
    return e.target.files;
  };

  const handleRemoveFile = (file: File) => {
    setFiles(files.filter((f) => f !== file));
  };

  const handleOnSubmit = () => {
    if (!text && !files.length) return;
    onSubmit({
      content: text.trim(),
      files: files,
      replyingToId: replyTo ? replyTo.id : undefined,
      replyingTo: replyTo ? replyTo : undefined,
    });
    setText("");
    setFiles([]);
    setReplyTo(undefined);
  };

  return (
    <footer
      className={"flex flex-col p-4 border-t-1 border-border gap-2 h-fit"}
    >
      {replyTo ? (
        <div className={"flex gap-2 items-center"}>
          <span className={"text-sm text-fg-muted shrink-0"}>Odpowiedz:</span>
          <p className={"text-sm  w-full truncate"}>{replyTo.content}</p>
          <Button
            size={"sm"}
            variant={"ghost"}
            variantColor={"muted"}
            className={"min-h-8 h-8 min-w-8 w-8 "}
            icon={<X />}
            iconPosition={"only"}
            onClick={() => setReplyTo(undefined)}
          />
        </div>
      ) : null}
      <div className={"flex gap-1 overflow-x-auto empty:hidden"}>
        <TooltipProvider delayDuration={200}>
          {files.map((file, index) => (
            <Tooltip key={file.name + "_" + index}>
              <TooltipTrigger asChild>
                <FileInputCard
                  className={"min-w-14 w-14 min-h-14 h-14 rounded-md"}
                  contentClassName={"gap-1 [&_span]:hidden [&_p]:text-[9px]"}
                  file={file}
                  onRemove={() => handleRemoveFile(file)}
                />
              </TooltipTrigger>
              <TooltipContent className={"max-w-[256px]"}>
                <p className={"text-xs text-fg-secondary truncate"}>
                  {file.name}
                </p>
                <p className={"text-xs text-fg-secondary truncate"}>
                  {formatBytes(file.size)}
                </p>
              </TooltipContent>
            </Tooltip>
          ))}
        </TooltipProvider>
      </div>
      <div className={"relative"}>
        {error && (
          <Badge
            hideDot
            variant={"destructive"}
            size={"md"}
            className={"absolute -top-1 right-1"}
          >
            {error}
          </Badge>
        )}
        <AutosizeTextarea
          ref={textAreaRef}
          maxHeight={92}
          minHeight={44}
          offsetBorder={0}
          onKeyDown={handleKeyDown}
          placeholder={"Wiadomość...."}
          value={text}
          onChange={handleTextChange}
          className={
            "resize-none border-none min-h-11 ring-offset-none hover:bg-transparent focus-visible:outline-offset-0 focus-visible:outline-0 focus-visible:outline-none p-0 rounded-none"
          }
        />
      </div>
      <div className={"flex gap-2"}>
        <input
          ref={attachmentsInputRef}
          onChange={handleAddFiles}
          multiple
          className={"hidden"}
          type={"file"}
        />
        <Button
          type={"button"}
          variant={"outline"}
          variantColor={"muted"}
          icon={<ImagePlus />}
          iconPosition={"only"}
          onClick={() => {
            if (attachmentsInputRef.current) {
              attachmentsInputRef.current.accept =
                "image/jpeg, image/png, image/gif, image/svg+xml, image/webp, audio/mpeg, audio/ogg, audio/3gp, audio/mp4, audio/webm, audio/flac, audio/object, video/mp4, video/webm, video/3gp, video/ogg, video/avi, video/mpeg";
              attachmentsInputRef.current.click();
            }
          }}
        />
        <Button
          type={"button"}
          variant={"outline"}
          variantColor={"muted"}
          icon={<Paperclip />}
          iconPosition={"only"}
          onClick={() => {
            if (attachmentsInputRef.current) {
              attachmentsInputRef.current.accept = "*";
              attachmentsInputRef.current.click();
            }
          }}
        />
        <Button
          className={"ml-auto"}
          variant={"solid"}
          variantColor={"brand"}
          icon={<Send />}
          iconPosition={"right"}
          disabled={!isConnected || !!error}
          onClick={handleOnSubmit}
        >
          Wyślij
        </Button>
      </div>
    </footer>
  );
}
