import { Badge } from "@/components/ui/badge/Badge";
import { Link } from "@/components/ui/link/Link";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { MeetingI, MeetingsI } from "@/types/meetings";
import { EmptyObject } from "@/types/utils";
import { format } from "date-fns";
import { Dot } from "lucide-react";

export default function MeetingInfoPopover({
  data,
  isSuccess,
  isFetching,
  basicData,
}: {
  data: MeetingI | EmptyObject;
  isSuccess: boolean;
  isFetching: boolean;
  basicData: Pick<
    MeetingsI,
    "startDate" | "endDate" | "type" | "desc" | "isCancelled"
  >;
}) {
  const { startDate, endDate, type, desc, isCancelled } = basicData;
  const { location, meetingURL } = data;

  const ListElementLoading = () => {
    return (
      <div className={"flex min-h-9 items-center gap-3 max-w-[35ch]"}>
        <Skeleton className={"h-4 w-[10ch]"} />
        <Skeleton className={"h-4 w-[35ch]"} />
      </div>
    );
  };

  return (
    <div className={"p-4 flex flex-col gap-4"}>
      <div className={"flex flex-col gap-1"}>
        <div className={"flex min-h-9 items-center max-w-[35ch]"}>
          <span className={"text-fg-muted min-w-14"}>Data:</span>
          <p className={"text-fg-primary flex items-center"}>
            {format(new Date(startDate), "dd MMM yyyy")}
            <Dot />
            {format(new Date(startDate), "H:mm")}-
            {format(new Date(endDate), "H:mm")}
          </p>
        </div>
        <div className={"flex min-h-9 items-center max-w-[35ch]"}>
          <span className={"text-fg-muted min-w-14 "}>Typ:</span>
          <div className={"text-fg-primary"}>
            <Badge variant={"accent"}>
              {type === "remote" ? "Zdalnie" : "Stacionarnie"}
            </Badge>
          </div>
        </div>

        {isFetching && (
          <>
            <ListElementLoading />
            <ListElementLoading />
          </>
        )}

        {isCancelled && (
          <div className={"flex min-h-9 items-center max-w-[35ch]"}>
            <span className={"text-fg-muted min-w-14"}>Stan:</span>
            <Badge variant={"destructive"}>Odwołano spotkanie</Badge>
          </div>
        )}
        {isSuccess && meetingURL && (
          <div className={"flex min-h-9 items-center max-w-[35ch]"}>
            <span className={"text-fg-muted min-w-14"}>Link:</span>
            <Link href={meetingURL} openInNewTab>
              {meetingURL}
            </Link>
          </div>
        )}

        {isSuccess && location && (
          <div className={"flex min-h-9 items-center max-w-[35ch]"}>
            <span className={"text-fg-muted min-w-14"}>Lokacja:</span>
            <p className={"text-fg-primary"}>{location.name}</p>
          </div>
        )}
      </div>
      <div className={"flex flex-col gap-2 text-fg-primary max-w-[35ch]"}>
        <h5 className={"text-md font-medium"}>Opis</h5>
        <p className={"text-sm"}>{desc}</p>
      </div>
    </div>
  );
}
