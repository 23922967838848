import ConversationsList from "@/components/features/chat/conversations-list/ConversationsList";
import CreateChat from "@/components/features/chat/form/CreateChat";
import Chat from "@/components/features/chat/layout/Chat";
import ChatIndexEmpty from "@/components/features/chat/layout/ChatIndexEmpty";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { Fragment, useMemo, useState } from "react";
import { Route, Routes } from "react-router";

export default function ChatPage() {
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const breakpoint = useMediaQueryHook("lg");

  const handleOpenCreateConversation = () => {
    setOpenCreate(true);
  };

  const content = useMemo(() => {
    if (breakpoint) {
      return (
        <ConversationsList
          openCreateConversation={handleOpenCreateConversation}
          className={"w-full"}
        />
      );
    }
    return (
      <ChatIndexEmpty openCreateConversation={handleOpenCreateConversation} />
    );
  }, [breakpoint]);

  const asideContent = useMemo(() => {
    if (breakpoint) return null;
    return (
      <ConversationsList
        openCreateConversation={handleOpenCreateConversation}
        className={"min-w-[18rem] w-[18rem]"}
      />
    );
  }, [breakpoint]);

  return (
    <Fragment>
      <CreateChat open={openCreate} onOpenChange={setOpenCreate} />
      <div
        className={"relative flex flex-1 overflow-hidden w-full h-full gap-4"}
      >
        {asideContent}
        <Routes>
          <Route index element={content} />
          <Route path={":id"} element={<Chat />} />
        </Routes>
      </div>
    </Fragment>
  );
}
