import { useMemo } from "react";

export default function CalendarContentHours() {

  const content = useMemo(() => Array.from({length: 23}, (_, index) => index + 1), [])


  return (
    <div className={"flex flex-col w-10"}>
      <div className={"relative h-full"}/>
      {content.map((hour) => {
        return (
          <div className={"relative h-full"} key={hour}>
            <p
              aria-label={`hour-${hour}`}
              className={"absolute text-xs text-fg-muted -top-2"}
            >
              {hour}:00
            </p>
          </div>
        );
      })}
    </div>
  );
}