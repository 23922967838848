import { useGetManagedUsersQuery as useGetManagedUsers } from "@/api/queries/usersQueries";
import ActivateUser from "@/components/features/manage/manage-users/ActivateUser";
import CreateUser from "@/components/features/manage/manage-users/CreateUser";
import DeleteUser from "@/components/features/manage/manage-users/DeleteUser";
import EditUser from "@/components/features/manage/manage-users/EditUser";
import ResetPassword from "@/components/features/manage/manage-users/ResetPassword";
import { TanstackTable } from "@/components/features/manage/tanstack-table/TanstackTable";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import { AlertDialog } from "@/components/ui/dialog/AlertDialog";
import { Dialog } from "@/components/ui/dialog/Dialog";
import { Checkbox } from "@/components/ui/input/checkbox/Checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { selectedRowsI } from "@/types/table";
import { UsersI } from "@/types/users";
import { ColumnDef } from "@tanstack/react-table";
import { format, isValid, parseISO } from "date-fns";
import { ArrowUpDown, Edit, ListPlus, Plus, Trash2 } from "lucide-react";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ManageUsers() {
  const breakpoint = useMediaQueryHook("md");

  const navigate = useNavigate();

  const [openResetPassword, setOpenResetPassword] = useState<boolean>(false);
  const [openChangeActive, setOpenChangeActive] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openUserAdd, setOpenUserAdd] = useState<boolean>(false);

  const [selectedRows, setSelectedRows] = useState<selectedRowsI<UsersI>[]>([]);
  const [selectedUser, setSelectedUser] = useState<UsersI | undefined>(
    undefined,
  );

  const { data = [], isPending } = useGetManagedUsers();

  const getSelectedRowsFn = (rows: UsersI[]) => {
    setSelectedRows(
      rows.map((row) => {
        return { data: row, status: "isIdle" };
      }),
    );
    setOpenDelete(true);
  };

  const triggerPopupEdit = (user: UsersI) => {
    if (user) {
      setSelectedUser(user);
      setOpenEdit(true);
    }
  };
  const triggerPopupChangeActive = (user: UsersI) => {
    if (user) {
      setSelectedUser(user);
      setOpenChangeActive(true);
    }
  };
  const triggerPopupResetPassword = (user: UsersI) => {
    if (user) {
      setSelectedUser(user);
      setOpenResetPassword(true);
    }
  };
  const triggerPopupAddUser = () => {
    setOpenUserAdd(true);
  };

  const addGroup = useMemo(() => {
    if (breakpoint) {
      return [
        {
          id: 1,
          label: "Dodaj uż.",
          icon: <Plus className={"h-4 w-4"} />,
          func: () => triggerPopupAddUser(),
        },
      ];
    }
    return [
      {
        id: 1,
        label: "Dodaj uż.",
        icon: <Plus className={"h-4 w-4"} />,
        func: () => triggerPopupAddUser(),
      },
      {
        id: 2,
        label: "Dodaj uży.",
        icon: <ListPlus className={"h-4 w-4"} />,
        func: () => navigate("/manage/users/massImport"),
      },
    ];
  }, [breakpoint]);
  const columns: ColumnDef<UsersI>[] = useMemo(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && "indeterminate")
            }
            onCheckedChange={(value) =>
              table.toggleAllPageRowsSelected(!!value)
            }
            aria-label="Select all"
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label={"Select row"}
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        meta: "Stanowisko",
        id: "lvl",
        accessorKey: "lvl",

        cell: ({ getValue }) => {
          const lvl = getValue();
          if (lvl === 0) return <Badge variant={"muted"}>Uczeń</Badge>;
          if (lvl === 1) return <Badge variant={"brand"}>Nauczyciel</Badge>;
          if (lvl === 2) return <Badge variant={"accent"}>Administrator</Badge>;
        },
        header: ({ column }) => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              icon={<ArrowUpDown />}
              iconPosition={"right"}
              className={"w-fit"}
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              Stanowisko
            </Button>
          );
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        meta: "Imię",
        id: "name",
        accessorKey: "name",
        header: ({ column }) => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              icon={<ArrowUpDown />}
              iconPosition={"right"}
              className={"w-fit"}
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              Imię
            </Button>
          );
        },
      },
      {
        meta: "Nazwisko",
        id: "surname",
        accessorKey: "surname",
        header: ({ column }) => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              icon={<ArrowUpDown />}
              iconPosition={"right"}
              className={"w-fit"}
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              Nazwisko
            </Button>
          );
        },
      },
      {
        meta: "Email",
        id: "email",
        accessorKey: "email",
        header: ({ column }) => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              icon={<ArrowUpDown />}
              iconPosition={"right"}
              className={"w-fit"}
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              Email
            </Button>
          );
        },
      },
      {
        meta: "Nr.tel",
        id: "phone",
        accessorKey: "phone",
        header: () => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              className={"w-fit"}
            >
              Nr.tel
            </Button>
          );
        },
      },
      {
        meta: "Data ur.",
        id: "birthdate",
        accessorKey: "birthdate",
        sortType: "datetime",
        cell: ({ getValue }) => {
          const date = getValue();
          if (typeof date === "string" && isValid(parseISO(date))) {
            return format(new Date(date), "dd.MM.yyyy");
          }
          return date;
        },
        header: ({ column }) => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              icon={<ArrowUpDown />}
              iconPosition={"right"}
              className={"w-fit"}
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              Data ur.
            </Button>
          );
        },
      },
      {
        meta: "Utworzono",
        id: "createdAt",
        accessorKey: "createdAt",
        sortType: "datetime",
        cell: ({ getValue }) => {
          const date = getValue();
          if (typeof date === "string" && isValid(parseISO(date))) {
            return format(new Date(date), "dd.MM.yyyy");
          }
          return date;
        },
        header: ({ column }) => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              icon={<ArrowUpDown />}
              iconPosition={"right"}
              className={"w-fit"}
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              Utworzono
            </Button>
          );
        },
      },
      {
        meta: "Aktywność",
        id: "active",
        accessorKey: "active",
        cell: ({ getValue }) => {
          const active = getValue();
          if (active === true) {
            return (
              <Badge hideDot variant={"brand"}>
                Aktywny
              </Badge>
            );
          }
          if (active === false) {
            return (
              <Badge hideDot variant={"destructive"}>
                Nieaktywny
              </Badge>
            );
          }
          return "";
        },
        header: ({ column }) => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              icon={<ArrowUpDown />}
              iconPosition={"right"}
              className={"w-fit"}
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              Aktywność
            </Button>
          );
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        id: "actions",
        enableHiding: false,
        enableSorting: false,
        cell: ({ row }) => {
          const rowOrigin = row.original;
          return (
            <div className={"flex w-full justify-end"}>
              <Button
                size={"sm"}
                variant={"ghost"}
                variantColor={"destructive"}
                iconPosition={"only"}
                icon={<Trash2 />}
                onClick={() => getSelectedRowsFn([rowOrigin])}
              />
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    size={"sm"}
                    variant={"ghost"}
                    variantColor={"muted"}
                    iconPosition={"only"}
                    icon={<Edit />}
                  />
                </DropdownMenuTrigger>
                <DropdownMenuContent align={"end"}>
                  <DropdownMenuGroup>
                    <DropdownMenuItem
                      onClick={() => triggerPopupEdit(rowOrigin)}
                    >
                      Edytuj
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                  <DropdownMenuSeparator />
                  <DropdownMenuGroup>
                    <DropdownMenuItem
                      className={"text-fg-destructive"}
                      onClick={() => triggerPopupChangeActive(rowOrigin)}
                    >
                      {rowOrigin.active ? "Dezaktywuj" : "Aktywuj"}
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className={"text-fg-destructive"}
                      onClick={() => triggerPopupResetPassword(rowOrigin)}
                    >
                      Resetuj hasło
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <Dialog open={openUserAdd} onOpenChange={setOpenUserAdd}>
        <CreateUser onOpenChange={setOpenUserAdd} />
      </Dialog>
      <Dialog open={openEdit} onOpenChange={setOpenEdit}>
        <EditUser
          user={selectedUser}
          open={openEdit}
          onOpenChange={setOpenEdit}
          callback={() => {
            setSelectedUser(undefined);
          }}
        />
      </Dialog>
      <AlertDialog open={openDelete} onOpenChange={setOpenDelete}>
        <DeleteUser
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          open={openDelete}
          onOpenChange={setOpenDelete}
        />
      </AlertDialog>
      <AlertDialog open={openChangeActive} onOpenChange={setOpenChangeActive}>
        <ActivateUser user={selectedUser} onOpenChange={setOpenChangeActive} />
      </AlertDialog>
      <AlertDialog open={openResetPassword} onOpenChange={setOpenResetPassword}>
        <ResetPassword
          user={selectedUser}
          onOpenChange={setOpenResetPassword}
        />
      </AlertDialog>

      <TanstackTable
        name={"Użytkownicy"}
        isLoading={isPending}
        columns={columns}
        defaultColumnVisibility={{
          phone: false,
          birthdate: false,
          active: false,
          createdAt: false,
        }}
        data={data}
        getSelectedRows={getSelectedRowsFn}
        filterBy={[
          {
            id: "lvl",
            title: "Stanowisko",
            options: [
              {
                value: 0,
                label: "Uczeń",
              },
              {
                value: 1,
                label: "Nauczyciel",
              },
              {
                value: 2,
                label: "Administrator",
              },
            ],
          },
          {
            id: "active",
            title: "Aktywność",
            options: [
              {
                label: "Aktywny",
                value: true,
              },
              {
                label: "Nieaktywny",
                value: false,
              },
            ],
          },
        ]}
        addGroup={addGroup}
      />
    </>
  );
}
