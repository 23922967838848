import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { TableCell, TableRow } from "@/components/ui/table/Table";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { Table as TableT } from "@tanstack/table-core/build/lib/types";
import { Fragment } from "react";

export default function TanstackTableLoading<TData>({
  table,
}: {
  table: TableT<TData>;
}) {
  const breakpoint = useMediaQueryHook("md");
  if (breakpoint) {
    return Array.from({ length: 3 }, (_, index) => (
      <div
        key={index}
        className={
          "flex flex-col w-full rounded-xl border-border border-1 bg-bg-container py-4"
        }
      >
        {table.getHeaderGroups().map((headerGroup) =>
          headerGroup.headers.map((header, index, headers) => {
            const isLast = index === headers.length - 1;
            return (
              <div
                key={header.id}
                className={cn(
                  "flex justify-between items-center min-h-7 gap-3 px-4",
                  isLast ? "border-t border-border px-4 pt-4 mt-4" : null,
                )}
              >
                {isLast ? (
                  <Skeleton className={"ml-auto w-10 h-10"} />
                ) : (
                  <Fragment>
                    <Skeleton className={"h-4 shrink-0 w-14"} />
                    <Skeleton className={"h-4 w-full"} />
                  </Fragment>
                )}
              </div>
            );
          }),
        )}
      </div>
    ));
  }
  return Array.from({ length: 20 }, (_, index) => (
    <TableRow key={index}>
      {table.getHeaderGroups().map((headerGroup) =>
        headerGroup.headers.map((header, index, headers) => {
          const isLast = index === headers.length - 1;
          return (
            <TableCell key={header.id} className={"px-3 py-1"}>
              <Skeleton
                className={cn(
                  "rounded-sm w-full z-0",
                  isLast ? "ml-auto h-10 w-10" : "h-4",
                )}
              />
            </TableCell>
          );
        }),
      )}
    </TableRow>
  ));
}
