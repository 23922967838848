import { FileIcon as File, FolderOpen } from "lucide-react";

export const FileIcon = () => {
  return (
    <span className={"grid place-items-center shrink-0 rounded-sm h-10 w-10"}>
      <File className={"w-4 h-4 stroke-fg-muted"} />
    </span>
  );
};
export const DirIcon = () => {
  return (
    <span className={"grid place-items-center shrink-0 rounded-sm h-10 w-10"}>
      <FolderOpen className={"w-4 h-4 stroke-fg-muted"} />
    </span>
  );
};
