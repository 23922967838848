import { httpErrorHandler } from "@/api/api";
import { useDeleteMeetingMutation as useDeleteMeeting } from "@/api/queries/meetingsQuery";
import { Badge } from "@/components/ui/badge/Badge";
import {
  AlertDialogAction,
  AlertDialogBody,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/dialog/AlertDialog";
import { useToast } from "@/components/ui/toast/useToast";
import { MeetingsI } from "@/types/meetings";
import { format } from "date-fns";
import { Dot, Trash2 } from "lucide-react";
import { forwardRef } from "react";

type CalendarDeleteMeetingPropsT = {
  data: MeetingsI;
  queryParams: { before: string; after: string };
};

const DeleteMeeting = forwardRef<HTMLDivElement, CalendarDeleteMeetingPropsT>(
  ({ data, queryParams }, ref) => {
    const { toast } = useToast();
    const { id, startDate, endDate, name, type } = data;

    const { mutateAsync } = useDeleteMeeting(queryParams);
    const onSubmit = () => {
      mutateAsync(id).catch((error) => {
        const { title, detail } = httpErrorHandler(error, {
          title: "Usunięcie nie powiodło się",
        });
        toast({
          title: title,
          description: detail,
          variant: "destructive",
        });
      });
    };

    return (
      <AlertDialogContent ref={ref} className={"max-w-[45ch]"}>
        <AlertDialogHeader>
          <AlertDialogTitle>Czy chcesz usunąć to spotkanie?</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogBody>
          <div className={"flex min-h-9 items-center"}>
            <span className={"text-fg-muted min-w-14 "}>Nazwa:</span>
            <p className={"text-fg-primary"}>{name}</p>
          </div>
          <div className={"flex min-h-9 items-center"}>
            <span className={"text-fg-muted min-w-14"}>Data:</span>
            <p className={"text-fg-primary flex items-center"}>
              {format(new Date(startDate), "dd MMM yyyy")}
              <Dot />
              {format(new Date(startDate), "H:mm")}-
              {format(new Date(endDate), "H:mm")}
            </p>
          </div>
          <div className={"flex min-h-9 items-center"}>
            <span className={"text-fg-muted min-w-14 "}>Typ:</span>
            <div className={"text-fg-primary"}>
              <Badge variant={"accent"}>
                {type === "remote" ? "Zdalnie" : "Stacionarnie"}
              </Badge>
            </div>
          </div>
        </AlertDialogBody>
        <AlertDialogFooter>
          <AlertDialogCancel variant={"ghost"} variantColor={"muted"}>
            Anuluj
          </AlertDialogCancel>
          <AlertDialogAction
            variant={"flat"}
            variantColor={"destructive"}
            onClick={onSubmit}
            iconPosition={"left"}
          >
            <Trash2 className={"mr-2"} />
            Potwierdź
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    );
  },
);
DeleteMeeting.displayName = "CalendarDeleteMeeting";

export default DeleteMeeting;
