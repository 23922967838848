import { useGetDirectoryQuery } from "@/api/queries/directoryQueries";
import DirectoryCard from "@/components/features/files/DirectoryCard";
import FileCard, {
  FIleCardSkeleton,
} from "@/components/features/files/FileCard";
import GroupElementHeader from "@/components/features/groups/layout/GroupElementHeader";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { cn } from "@/lib/utils";
import getFileUrl from "@/utils/getFileUrl";
import { DownloadIcon, FolderOpen } from "lucide-react";
import { Fragment, forwardRef } from "react";
import { useNavigate } from "react-router-dom";

type GroupFilesProps = {
  className?: string;
  directoryId?: string;
};

const GroupFiles = forwardRef<HTMLDivElement, GroupFilesProps>(
  ({ className, directoryId }: GroupFilesProps, ref) => {
    const navigate = useNavigate();
    const { isPending, isSuccess, data } = useGetDirectoryQuery(
      directoryId as string,
      !!directoryId,
    );

    const handleNavigate = () => {
      if (directoryId) {
        navigate(`/fs/dir/${directoryId}`, { state: directoryId });
      }
    };

    return (
      <div
        ref={ref}
        className={cn(
          "flex flex-col rounded-lg border-border border-1 bg-bg-container overflow-auto",
          className,
        )}
      >
        <GroupElementHeader label={"Pliki"} callback={handleNavigate} />
        <div
          className={
            "flex flex-col gap-2 px-4 pb-4 overflow-y-auto overscroll-contain"
          }
        >
          {isSuccess &&
            data.childDirs.length === 0 &&
            data.files.length === 0 && (
              <p className={"py-4 text-fg-muted text-sm text-center"}>
                Brak plików
              </p>
            )}
          {isSuccess &&
            data.files.map((file) => (
              <DropdownMenu key={file.id}>
                <FileCard file={file} menuButton />
                <DropdownMenuContent>
                  <DropdownMenuGroup>
                    <DropdownMenuItem
                      onClick={() => {
                        const url = getFileUrl(file.id);
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", file.name);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                      }}
                    >
                      <DownloadIcon className={"w-4 h-4"} />
                      Pobierz
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            ))}
          {isSuccess &&
            data.childDirs.map((directory) => (
              <DropdownMenu key={directory.id}>
                <DirectoryCard directory={directory} menuButton />
                <DropdownMenuContent>
                  <DropdownMenuGroup>
                    <DropdownMenuItem
                      onClick={() => navigate(`/fs/dir/${directory.id}`)}
                    >
                      <FolderOpen className={"w-4 h-4"} />
                      Otwórz
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            ))}
          {isPending && (
            <Fragment>
              <FIleCardSkeleton />
              <FIleCardSkeleton />
              <FIleCardSkeleton />
            </Fragment>
          )}
        </div>
      </div>
    );
  },
);

GroupFiles.displayName = "GroupFiles";
export default GroupFiles;
