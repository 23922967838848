import { Button } from "@/components/ui/button/Button";
import { MeetingI } from "@/types/meetings";
import { Check, Copy, Video } from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function MeetingRemoteLink({ meeting }: { meeting: MeetingI }) {
  const [copiedPassword, setCopiedPassword] = useState<boolean>(false);
  const copyContent = async () => {
    await navigator.clipboard.writeText(meeting.meetingURL);
    setCopiedPassword(true);
    setTimeout(() => {
      setCopiedPassword(false);
    }, 1000);
  };

  return (
    <div>
      <div className={"flex justify-between w-full"}>
        <Link to={meeting.meetingURL}>
          <Button
            disabled={!meeting.meetingURL}
            icon={<Video />}
            iconPosition={"left"}
          >
            {meeting.meetingURL
              ? "Dołącz do spotkania"
              : "Brak linku do spotkania"}
          </Button>
        </Link>
        <Button
          onClick={copyContent}
          variant={"ghost"}
          variantColor={"muted"}
          disabled={!meeting.meetingURL}
          icon={copiedPassword ? <Check /> : <Copy />}
          iconPosition={"only"}
        />
      </div>
      <p className={"text-xs text-fg-muted truncate mt-3 empty:hidden"}>
        {meeting.meetingURL}
      </p>
    </div>
  );
}
