import { GetMeetingsPropsT } from "@/api/endpoints/meetings";
import { useGetAssignedMeetingsInfiniteQuery } from "@/api/queries/meetingsQuery";
import CalendarCard from "@/components/features/calendar/CalendarCard";
import { startOfDay } from "date-fns";
import { useState } from "react";

export default function HomeCalendarCard() {
  const [queryParams, setQueryParams] = useState<GetMeetingsPropsT>({
    after: startOfDay(new Date()).toISOString(),
    sortOrder: "ASC",
    page: 0,
    pageSize: 15,
  });

  const query = useGetAssignedMeetingsInfiniteQuery(queryParams);

  return (
    <CalendarCard
      query={query}
      setQueryParams={setQueryParams}
      className={
        "sticky top-0 max-w-[23rem]  w-[23rem] h-[calc(100dvh-var(--space-12))]"
      }
    />
  );
}
