import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs/Tabs";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { VIEW_KEY } from "@/page/pages/calendar/CalendarPage";
import { setNavigateT } from "@/types/calendar";

interface CalendarToggleProps {
  viewKey: VIEW_KEY;
  setNavigate: setNavigateT;
}

export default function CalendarToggle({
  viewKey,
  setNavigate,
}: CalendarToggleProps) {
  const isMobile = useMediaQueryHook("sm");
  const names = {
    [VIEW_KEY.DAY]: isMobile ? "Dzień" : "D",
    [VIEW_KEY.WEEK]: isMobile ? "Tydzień" : "T",
    [VIEW_KEY.MONTH]: isMobile ? "Miesiąc" : "M",
  };

  const handleChange = (value: VIEW_KEY) => {
    setNavigate({ newViewKey: value });
  };

  return (
    <Tabs
      value={viewKey}
      className={cn(isMobile ? "w-full" : "w-fit")}
      onValueChange={(value) => handleChange(value as VIEW_KEY)}
    >
      <TabsList className="grid w-full grid-cols-3">
        <TabsTrigger value={VIEW_KEY.DAY}>{names[VIEW_KEY.DAY]}</TabsTrigger>
        <TabsTrigger value={VIEW_KEY.WEEK}>{names[VIEW_KEY.WEEK]}</TabsTrigger>
        <TabsTrigger value={VIEW_KEY.MONTH}>
          {names[VIEW_KEY.MONTH]}
        </TabsTrigger>
      </TabsList>
    </Tabs>
  );
}
