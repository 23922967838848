import { NotificationsSheet } from "@/components/features/notifications/NotificationsSheet";
import { Button } from "@/components/ui/button/Button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible/Collapsible";
import {
  NavigationAvatarImage,
  NavigationContext,
  NavigationItem,
  NavigationProps,
} from "@/components/ui/navigation/Navigation";
import { Separator } from "@/components/ui/separator/Separator";
import {
  Sheet,
  SheetBody,
  SheetContent,
  SheetFooter,
  SheetHeader,
} from "@/components/ui/sheet/Sheet";
import {
  Bell,
  CalendarDays,
  CandlestickChart,
  ClipboardList,
  FolderClosed,
  Home,
  Library,
  LogOut,
  Map,
  Menu,
  MessageCircle,
  Newspaper,
  Settings,
  Settings2,
  User,
  Users,
  X,
} from "lucide-react";

function Navbar({
  open,
  onOpenChange,
  logout,
  isLoading,
  userData: { name, surname, userRole, lvl, avatarURL },
}: NavigationProps) {
  const closeHandler = () => {
    onOpenChange(false);
  };
  const openHandler = () => {
    onOpenChange(true);
  };

  const NotificationContent = () => {
    return (
      <Sheet open={open} onOpenChange={onOpenChange}>
        <SheetContent side={"left"} hideClose={true}>
          <NavigationContext.Provider value={{ open }}>
            <SheetHeader className={"pb-5 sm:pb-5"}>
              <Button
                variant={"ghost"}
                variantColor={"muted"}
                onClick={closeHandler}
                iconPosition={"only"}
                icon={<X />}
              />
            </SheetHeader>
            <SheetBody className={"flex flex-col gap-5"}>
              <div className={"flex gap-3"}>
                <NavigationAvatarImage avatarURL={avatarURL} />
                <div className={"flex flex-col gap-0.5"}>
                  <h5 className={"font-semibold text-fg-primary"}>
                    {name + " " + surname}
                  </h5>
                  <small className={"text-sm text-fg-muted"}>{userRole}</small>
                </div>
              </div>
              <div className={"flex flex-col gap-1 pb-4"}>
                <NavigationItem
                  icon={<Home />}
                  text={"Strona Główna"}
                  to={"/"}
                  onClick={closeHandler}
                />
                <Separator />

                <NavigationItem
                  icon={<MessageCircle />}
                  text={"Chat"}
                  to={"/chat"}
                  onClick={closeHandler}
                />
                <NavigationItem
                  icon={<CalendarDays />}
                  text={"Kalendarz"}
                  to={`/calendar/`}
                  onClick={closeHandler}
                />
                <NavigationItem
                  icon={<Users />}
                  text={"Grupy"}
                  to={"/groups"}
                  onClick={closeHandler}
                />
                <NavigationItem
                  icon={<CandlestickChart />}
                  text={"Oceny"}
                  to={"/grades"}
                  onClick={closeHandler}
                />
                <NavigationItem
                  icon={<ClipboardList />}
                  text={"Zadania"}
                  to={"/assigments"}
                  onClick={closeHandler}
                />

                {lvl > 0 && (
                  <Collapsible>
                    <CollapsibleTrigger asChild>
                      <NavigationItem
                        icon={<Settings2 />}
                        type={"list"}
                        text={"Zarządzaj"}
                        isNavigation={false}
                      />
                    </CollapsibleTrigger>
                    <CollapsibleContent className={"ml-4 flex flex-row gap-1"}>
                      <div
                        className={"flex-shrink-0 w-px h-auto bg-border-layout"}
                      />
                      <div>
                        <NavigationItem
                          icon={<User />}
                          text={"Użytkownicy"}
                          to={"/manage/users"}
                          onClick={closeHandler}
                        />
                        <NavigationItem
                          icon={<Users />}
                          text={"Grupy"}
                          to={"/manage/groups"}
                          onClick={closeHandler}
                        />
                        <NavigationItem
                          icon={<Newspaper />}
                          text={"Posty"}
                          to={"/manage/posts"}
                          onClick={closeHandler}
                        />
                        <NavigationItem
                          icon={<Library />}
                          text={"Lekcje"}
                          to={"/manage/lessons"}
                          onClick={closeHandler}
                        />
                        {lvl == 2 && (
                          <NavigationItem
                            icon={<Map />}
                            text={"Lokacje"}
                            to={"/manage/locations"}
                            onClick={closeHandler}
                          />
                        )}
                      </div>
                    </CollapsibleContent>
                  </Collapsible>
                )}
                <NavigationItem
                  icon={<FolderClosed />}
                  text={"Pliki"}
                  to={"/fs"}
                  onClick={closeHandler}
                />
              </div>
            </SheetBody>
            <SheetFooter
              className={
                "flex gap-1 flex-col justify-center flex-shrink-0  pt-0"
              }
            >
              <Separator />
              <NavigationItem
                icon={<Settings />}
                text={"Ustawienia"}
                to={"/settings"}
                onClick={closeHandler}
              />
              <Button
                role={"button"}
                variant={"ghost"}
                variantColor={"muted"}
                onClick={logout}
                iconPosition={"left"}
                icon={<LogOut />}
                className={"w-full justify-start"}
                isLoading={{
                  state: isLoading,
                  message: "Wylogowywanie",
                }}
              >
                Wyloguj
              </Button>
            </SheetFooter>
          </NavigationContext.Provider>
        </SheetContent>
      </Sheet>
    );
  };

  return (
    <>
      <nav
        data-open={open}
        className={
          "sticky top-0 z-40 flex items-center justify-between p-4 w-full border-b-1 border-border bg-bg-container rounded-b-sm"
        }
      >
        <Button
          variant={"ghost"}
          variantColor={"muted"}
          onClick={openHandler}
          iconPosition={"only"}
          icon={<Menu />}
        />

        <div className={"flex flex-row gap-1"}>
          <NotificationsSheet>
            <Button
              variant={"ghost"}
              variantColor={"muted"}
              iconPosition={"only"}
              icon={<Bell />}
            />
          </NotificationsSheet>
          <NavigationAvatarImage avatarURL={avatarURL} />
        </div>
      </nav>

      <NotificationContent />
    </>
  );
}

Navbar.displayName = "NavbarMobile";

export { Navbar };
