import { cn } from '@/lib/utils';
import React from 'react';

interface SettingOptionHeaderProps {
    title: string, 
    description: string, 
    className?: string
}


export default function SettingOptionHeader({title, description, className}: SettingOptionHeaderProps) {
    return (
        <div className={cn("flex flex-col gap-2", className)}>
            <h5 className={"w-full text-md font-medium text-fg-secondary whitespace-nowrap overflow-hidden text-ellipsis"}>
                {title}
            </h5>
            <p className={"text-sm text-fg-muted whitespace-normal overflow-hidden text-ellipsis"}>
                {description}
            </p>
        </div>
    )
}