import { useGetPostsQuery } from "@/api/queries/postsQueries";
import ActivatePost from "@/components/features/manage/manage-posts/ActivatePost";
import CreatePost from "@/components/features/manage/manage-posts/CreatePost";
import DeletePost from "@/components/features/manage/manage-posts/DeletePost";
import EditPost from "@/components/features/manage/manage-posts/EditPost";
import { TanstackTable } from "@/components/features/manage/tanstack-table/TanstackTable";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import { AlertDialog } from "@/components/ui/dialog/AlertDialog";
import { Dialog } from "@/components/ui/dialog/Dialog";
import { Checkbox } from "@/components/ui/input/checkbox/Checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { PostCreatorI, PostsI } from "@/types/posts";
import { selectedRowsI } from "@/types/table";
import { ColumnDef } from "@tanstack/react-table";
import { format, isValid, parseISO } from "date-fns";
import { ArrowUpDown, Edit, Plus, Trash2 } from "lucide-react";
import { useMemo, useState } from "react";

export default function ManagePosts() {
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openChangeActive, setOpenChangeActive] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openPostAdd, setOpenPostAdd] = useState<boolean>(false);

  const [selectedRows, setSelectedRows] = useState<selectedRowsI<PostsI>[]>([]);
  const [selectedPost, setSelectedPost] = useState<PostsI | undefined>(
    undefined,
  );

  const { data = [], isPending } = useGetPostsQuery();
  const getSelectedRowsFn = (rows: PostsI[]) => {
    setSelectedRows(
      rows.map((row) => {
        return { data: row, status: "isIdle" };
      }),
    );
    setOpenDelete(true);
  };

  const triggerPopupEdit = (post: PostsI) => {
    if (post) {
      setSelectedPost(post);
      setOpenEdit(true);
    }
  };
  const triggerPopupChangeActive = (post: PostsI) => {
    if (post) {
      setSelectedPost(post);
      setOpenChangeActive(true);
    }
  };

  const triggerPopupAddPost = () => {
    setOpenPostAdd(true);
  };

  const addGroup = useMemo(() => {
    return [
      {
        id: 1,
        label: "Dodaj post",
        icon: <Plus className={"w-4 h-4"} />,
        func: () => triggerPopupAddPost(),
      },
    ];
  }, []);

  const columns: ColumnDef<PostsI>[] = useMemo(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && "indeterminate")
            }
            onCheckedChange={(value) =>
              table.toggleAllPageRowsSelected(!!value)
            }
            aria-label="Select all"
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label={"Select row"}
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        meta: "Tytuł",
        id: "name",
        accessorKey: "name",
        header: ({ column }) => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              icon={<ArrowUpDown />}
              iconPosition={"right"}
              className={"w-fit"}
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              Tytuł
            </Button>
          );
        },
      },
      {
        meta: "Twórca",
        id: "creator",
        accessorKey: "creator",
        cell: ({ getValue }) => {
          const creator = getValue() as PostCreatorI;
          if (creator) {
            return creator.name + " " + creator.surname;
          }
          return "";
        },
        header: ({ column }) => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              icon={<ArrowUpDown />}
              iconPosition={"right"}
              className={"w-fit"}
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              Twórca
            </Button>
          );
        },
      },
      {
        meta: "Aktywność",
        id: "active",
        accessorKey: "active",
        cell: ({ getValue }) => {
          const active = getValue();
          if (active === true) {
            return (
              <Badge hideDot variant={"brand"}>
                Aktywny
              </Badge>
            );
          }
          if (active === false) {
            return (
              <Badge hideDot variant={"destructive"}>
                Nieaktywny
              </Badge>
            );
          }
          return "";
        },
        header: ({ column }) => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              icon={<ArrowUpDown />}
              iconPosition={"right"}
              className={"w-fit"}
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              Aktywność
            </Button>
          );
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        meta: "Ostatnie zmiany",
        id: "updatedAt",
        accessorKey: "updatedAt",
        sortType: "datetime",
        cell: ({ getValue }) => {
          const date = getValue();
          if (typeof date === "string" && isValid(parseISO(date))) {
            return format(new Date(date), "dd.MM.yyyy");
          }
          return date;
        },
        header: ({ column }) => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              icon={<ArrowUpDown />}
              iconPosition={"right"}
              className={"w-fit"}
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              Ostatnie zmiany
            </Button>
          );
        },
      },
      {
        meta: "Data utwotrzenia",
        id: "createdAt",
        accessorKey: "createdAt",
        sortType: "datetime",
        cell: ({ getValue }) => {
          const date = getValue();
          if (typeof date === "string" && isValid(parseISO(date))) {
            return format(new Date(date), "dd.MM.yyyy");
          }
          return date;
        },
        header: ({ column }) => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              icon={<ArrowUpDown />}
              iconPosition={"right"}
              className={"w-fit"}
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              Data utworzenia
            </Button>
          );
        },
      },
      {
        id: "actions",
        enableHiding: false,
        enableSorting: false,
        cell: ({ row }) => {
          const rowOrigin = row.original;
          return (
            <div className={"flex justify-end"}>
              <Button
                size={"sm"}
                variant={"ghost"}
                variantColor={"destructive"}
                iconPosition={"only"}
                icon={<Trash2 />}
                onClick={() => getSelectedRowsFn([rowOrigin])}
              />
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    size={"sm"}
                    variant={"ghost"}
                    variantColor={"muted"}
                    iconPosition={"only"}
                    icon={<Edit />}
                  />
                </DropdownMenuTrigger>
                <DropdownMenuContent align={"end"}>
                  <DropdownMenuGroup>
                    <DropdownMenuItem
                      onClick={() => triggerPopupEdit(rowOrigin)}
                    >
                      Edytuj
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                  <DropdownMenuSeparator />
                  <DropdownMenuGroup>
                    <DropdownMenuItem
                      className={"text-fg-destructive"}
                      onClick={() => triggerPopupChangeActive(rowOrigin)}
                    >
                      {rowOrigin.active ? "Dezaktywuj" : "Aktywuj"}
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <Dialog open={openPostAdd} onOpenChange={setOpenPostAdd}>
        <CreatePost onOpenChange={setOpenPostAdd} />
      </Dialog>
      <Dialog open={openEdit} onOpenChange={setOpenEdit}>
        <EditPost
          post={selectedPost}
          open={openEdit}
          onClose={() => {
            setOpenEdit(false);
            setSelectedPost(undefined);
          }}
        />
      </Dialog>
      <AlertDialog open={openDelete} onOpenChange={setOpenDelete}>
        <DeletePost
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          open={openDelete}
          onOpenChange={setOpenDelete}
        />
      </AlertDialog>
      <AlertDialog open={openChangeActive} onOpenChange={setOpenChangeActive}>
        <ActivatePost post={selectedPost} onOpenChange={setOpenChangeActive} />
      </AlertDialog>
      <TanstackTable
        name={"Posty"}
        isLoading={isPending}
        columns={columns}
        defaultColumnVisibility={{
          updatedAt: false,
          createdAt: false,
        }}
        data={data}
        getSelectedRows={getSelectedRowsFn}
        filterBy={[
          {
            id: "active",
            title: "Aktywność",
            options: [
              {
                label: "Aktywny",
                value: true,
              },
              {
                label: "Nieaktywny",
                value: false,
              },
            ],
          },
        ]}
        addGroup={addGroup}
      />
    </>
  );
}
