import { formatBytes } from "@/lib/utils";
import { FilesI } from "@/types/files";
import { UseMutationResult } from "@tanstack/react-query";
import { isCancel } from "axios";
import { Ban, Check, RefreshCw, X } from "lucide-react";
import { HTMLAttributes, useCallback, useMemo } from "react";

export type useFSEntryProps = {
  variant?: "file" | "directory";
  abort?: () => void;
  retry?: () => void;
  status?: UseMutationResult["status"];
  error?: Error;
  file?: FilesI | File;
  progress?: number;
} & HTMLAttributes<HTMLDivElement>;

export const useFSEntry = (props: useFSEntryProps) => {
  const { variant, retry, abort, status, file, progress, error } = props;

  const isFile = useMemo(() => variant === "file", [variant]);

  const isFileInstance = useMemo(() => file instanceof File, [file]);

  const handleRetryUpload = useCallback(() => {
    if (retry) retry();
  }, [retry]);

  const handleAbortUpload = useCallback(() => {
    if (abort) abort();
  }, [abort]);

  const statusProps = useMemo(() => {
    const styles = "text-xs ";
    switch (status) {
      case "idle": {
        if (isFile) {
          return {
            children: "Odrzucono plik",
            className: styles + "text-fg-muted",
            icon: <RefreshCw />,
            callback: handleRetryUpload,
          };
        } else {
          return {
            children: "Odrzucono folder",
            className: styles + "text-fg-muted",
          };
        }
      }
      case "pending": {
        if (isFile) {
          return {
            children: isFileInstance
              ? `${formatBytes((file as File).size)} | ${progress}%`
              : progress
                ? `${progress}%`
                : null,
            className: styles + "text-fg-muted",
            icon: <X />,
            callback: handleAbortUpload,
          };
        } else {
          return {
            children: progress ? `${progress}%` : null,
            className: styles + "text-fg-muted",
            icon: <X />,
            callback: handleAbortUpload,
          };
        }
      }
      case "success": {
        if (isFile) {
          return {
            children: "Plik został przesłany",

            className: styles + "text-fg-muted",
            icon: <Check className={"w-4 h-4 stroke-fg-success"} />,
          };
        } else {
          return {
            children: "Folder został przesłany",
            className: styles + "text-fg-muted",
            icon: <Check className={"w-4 h-4 stroke-fg-success"} />,
          };
        }
      }
      case "error": {
        if (isFile) {
          if (isCancel(error)) {
            return {
              children: "Odrzucono plik",
              className: styles + "text-fg-muted",
              icon: <RefreshCw />,
              callback: handleRetryUpload,
            };
          } else {
            return {
              children: "Wystąpił błąd",
              className: styles + "text-fg-destructive",
            };
          }
        } else {
          if (isCancel(error)) {
            return {
              children: "Odrzucono Folder",
              className: styles + "text-fg-muted",
            };
          } else {
            return {
              children: "Wystąpił błąd",
              className: styles + "text-fg-destructive",
            };
          }
        }
      }
      default:
        return {
          className: styles + "text-fg-muted",
        };
    }
  }, [file, isFileInstance, progress, status, error]);

  return {
    isFileInstance,
    statusProps,
  };
};
