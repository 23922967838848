import AudioPlayer from "@/components/ui/media-viewer/AudioPlayer";
import { FilesI } from "@/types/files";
import getFileUrl from "@/utils/getFileUrl";
import { AudioMimeType } from "@vidstack/react/types/vidstack";
import { useMemo } from "react";

type MediaAudioViewerProps = {
  file: FilesI | File;
};

export default function MediaAudioViewer({ file }: MediaAudioViewerProps) {
  const isFilesInstance = "id" in file;

  const { fileURL, type } = useMemo(() => {
    if (!file || isFilesInstance) {
      return { fileURL: getFileUrl(file.id), type: file.fileType };
    }
    return { fileURL: URL.createObjectURL(file), type: file.type };
  }, [file]);

  return (
    <AudioPlayer
      src={{
        src: fileURL,
        type: type as AudioMimeType,
      }}
      title={file?.name ? file?.name : "audio file"}
    />
  );
}
