import {
  addWeeks,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isThisWeek,
  isThisYear,
  isToday,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import pl from "date-fns/locale/pl";

/**
 * Formats a given date into a string representation based on its relation to the current date.
 * - If the date is today, it returns the time in "HH:mm" format.
 * - If the date is within the current week, it returns the day of the week (e.g., "Mon").
 * - If the date is within the current year, it returns the date and month (e.g., "12 Mar").
 * - Otherwise, it returns the full date including the year (e.g., "12 Mar 2023").
 *
 * @param {Date} date - The date to format.
 * @returns {string} The formatted date string.
 */
function formatDate(date: Date) {
  if (isToday(date)) {
    return format(date, "HH:mm", { locale: pl });
  } else if (isThisWeek(date, { weekStartsOn: 1 })) {
    return format(date, "EEE", { locale: pl }); // Mon-Sun
  } else if (isThisYear(date)) {
    return format(date, "dd MMM", { locale: pl }); // Date and month
  } else {
    return format(date, "dd MMM yyyy", { locale: pl }); // Date, month, and year
  }
}

/**
 * Function to get all the days of a given month.
 *
 * @param {Date} date - A date within the month for which the days are to be returned.
 *
 * @param {Object} options - An optional object with a boolean property 'fixedWeeks'.
 * @param {boolean} options.fixedWeeks - A boolean indicating whether to return fixed weeks or not.
 *
 * @returns {Date[]} - An array of Date objects representing each day of the month.
 */
function getMonth(date: Date, options?: { fixedWeeks?: boolean }): Date[] {
  const start = startOfMonth(date);
  const end = endOfMonth(date);

  const weekStart = startOfWeek(start, { weekStartsOn: 1 });
  let weekEnd = addWeeks(weekStart, 5);
  weekEnd = endOfWeek(weekEnd, { weekStartsOn: 1 });
  if (options?.fixedWeeks)
    return eachDayOfInterval({ start: weekStart, end: weekEnd });

  return eachDayOfInterval({ start, end });
}

/**
 * Returns an array of dates representing each day of the week for the given date.
 *
 * @param {Date} date - The date within the week for which the array of dates is to be returned.
 * @returns {Date[]} An array of Date objects representing each day of the week, starting from Monday.
 *
 * @example
 * // Returns [2022-01-03T00:00:00.000Z, 2022-01-04T00:00:00.000Z, ..., 2022-01-09T00:00:00.000Z]
 * getCurrentWeek(new Date('2022-01-05'))
 */

function getWeek(date: Date): Date[] {
  const start = startOfWeek(date, { weekStartsOn: 1 }); // week starts on Monday
  const end = endOfWeek(date, { weekStartsOn: 1 }); // week starts on Monday

  return eachDayOfInterval({ start, end });
}

export { formatDate, getWeek, getMonth };
