import { cn } from "@/lib/utils";
import { getWeek } from "@/utils/date-format";
import { format, isSameDay, isToday } from "date-fns";
import pl from "date-fns/locale/pl";

type WeekRowProps = {
  date?: Date;
  onClick?: (day: Date) => void;
  className?: string;
};

export default function WeekCards({
  date = new Date(),
  onClick,
  className,
}: WeekRowProps) {
  const week = getWeek(date);

  const handleOnClick = (day: Date) => {
    if (onClick) {
      onClick(day);
    }
  };

  return week.map((day) => {
    const weekDay = format(day, "ccc", { locale: pl });
    const monthDate = format(day, "d", { locale: pl });
    const today = isToday(day);
    const sameDay = isSameDay(day, date);
    return (
      <button
        key={day.toString()}
        className={cn(
          "flex flex-col gap-1 min-w-8 w-11 h-13 items-center py-1 rounded-md cursor-pointer ease-out duration-100 transition-all opacity-100 hover:opacity-90 focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-ring disabled:opacity-50",
          sameDay ? "bg-bg-brand" : "bg-bg-container",
          className,
        )}
        onClick={() => handleOnClick(day)}
      >
        <small
          className={cn(
            "text-xs",
            sameDay ? "text-fg-brand-on" : "text-fg-muted",
          )}
        >
          {weekDay}
        </small>
        <span
          className={cn(
            "text-md",
            sameDay ? "text-fg-brand-on" : "text-fg-secondary",
          )}
        >
          {monthDate}
        </span>
        {today && (
          <span
            className={cn(
              "w-1 min-w-1 h-1 min-h-1 rounded-full ",
              sameDay ? "bg-fg-brand-on" : "bg-fg-muted",
            )}
          />
        )}
      </button>
    );
  });
}
