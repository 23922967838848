import { Button } from "@/components/ui/button/Button";
import { cn } from "@/lib/utils";
import { FileUp, ImagePlus, Video } from "lucide-react";
import { ChangeEvent, ReactNode, useRef } from "react";

type FileInputProps = {
  className?: string;
  onAddFiles: (e: ChangeEvent<HTMLInputElement>) => void;
  children: ReactNode;
};

export default function FileInput({
  onAddFiles,
  children,
  className,
}: FileInputProps) {
  const attachmentsInputRef = useRef<HTMLInputElement>(null);

  return (
    <fieldset
      className={cn(
        "relative flex flex-col gap-3 p-3 border-border border-1 rounded-md overflow-hidden",
        className,
      )}
    >
      <input
        ref={attachmentsInputRef}
        onChange={onAddFiles}
        multiple
        className={"hidden"}
        type={"file"}
      />
      <div className={"flex gap-2 justify-between"}>
        <div className={"flex w-full gap-2 justify-end"}>
          <Button
            type={"button"}
            size={"sm"}
            variant={"flat"}
            variantColor={"muted"}
            icon={<ImagePlus />}
            iconPosition={"only"}
            onClick={() => {
              if (attachmentsInputRef.current) {
                attachmentsInputRef.current.accept =
                  "image/jpeg, image/png, image/gif, image/svg+xml, image/webp";
                attachmentsInputRef.current.click();
              }
            }}
          />
          <Button
            type={"button"}
            size={"sm"}
            variant={"flat"}
            variantColor={"muted"}
            icon={<Video />}
            iconPosition={"only"}
            onClick={() => {
              if (attachmentsInputRef.current) {
                attachmentsInputRef.current.accept =
                  "video/mp4, video/webm, video/3gp, video/ogg, video/avi, video/mpeg audio/mpeg, audio/ogg, audio/3gp, audio/mp4, audio/webm, audio/flac, audio/object";
                attachmentsInputRef.current.click();
              }
            }}
          />
          <Button
            type={"button"}
            size={"sm"}
            variant={"flat"}
            variantColor={"muted"}
            icon={<FileUp />}
            iconPosition={"only"}
            onClick={() => {
              if (attachmentsInputRef.current) {
                attachmentsInputRef.current.accept = "*";
                attachmentsInputRef.current.click();
              }
            }}
          />
        </div>
      </div>
      <div
        className={
          "grid gap-2 grid-cols-[repeat(auto-fit,minmax(200px,1fr))] grid-rows-[auto] empty:hidden"
        }
      >
        {children}
      </div>
    </fieldset>
  );
}
