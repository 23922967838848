import { AudioLayout } from "@/components/ui/media-viewer/AudioLayout";
import {
  MediaPlayer,
  MediaPlayerInstance,
  MediaPlayerProps,
  MediaProvider,
} from "@vidstack/react";
import "@vidstack/react/player/styles/base.css";
import { PlayerSrc } from "@vidstack/react/types/vidstack";
import { useRef } from "react";

type AudioPlayerProps = {
  src?: PlayerSrc;
  title: string;
  variant?: "default" | "minimal";
} & Omit<MediaPlayerProps, "children">;

export default function AudioPlayer({
  title,
  src,
  variant = "default",
  ...props
}: AudioPlayerProps) {
  const player = useRef<MediaPlayerInstance>(null);

  return (
    <MediaPlayer
      className={
        "relative overflow-hidden w-full bg-bg-container text-fg-muted rounded-sm ring-media-focus data-[focus]:ring-1 ring-ring"
      }
      title={title}
      src={src}
      ref={player}
      playsInline
      streamType={"on-demand"}
      storage={"audio-media-storage"}
      viewType={"audio"}
      {...props}
    >
      <MediaProvider />
      <AudioLayout variant={variant} />
    </MediaPlayer>
  );
}
