import HomeCreatePost from "@/components/features/home/HomeCreatePost";
import HomeCalendarCard from "@/components/features/home/layout/HomeCalendarCard";
import HomeHeader from "@/components/features/home/layout/HomeHeader";
import PostList from "@/components/features/post/PostList";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { useCredentials } from "@/store/authStore";

export default function HomePage() {
  const isLaptop = useMediaQueryHook("lg");
  const { lvl } = useCredentials();
  return (
    <div className={"flex flex-1 gap-6 sm:gap-4 flex-row"}>
      <section className={"flex flex-1 flex-col gap-4"}>
        <HomeHeader />
        <div className={"flex flex-1 flex-col gap-5 items-center w-full"}>
          {lvl > 1 ? <HomeCreatePost /> : null}
          <PostList />
        </div>
      </section>
      {!isLaptop && <HomeCalendarCard />}
    </div>
  );
}
