import CalendarContentDay from "@/components/features/calendar/layout/content/CalendarContentDay";
import CalendarContentMonth from "@/components/features/calendar/layout/content/CalendarContentMonth";
import CalendarContentWeek from "@/components/features/calendar/layout/content/CalendarContentWeek";
import CalendarHeader from "@/components/features/calendar/layout/header/CalendarHeader.js";
import CalendarHeaderDay from "@/components/features/calendar/layout/header/CalendarHeaderDay";
import CalendarHeaderMonth from "@/components/features/calendar/layout/header/CalendarHeaderMonth";
import CalendarHeaderWeek from "@/components/features/calendar/layout/header/CalendarHeaderWeek";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import useSwipe from "@/hooks/useSwipe";
import { navigationI } from "@/types/calendar";
import { timeToHeight } from "@/utils/timeToHeight";
import { addDays, addMonths, addWeeks, format, isValid, parse } from "date-fns";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";

export enum VIEW_KEY {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}

export default function CalendarPage() {
  const isMobile = useMediaQueryHook("sm");

  const containerHeight = useRef<number>(2304);
  const containerRef = useRef<HTMLDivElement>(null);

  const swipe = useSwipe<HTMLDivElement>(containerRef, 75);

  const handleDateParser = (dateString: string | null): Date => {
    if (!dateString) return new Date();
    const dateObject = parse(dateString, "dd-MM-yyyy", new Date());
    if (isValid(dateObject)) {
      return dateObject;
    } else {
      return new Date();
    }
  };

  const handleViewKeyParser = (viewKey: string | null): VIEW_KEY => {
    if (!viewKey || !Object.values(VIEW_KEY).includes(viewKey as VIEW_KEY)) {
      return VIEW_KEY.DAY;
    } else {
      return viewKey as VIEW_KEY;
    }
  };

  const [searchParams, setSearchParams] = useSearchParams({
    viewKey: VIEW_KEY.DAY,
    date: format(new Date(), "dd-MM-yyyy"),
  });

  const date = useMemo(
    () => handleDateParser(searchParams.get("date")),
    [searchParams],
  );

  const viewKey = useMemo(
    () => handleViewKeyParser(searchParams.get("viewKey")),
    [searchParams],
  );

  const handleNavigate = useCallback(
    ({ newViewKey, newDate }: navigationI): void => {
      let dateString = searchParams.get("date") ? searchParams.get("date") : "";
      let viewKey = searchParams.get("viewKey")
        ? searchParams.get("viewKey")
        : VIEW_KEY.DAY;

      if (newDate && isValid(newDate)) {
        dateString = format(newDate, "dd-MM-yyyy");
      }
      if (newViewKey) {
        viewKey = newViewKey;
      }

      setSearchParams(`viewKey=${viewKey}&date=${dateString}`, {
        replace: true,
      });
    },
    [searchParams, setSearchParams],
  );

  const handleChangeDate = useCallback(
    (number: number): void => {
      if (!isValid(date)) return;
      switch (viewKey) {
        case VIEW_KEY.DAY:
          handleNavigate({ newDate: addDays(date, number) });
          break;
        case VIEW_KEY.WEEK:
          handleNavigate({ newDate: addWeeks(date, number) });
          break;
        case VIEW_KEY.MONTH:
          handleNavigate({ newDate: addMonths(date, number) });
          break;
      }
    },
    [date, viewKey, handleNavigate],
  );

  useEffect(() => {
    const offset = timeToHeight(new Date(), containerHeight.current);
    if (containerRef.current) {
      containerRef.current.scrollTo(0, offset - 50);
    }
  }, []);

  useEffect(() => {
    if (!swipe.isSwiping && swipe.direction === "right") {
      handleChangeDate(-1);
    }
    if (!swipe.isSwiping && swipe.direction === "left") {
      handleChangeDate(1);
    }
  }, [swipe.isSwiping, swipe.direction]);

  let modeHeader;
  let content;

  switch (viewKey) {
    case VIEW_KEY.DAY:
      modeHeader = <CalendarHeaderDay date={date} />;
      content = (
        <CalendarContentDay
          date={date}
          containerHeight={containerHeight.current}
        />
      );
      break;
    case VIEW_KEY.WEEK:
      modeHeader = (
        <CalendarHeaderWeek date={date} setNavigate={handleNavigate} />
      );
      content = isMobile ? (
        <CalendarContentDay
          date={date}
          containerHeight={containerHeight.current}
        />
      ) : (
        <CalendarContentWeek
          date={date}
          containerHeight={containerHeight.current}
        />
      );
      break;
    case VIEW_KEY.MONTH:
      modeHeader = <CalendarHeaderMonth date={date} />;
      content = (
        <CalendarContentMonth date={date} setNavigate={handleNavigate} />
      );
      break;
  }

  return (
    <div
      className={
        "relative overflow-hidden flex flex-col h-full bg-bg-container rounded-xl border border-border"
      }
    >
      <CalendarHeader
        date={date}
        viewKey={viewKey}
        setNavigate={handleNavigate}
        changeDate={handleChangeDate}
      />

      <div className={"relative flex flex-col flex-1 overflow-hidden"}>
        <div className={"p-4"}>{modeHeader}</div>
        <div
          ref={containerRef}
          className={"px-4 pb-4 flex grow overflow-y-auto"}
        >
          {content}
        </div>
      </div>
    </div>
  );
}
