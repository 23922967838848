import { defaultCredentials, useAuthStore } from "@/store/authStore";
import { useStateStore } from "@/store/stateStore";
import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";
import { isAxiosError } from "axios";

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: async (error) => {
      console.log("ERROR: ", error);
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          const currentUrl = window.location.pathname;
          useStateStore.setState({ pathURL: currentUrl });

          useAuthStore.persist.clearStorage();
          useAuthStore.setState({ credentials: defaultCredentials });
        }
      }
    },
  }),

  mutationCache: new MutationCache({
    onError: async (error) => {
      console.log("ERROR: ", error);
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          const currentUrl = window.location.pathname;
          useStateStore.setState({ pathURL: currentUrl });

          useAuthStore.persist.clearStorage();
          useAuthStore.setState({ credentials: defaultCredentials });
        }
      }
    },
  }),

  defaultOptions: {
    queries: {
      retry: import.meta.env.VITE_QUERY_RETRY,
      throwOnError: false,
      refetchOnWindowFocus: true,
    },
    mutations: {
      retry: import.meta.env.VITE_QUERY_RETRY,
      throwOnError: false,
    },
  },
});
