import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar/Avatar";
import { Button } from "@/components/ui/button/Button";
import { Identifiable, useUlElementContext } from "@/components/ui/list/List";
import { Separator } from "@/components/ui/separator/Separator";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { cn } from "@/lib/utils";
import { getAvatar } from "@/utils/getAvarar";
import { Plus, UserX, Users } from "lucide-react";
import { forwardRef } from "react";

type UlElementProps<T extends Identifiable> = {
  entity: T;
  data: {
    title?: string;
    detail?: string;
    avatarURL?: string;
  };
  className?: string;
};

const LiElement = <T extends Identifiable>({
  entity,
  data: { title, detail, avatarURL },
  className,
}: UlElementProps<T>) => {
  const { removeElement, addElement, isSelected } = useUlElementContext();

  const handleAddElement = () => {
    addElement(entity);
  };
  const handleRemoveElement = () => {
    removeElement(entity.id);
  };

  const state = isSelected(entity.id);
  return (
    <li
      className={cn(
        "flex justify-between h-fit items-center gap-3 py-2 rounded-sm group overflow-hidden",
        className,
      )}
    >
      <div className={"flex gap-3 items-center"}>
        {avatarURL !== undefined && (
          <Avatar size={"sm"}>
            <AvatarImage src={getAvatar(avatarURL)} alt="avatar" />
            <AvatarFallback>
              <Users className={"w-4 h-4"} />
            </AvatarFallback>
          </Avatar>
        )}
        <div className={"flex flex-col gap-1"}>
          {title && (
            <h5 className={"w-full truncate text-md text-fg-primary"}>
              {title}
            </h5>
          )}
          {detail && (
            <small className={"text-xs text-fg-muted truncate"}>{detail}</small>
          )}
        </div>
      </div>
      {state ? (
        <Button
          size={"sm"}
          variant={"ghost"}
          variantColor={"destructive"}
          onClick={handleRemoveElement}
          icon={<UserX />}
          iconPosition={"only"}
        />
      ) : (
        <Button
          size={"sm"}
          variant={"ghost"}
          variantColor={"brand"}
          onClick={handleAddElement}
          icon={<Plus />}
          iconPosition={"only"}
        />
      )}
    </li>
  );
};

type LiElementLSkeletonProps = {
  className?: string;
  hasAvatar?: boolean;
  hasTitle?: boolean;
  hasDetail?: boolean;
};

const LiElementLSkeleton = forwardRef<HTMLDivElement, LiElementLSkeletonProps>(
  (
    {
      className,
      hasAvatar = true,
      hasTitle = true,
      hasDetail = true,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={cn(
          "flex h-fit items-center gap-3 py-2 rounded-sm",
          className,
        )}
        {...props}
      >
        {hasAvatar && <Skeleton className={"w-10 h-10 rounded-sm"} />}
        <div className={"flex flex-col gap-1"}>
          {hasTitle && <Skeleton className={"rounded-sm w-[7rem] h-3"} />}
          {hasDetail && <Skeleton className={"rounded-sm w-[4rem] h-3"} />}
        </div>
      </div>
    );
  },
);

LiElementLSkeleton.displayName = "LiElementLSkeleton";

const LiElementSeparator = ({
  message = "Wyszukiwane",
}: {
  message?: string;
}) => {
  return (
    <li className="my-2">
      <p className="text-xs text-fg-muted">{message}</p>
      <Separator className="mt-0.5" />
    </li>
  );
};

export { LiElement, LiElementLSkeleton, LiElementSeparator };
