import ChatTimeToPresent from "@/components/features/chat/ChatTimeToPresent";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar/Avatar";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { useAuthStore } from "@/store/authStore";
import { ConversationI } from "@/types/chat";
import { User, Users } from "lucide-react";
import { forwardRef, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

export const ConversationsListSkeletonItem = forwardRef<HTMLDivElement>(
  (props = {}, ref) => {
    return (
      <div
        ref={ref}
        className={"inline-flex gap-2 p-2 items-center rounded-md"}
        {...props}
      >
        <Skeleton className={"w-10 min-w-10 h-10 min-h-10  rounded-sm"} />
        <div className={"flex flex-col gap-1 w-full"}>
          <Skeleton className={"w-[20ch] h-3"} />
          <Skeleton className={"w-[14ch]  h-3"} />
        </div>
      </div>
    );
  },
);

ConversationsListSkeletonItem.displayName = "ConversationsListSkeletonItem";

export function ConversationsListItem({
  id,
  name,
  type,
  messages,
}: ConversationI) {
  const { id: userId } = useAuthStore.useCredentials();

  const getObjectKeyByValue = useCallback(
    (
      obj: Record<string, any>,
      targetKey: number | undefined,
    ): any | undefined => {
      const entry = Object.entries(obj).find(
        ([key]) => String(key) === String(targetKey),
      );

      return entry ? entry[1] : undefined;
    },
    [],
  );
  const {
    content = "",
    sender: { name: senderName = "", surname: senderSurname = "" } = {},
    createdAt = "",
  } = useMemo(() => messages[0] || {}, [messages]);

  const chatName = useMemo((): string => {
    if (type === "individual") {
      return getObjectKeyByValue(JSON.parse(name), userId);
    }
    return name;
  }, [userId, name, type]);

  return (
    <Link
      to={`/chat/${String(id)}`}
      state={{ id: id, type: type, name: chatName }}
      className={
        "inline-flex w-full  gap-2 p-2 items-center rounded-md ease-out duration-100 transition-all opacity-100 hover:bg-bg-muted-subtle/45 focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-ring disabled:opacity-50"
      }
    >
      <Avatar size={"sm"}>
        <AvatarImage src={""} alt={"avatar"} />
        <AvatarFallback>
          {type === "group" ? (
            <Users className={"w-4 h-4"} />
          ) : (
            <User className={"w-4 h-4"} />
          )}
        </AvatarFallback>
      </Avatar>
      <div className={"flex flex-col ga-0.5 w-full"}>
        <h5 className={"text-sm font-medium text-fg-primary min-h-4 truncate"}>
          {chatName}
        </h5>
        <p className={"text-xs text-fg-secondary truncate min-h-4"}>
          {content
            ? senderName + " " + senderSurname?.at(0) + ": " + content
            : " "}
        </p>
      </div>
      <div className={"shrink-0"}>
        <ChatTimeToPresent
          createdAt={createdAt}
          className={"text-xs text-fg-secondary"}
        />
      </div>
    </Link>
  );
}
