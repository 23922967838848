import { useInfiniteQueryResult } from "@/api/api-utils";
import { useGetAssignedPostsInfiniteQuery } from "@/api/queries/postsQueries";
import { Post, PostSkeleton } from "@/components/features/post/Post";
import { InfiniteScroll } from "@/components/ui/infinite-scroll/InfiniteScroll";
import { PostsI } from "@/types/posts";
import { useMutationState } from "@tanstack/react-query";
import { Fragment, memo } from "react";

export default function PostList() {
  const { data, isSuccess, isFetching, isLoading, hasNextPage, fetchNextPage } =
    useGetAssignedPostsInfiniteQuery();

  const { data: posts, isEmpty: postsIsEmpty } = useInfiniteQueryResult(data);

  const next = async () => {
    await fetchNextPage();
  };

  const variables = useMutationState<PostsI>({
    filters: { mutationKey: ["postPost"], status: "pending" },
    select: (mutation) => mutation.state.variables as PostsI,
  });

  return (
    <div className={"flex flex-col gap-4 w-full"}>
      {variables &&
        variables.map((variable) => <PostSkeleton key={variable.name} />)}
      {posts.map((post) => (
        <Post key={post.id} post={post} />
      ))}
      {isSuccess && postsIsEmpty && <EmptyPostsList />}
      {isLoading && (
        <Fragment>
          <PostSkeleton />
          <PostSkeleton />
          <PostSkeleton />
        </Fragment>
      )}
      <InfiniteScroll
        hasNextPage={hasNextPage}
        isLoading={isLoading}
        isFetching={isFetching}
        next={next}
        threshold={0.25}
        root={null}
      >
        {hasNextPage && <PostSkeleton />}
      </InfiniteScroll>
    </div>
  );
}

const EmptyPostsList = memo(() => {
  return (
    <div
      className={
        "flex flex-col h-full items-center justify-center flex-1 gap-1 py-10 px-4 rounded-md border"
      }
    >
      <p className={"text-fg-secondary"}>Brak postów</p>
      <p className={"text-fg-muted text-xs text-center"}>
        Żaden post nie został <br /> dotychczas utworzony na twojej stronie
      </p>
    </div>
  );
});

EmptyPostsList.displayName = "EmptyPostsList";
