const phoneRegex =
  /^(?:[\+][0-9]{2}[-\s\.]?)?[0-9]{3}[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3}$/;

const linkURLRegex =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

const timeRegex = /^([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;

const HEXColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

const coordinatesRegex =
  /^((\-?|\+?)?\d+(\.\d+)?),\s*((\-?|\+?)?\d+(\.\d+)?)$/gi;

export { phoneRegex, linkURLRegex, timeRegex, HEXColorRegex, coordinatesRegex };
