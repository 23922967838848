import { cn } from "@/lib/utils";
import * as TogglePrimitive from "@radix-ui/react-toggle";
import { VariantProps, cva } from "class-variance-authority";
import * as React from "react";

const toggleVariants = cva(
  "relative inline-flex items-center justify-center rounded-sm text-sm font-medium truncate shrink-0 ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-transparent",
        outline:
          "border-1 border-border bg-transparent hover:bg-bg-muted-subtle/50 text-fg-muted hover:text-fg-secondary",
      },
      variantColor: {
        brand: "",
        accent: "",
        muted: "",
      },
      size: {
        sm: "h-9 min-w-9 gap-1 px-2",
        md: "h-10 min-w-10 gap-2 px-2",
        lg: "h-11 min-w-11 gap-3 px-3",
      },
    },
    compoundVariants: [
      {
        variant: "outline",
        variantColor: "muted",
        className:
          "border-border hover:bg-bg-muted-subtle/50 text-fg-muted hover:text-fg-secondary data-[state=on]:bg-bg-muted-subtle data-[state=on]:text-fg-primary",
      },
      {
        variant: "outline",
        variantColor: "brand",
        className:
          "border-fg-brand-subtle hover:bg-bg-brand-subtle/50 text-fg-brand data-[state=on]:bg-bg-brand-subtle data-[state=on]:text-fg-brand",
      },
      {
        variant: "outline",
        variantColor: "accent",
        className:
          "border-fg-accent-subtle hover:bg-bg-accent-subtle/50 text-fg-accent data-[state=on]:bg-bg-accent-subtle data-[state=on]:text-fg-accent",
      },
      {
        variant: "default",
        variantColor: "muted",
        className:
          "bg-bg-muted-subtle/50 text-fg-muted hover:text-fg-secondary data-[state=on]:bg-bg-muted-subtle data-[state=on]:text-fg-primary",
      },
      {
        variant: "default",
        variantColor: "brand",
        className:
          "bg-bg-brand-subtle/50 text-fg-brand data-[state=on]:bg-bg-brand data-[state=on]:text-fg-brand-on",
      },
      {
        variant: "default",
        variantColor: "accent",
        className:
          "bg-bg-accent-subtle/50 text-fg-accent data-[state=on]:bg-bg-accent data-[state=on]:text-fg-accent-on",
      },
    ],
    defaultVariants: {
      variant: "outline",
      variantColor: "muted",
      size: "md",
    },
  },
);

const Toggle = React.forwardRef<
  React.ElementRef<typeof TogglePrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> &
    VariantProps<typeof toggleVariants>
>(({ className, variant, variantColor, size, ...props }, ref) => (
  <TogglePrimitive.Root
    ref={ref}
    className={cn(toggleVariants({ variant, variantColor, size, className }))}
    {...props}
  />
));

Toggle.displayName = TogglePrimitive.Root.displayName;

export { Toggle, toggleVariants };
