import Ripple from "@/components/ui/ripple/Ripple";
import { useRipple } from "@/components/ui/ripple/useRipple";
import { Spinner } from "@/components/ui/spinner/Spinner";
import { cn } from "@/lib/utils";
import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import React, { useMemo } from "react";

const colorVariants = {
  solid: {
    brand: "bg-bg-brand text-fg-brand-on [&>*]:stroke-fg-brand-on",
    accent: "bg-bg-accent text-fg-accent-on [&>*]:stroke-fg-accent-on",
    muted: "bg-bg-muted text-fg-primary [&>*]:stroke-fg-primary",
    success: "bg-bg-success text-fg-success-on [&>*]:stroke-fg-success-on",
    warning: "bg-bg-warning text-fg-warning-on [&>*]:stroke-fg-warning-on",
    destructive:
      "bg-bg-destructive text-fg-destructive-on [&>*]:stroke-fg-destructive-on",
  },
  flat: {
    brand: "bg-bg-brand-subtle text-fg-brand [&>*]:stroke-fg-brand",
    accent: "bg-bg-accent-subtle text-fg-accent [&>*]:stroke-fg-accent",
    muted: "bg-bg-muted-subtle text-fg-primary [&>*]:stroke-fg-primary",
    success: "bg-bg-success-subtle text-fg-success [&>*]:stroke-fg-success",
    warning: "bg-bg-warning-subtle text-fg-warning [&>*]:stroke-fg-warning",
    destructive:
      "bg-bg-destructive-subtle text-fg-destructive [&>*]:stroke-fg-destructive",
  },
  outline: {
    brand:
      "bg-transparent text-fg-brand border-1 border-fg-brand [&>*]:stroke-fg-brand",
    accent:
      "bg-transparent text-fg-accent border-1 border-fg-accent [&>*]:stroke-fg-accent",
    muted:
      "bg-transparent text-fg-primary border-1 border-border [&>*]:stroke-fg-primary",
    success:
      "bg-transparent text-fg-success border-1 border-fg-success [&>*]:stroke-fg-success",
    warning:
      "bg-transparent text-fg-warning border-1 border-fg-warning [&>*]:stroke-fg-warning",
    destructive:
      "bg-transparent text-fg-destructive border-1 border-fg-destructive [&>*]:stroke-fg-destructive",
  },
  ghost: {
    brand: "bg-transparent text-fg-brand hover:bg-bg-brand-subtle",
    accent: "bg-transparent text-fg-accent hover:bg-bg-accent-subtle",
    muted: "bg-transparent text-fg-primary hover:bg-bg-muted-subtle",
    success: "bg-transparent text-fg-success hover:bg-bg-success-subtle",
    warning: "bg-transparent text-fg-warning hover:bg-bg-warning-subtle",
    destructive:
      "bg-transparent text-fg-destructive hover:bg-bg-destructive-subtle",
  },
};

const buttonVariants = cva(
  "group relative inline-flex whitespace-nowrap overflow-hidden items-center justify-center rounded-md text-md font-medium ease-out duration-100 transition-all opacity-100 hover:opacity-90 focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-ring disabled:opacity-50",
  {
    variants: {
      variant: {
        solid: "",
        flat: "",
        outline: "border-1",
        ghost: "bg-transparent",
      },
      variantColor: {
        brand: "",
        accent: "",
        muted: "",
        success: "",
        warning: "",
        destructive: "",
      },
      size: {
        sm: "h-10 min-h-10 min-w-10 gap-2 text-sm rounded-sm [&>svg]:flex-shrink-0 [&>svg]:w-4 [&>svg]:h-4",
        md: "h-11 min-h-11 min-w-11 gap-2 text rounded-md [&>svg]:flex-shrink-0 [&>svg]:w-5 [&>svg]:h-5",
        lg: "h-12 min-h-12 min-w-12 gap-2 text-lg rounded-md [&>svg]:flex-shrink-0 [&>svg]:w-5 [&>svg]:h-5",
      },
      iconPosition: {
        left: "",
        right: "",
        none: "",
        only: "aspect-square",
      },
    },
    compoundVariants: [
      //paddings/sm
      {
        size: "sm",
        iconPosition: "left",
        className: "pl-4 pr-5",
      },
      {
        size: "sm",
        iconPosition: "right",
        className: "pl-5 pr-4",
      },
      {
        size: "sm",
        iconPosition: "none",
        className: "pl-5 pr-5",
      },
      {
        size: "sm",
        iconPosition: "only",
        className: "p-0",
      },
      //paddings/md
      {
        size: "md",
        iconPosition: "left",
        className: "pl-5 pr-6",
      },
      {
        size: "md",
        iconPosition: "right",
        className: "pl-6 pr-5",
      },
      {
        size: "md",
        iconPosition: "none",
        className: "pl-6 pr-6",
      },
      {
        size: "md",
        iconPosition: "only",
        className: "p-0",
      },
      //paddings/lg
      {
        size: "lg",
        iconPosition: "left",
        className: "pl-6 pr-7",
      },
      {
        size: "lg",
        iconPosition: "right",
        className: "pl-7 pr-6",
      },
      {
        size: "lg",
        iconPosition: "none",
        className: "pl-7 pr-7",
      },
      {
        size: "lg",
        iconPosition: "only",
        className: "p-0",
      },

      // solid / color
      {
        variant: "solid",
        variantColor: "brand",

        className: colorVariants.solid.brand,
      },
      {
        variant: "solid",
        variantColor: "accent",
        className: colorVariants.solid.accent,
      },
      {
        variant: "solid",
        variantColor: "muted",
        className: colorVariants.solid.muted,
      },
      {
        variant: "solid",
        variantColor: "success",
        className: colorVariants.solid.success,
      },
      {
        variant: "solid",
        variantColor: "warning",
        className: colorVariants.solid.warning,
      },
      {
        variant: "solid",
        variantColor: "destructive",
        className: colorVariants.solid.destructive,
      },

      // flat / color
      {
        variant: "flat",
        variantColor: "brand",
        className: colorVariants.flat.brand,
      },
      {
        variant: "flat",
        variantColor: "accent",
        className: colorVariants.flat.accent,
      },
      {
        variant: "flat",
        variantColor: "muted",
        className: colorVariants.flat.muted,
      },
      {
        variant: "flat",
        variantColor: "muted",
        className: colorVariants.flat.muted,
      },
      {
        variant: "flat",
        variantColor: "success",
        className: colorVariants.flat.success,
      },
      {
        variant: "flat",
        variantColor: "warning",
        className: colorVariants.flat.warning,
      },
      {
        variant: "flat",
        variantColor: "destructive",
        className: colorVariants.flat.destructive,
      },
      // outline / color
      {
        variant: "outline",
        variantColor: "brand",
        className: colorVariants.outline.brand,
      },
      {
        variant: "outline",
        variantColor: "accent",
        className: colorVariants.outline.accent,
      },
      {
        variant: "outline",
        variantColor: "muted",
        className: colorVariants.outline.muted,
      },
      {
        variant: "outline",
        variantColor: "success",
        className: colorVariants.outline.success,
      },
      {
        variant: "outline",
        variantColor: "warning",
        className: colorVariants.outline.warning,
      },
      {
        variant: "outline",
        variantColor: "destructive",
        className: colorVariants.outline.destructive,
      },
      // ghost / color
      {
        variant: "ghost",
        variantColor: "brand",
        className: colorVariants.ghost.brand,
      },
      {
        variant: "ghost",
        variantColor: "accent",
        className: colorVariants.ghost.accent,
      },
      {
        variant: "ghost",
        variantColor: "muted",
        className: colorVariants.ghost.muted,
      },
      {
        variant: "ghost",
        variantColor: "success",
        className: colorVariants.ghost.success,
      },
      {
        variant: "ghost",
        variantColor: "warning",
        className: colorVariants.ghost.warning,
      },
      {
        variant: "ghost",
        variantColor: "destructive",
        className: colorVariants.ghost.destructive,
      },
      {
        iconPosition: "only",
        size: "sm",
        className: "h-10 min-h-10 w-10 min-w-10 flex-shrink-0",
      },
      {
        iconPosition: "only",
        size: "md",
        className: "h-11 min-h-11 w-11 min-w-11 flex-shrink-0",
      },
      {
        iconPosition: "only",
        size: "lg",
        className: "h-12 min-h-12 w-12 min-w-12 flex-shrink-0",
      },
    ],
    defaultVariants: {
      variant: "solid",
      variantColor: "brand",
      iconPosition: "none",
      size: "md",
    },
  },
);

export interface ButtonProps extends VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  disabled?: boolean;
  isLoading?: {
    state: boolean;
    message?: string | null;
  };
  icon?: React.ReactNode;
  showSpinner?: boolean;
}

export interface ButtonPropsWithHTMLAttributes
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    ButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Button = React.forwardRef<
  HTMLButtonElement,
  ButtonPropsWithHTMLAttributes
>(
  (
    {
      onClick,
      children,
      className,
      asChild = false,
      disabled = false,
      isLoading = { state: false },
      variant,
      variantColor,
      size,
      iconPosition = "none",
      icon,
      showSpinner = true,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";

    const {
      onClick: onRippleClickHandler,
      onClear: onClearRipple,
      ripples,
    } = useRipple();
    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
      onRippleClickHandler(event);
      if (onClick) {
        onClick(event);
      }
    };

    const spinner = (
      <Spinner
        size={size}
        variant={
          variantColor
            ? variant === "solid"
              ? `on-${variantColor}`
              : variantColor
            : null
        }
      />
    );

    const leftSide = useMemo(() => {
      if (
        isLoading.state &&
        showSpinner &&
        (iconPosition === "left" || iconPosition === "none")
      ) {
        return spinner;
      }
      if (icon && iconPosition === "left") {
        return icon;
      }
      return null;
    }, [iconPosition, icon, isLoading]);

    const rightSide = useMemo(() => {
      if (isLoading.state && showSpinner && iconPosition === "right") {
        return spinner;
      }
      if (icon && iconPosition === "right") {
        return icon;
      }
      return null;
    }, [iconPosition, icon, isLoading]);

    const center = useMemo(() => {
      if (iconPosition === "only" && isLoading.state && showSpinner) {
        return spinner;
      }
      if (isLoading.state && isLoading.message) {
        return isLoading.message;
      }
      if (iconPosition === "only") {
        return icon;
      }
      return children;
    }, [iconPosition, icon, isLoading, children]);

    return (
      <Comp
        ref={ref}
        onClick={handleClick}
        className={cn(
          buttonVariants({
            variant,
            variantColor,
            iconPosition,
            size,
          }),
          className,
        )}
        type={props?.type || "button"}
        disabled={isLoading?.state || disabled}
        {...props}
      >
        {leftSide}
        {center}
        {rightSide}

        <Ripple onClear={onClearRipple} ripples={ripples} />
      </Comp>
    );
  },
);

Button.displayName = "Button";
export { Button, buttonVariants, colorVariants };
