import { queryClient } from "@/api/query-client";
import { ProviderTheme } from "@/context/Theme";
import { router } from "@/routes/routes";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import { I18nProvider } from "react-aria";
import { HelmetProvider } from "react-helmet-async";
import { RouterProvider } from "react-router-dom";

export default function App() {
  return (
    <React.StrictMode>
      <HelmetProvider>
        <ProviderTheme>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools
              initialIsOpen={false}
              position={"top"}
              buttonPosition={"top-right"}
            />
            <I18nProvider locale={"pl-PL"}>
              <RouterProvider router={router} />
            </I18nProvider>
          </QueryClientProvider>
        </ProviderTheme>
      </HelmetProvider>
    </React.StrictMode>
  );
}
