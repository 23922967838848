import {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb/Breadcrumb";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { useCredentials } from "@/store/authStore";
import { DirectoryI, DirectoryPathI } from "@/types/files";
import { Folder, HardDrive } from "lucide-react";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";

type FSPathProps = {
  fsEntry?: DirectoryI;
  isLoading: boolean;
  isError: boolean;
  onRouteChange?: (id: string) => void;
};

export default function FSPath({
  fsEntry,
  isLoading,
  isError,
  onRouteChange,
}: FSPathProps) {
  const breakpointMobile = useMediaQueryHook("sm");
  const { directoryId: defaultDirectoryId } = useCredentials();
  const [open, setOpen] = useState<boolean>(false);

  const paths = useMemo(() => {
    if (!fsEntry) return [];
    return [
      ...fsEntry.path.filter(({ id }) => id !== defaultDirectoryId),
    ].reverse();
  }, [defaultDirectoryId, fsEntry]);
  const currentPath = useMemo(() => {
    return fsEntry
      ? fsEntry.id !== defaultDirectoryId &&
          ({
            name: fsEntry.name,
            id: fsEntry.id,
            parentDirId: fsEntry.parentDirId,
          } as DirectoryPathI)
      : null;
  }, [defaultDirectoryId, fsEntry]);

  return (
    <Breadcrumb className={"[grid-area:breadcrumb] flex flex-nowrap"}>
      <BreadcrumbList>
        {onRouteChange ? (
          <BreadcrumbItem
            className="w-10 h-10 justify-center items-center"
            onClick={() => onRouteChange(defaultDirectoryId)}
          >
            {breakpointMobile ? <HardDrive className={"w-4 h-4"} /> : "Dysk"}
          </BreadcrumbItem>
        ) : (
          <BreadcrumbItem>
            <BreadcrumbLink href={"/fs"}>
              {breakpointMobile ? <HardDrive className={"w-4 h-4"} /> : "Dysk"}
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}
        {paths.length ? (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <DropdownMenu open={open} onOpenChange={setOpen}>
                <DropdownMenuTrigger aria-label={"Toggle menu"}>
                  <BreadcrumbEllipsis
                    className={"justify-center items-center"}
                  />
                </DropdownMenuTrigger>
                <DropdownMenuContent align={"start"}>
                  <DropdownMenuGroup>
                    {paths.map((item, index) => {
                      if (onRouteChange) {
                        return (
                          <DropdownMenuItem
                            key={index}
                            onClick={() => onRouteChange(item.id)}
                          >
                            <Folder className={"w-4 h-4"} />
                            {item.name}
                          </DropdownMenuItem>
                        );
                      } else {
                        return (
                          <DropdownMenuItem key={index}>
                            <Link
                              to={`/fs/dir/${item.id}`}
                              className={
                                "flex gap-2 w-full h-full items-center max-w-[40ch] truncate"
                              }
                            >
                              <Folder className={"w-4 h-4"} />
                              {item.name}
                            </Link>
                          </DropdownMenuItem>
                        );
                      }
                    })}
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            </BreadcrumbItem>
          </>
        ) : null}

        {currentPath ? (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage className={"max-w-[25ch] truncate"}>
                {currentPath.name}
              </BreadcrumbPage>
            </BreadcrumbItem>
          </>
        ) : null}

        {isLoading && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <Skeleton className={"h-10 w-10"} />
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <Skeleton className={"h-10 w-14"} />
            </BreadcrumbItem>
          </>
        )}
        {isError && (
          <BreadcrumbItem>
            <BreadcrumbPage className={"text-fg-destructive text-sm italic"}>
              Wystąpił błąd przy ładowaniu ścieżki pliku
            </BreadcrumbPage>
          </BreadcrumbItem>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  );
}
