import { MeetingI } from "@/types/meetings";
import { UseInfiniteQueryResult, UseQueryResult } from "@tanstack/react-query";
import { ReactNode, createContext, useContext } from "react";

type MeetingListContextT = {
  meetings: MeetingI[];
  isLoading: boolean;
  isFetching: boolean;
  fetchNextPage?: () => void;
  hasNextPage?: boolean;
};

const MeetingListContext = createContext<MeetingListContextT>({
  meetings: [],
  isLoading: false,
  isFetching: false,
});

export const useMeetingListContext = () => {
  return useContext(MeetingListContext);
};

type MeetingsListProps = {
  query: UseInfiniteQueryResult | UseQueryResult;
  meetings: MeetingI[];
  children: ReactNode;
};

export default function MeetingsList({
  children,
  meetings,
  query,
}: MeetingsListProps) {
  return (
    <MeetingListContext.Provider
      value={{
        meetings,
        isLoading: query.isLoading,
        isFetching: query.isFetching,
        fetchNextPage: (query as UseInfiniteQueryResult).fetchNextPage,
        hasNextPage: (query as UseInfiniteQueryResult).hasNextPage,
      }}
    >
      {children}
    </MeetingListContext.Provider>
  );
}
