import { httpErrorHandler } from "@/api/api";
import { useCreateDirectoryMutation } from "@/api/queries/directoryQueries";
import {
  useFileUploadMutation,
  useFilesUploadMutation,
} from "@/api/queries/filesQueries";
import DirectoryCard from "@/components/features/files/DirectoryCard";
import FileCard from "@/components/features/files/FileCard";
import { Button } from "@/components/ui/button/Button";
import { Drawer, DrawerContent } from "@/components/ui/drawer/Drawer";
import { useToast } from "@/components/ui/toast/useToast";
import { cn } from "@/lib/utils";
import { DialogTitle } from "@radix-ui/react-dialog";
import { UseMutationResult } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { ChevronDown, X } from "lucide-react";
import {
  ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";

type FileAddFormProps = {
  openCreateFile: boolean;
  openCreateFolder: boolean;
  onOpenCreateFileChange: (open: boolean) => void;
  onOpenCreateFolderChange: (open: boolean) => void;
  parentDirId: string;
};

export default function CreateFS({
  parentDirId,
  openCreateFile,
  openCreateFolder,
  onOpenCreateFileChange,
  onOpenCreateFolderChange,
}: FileAddFormProps) {
  const { toast } = useToast();
  const inputRef = useRef<HTMLInputElement>(null);
  const folderRef = useRef<HTMLInputElement>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openCollapsible, setOpenCollapsible] = useState<boolean>(false);

  const [folderVariable, setFolderVariable] = useState<boolean>(false);
  const [folderName, setFolderName] = useState<string>("");

  const {
    executeMutations: executeDirFilesMutations,
    statuses: dirFilesStatuses,
    clearStatuses: clearDirFilesStatuses,
    abortAll: abortAllDirFiles,
  } = useFilesUploadMutation();

  const {
    executeMutations: executeFilesMutations,
    statuses: filesStatuses,
    clearStatuses: clearFilesStatuses,
  } = useFilesUploadMutation();

  const { mutateAsync: createFolder } = useCreateDirectoryMutation();

  const handleCollapsibleToggle = () => {
    setOpenCollapsible((prev) => !prev);
  };

  const onInputFileUpload = useCallback(() => {
    if (inputRef.current) inputRef.current.click();
    onOpenCreateFileChange(false);
  }, [inputRef]);

  const onInputFolderUpload = useCallback(() => {
    if (folderRef.current) folderRef.current.click();
    onOpenCreateFolderChange(false);
  }, [folderRef]);

  async function writeFolders(
    fileList: FileList,
  ): Promise<Map<string, string>> {
    const dirNamesMap = new Map<string, string>();
    for (const file of fileList) {
      const path = file.webkitRelativePath.split("/").slice(0, -1);
      for (const dir of path) {
        dirNamesMap.set(dir, dir);
      }
    }
    return new Promise(async (resolve, reject) => {
      let parentId = parentDirId;
      const dirNamesCopy = new Map(dirNamesMap);

      clearDirFilesStatuses();
      setFolderVariable(true);
      for (const dirName of Array.from(dirNamesMap.keys())) {
        await createFolder({
          name: dirName,
          parentDirId: parentId,
        })
          .then((res) => {
            parentId = res.id;
            dirNamesCopy.set(dirName, res.id);
          })
          .catch((error) => {
            setFolderVariable(false);
            reject(error);
          });
      }
      setFolderVariable(false);
      resolve(dirNamesCopy);
    });
  }

  async function writeFiles(dirsMap: Map<string, string>, files: FileList) {
    const filesData: { directoryId: string; file: File; tempUUID: string }[] =
      [];

    for (const file of files) {
      const pathArr = file.webkitRelativePath.split("/");
      const path = pathArr.slice(pathArr.length - 2, -1);
      const dirId = dirsMap.get(path[0]) as string;
      filesData.push({ directoryId: dirId, file: file, tempUUID: uuidv4() });
    }
    executeDirFilesMutations(filesData);
  }

  const onFilesAdd = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;

    setOpenDrawer(true);
    setOpenCollapsible(true);

    const filesData: { directoryId: string; file: File; tempUUID: string }[] =
      [];

    for (const file of files) {
      filesData.push({
        directoryId: parentDirId,
        file: file,
        tempUUID: uuidv4(),
      });
    }

    executeFilesMutations(filesData);
  };

  const onFolderAdd = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (!files) return;

    setFolderName(files[0].webkitRelativePath.split("/")[0]);

    setOpenDrawer(true);
    setOpenCollapsible(true);

    writeFolders(files)
      .then((response) => {
        writeFiles(response, files);
      })
      .catch((error) => {
        const { title, detail } = httpErrorHandler(error);
        toast({
          title: title,
          description: detail,
          variant: "destructive",
        });
      });
  };

  const handleClose = () => {
    clearDirFilesStatuses();
    clearFilesStatuses();
    setFolderName("");
    setOpenDrawer(false);
  };

  useEffect(() => {
    if (openCreateFile) {
      onInputFileUpload();
    }
    if (openCreateFolder) {
      onInputFolderUpload();
    }
  }, [openCreateFile, openCreateFolder]);

  const { dirProgress, dirName, dirStatus } = useMemo(() => {
    if (dirFilesStatuses.length === 0) {
      return {
        dirProgress: 0,
        dirName: "",
        dirStatus: "idle" as UseMutationResult["status"],
      };
    }
    const statusesLength = dirFilesStatuses.length;
    const statusesProgress = dirFilesStatuses.reduce((acc, status) => {
      return acc + (status?.progress ? status?.progress : 0);
    }, 0);
    const successStatuses = dirFilesStatuses.filter(
      (entity) => entity.status === "success",
    ).length;
    const dirName = `${folderName}  ${successStatuses}/${statusesLength}`;

    const dirStatus = dirFilesStatuses.some(
      (entity) => entity.status === "pending",
    )
      ? "pending"
      : "success";

    return {
      dirName: dirName,
      dirProgress: Math.ceil(statusesProgress / statusesLength),
      dirStatus: dirStatus as UseMutationResult["status"],
    };
  }, [dirFilesStatuses]);

  return (
    <Fragment>
      <input
        ref={inputRef}
        onChange={onFilesAdd}
        type={"file"}
        multiple
        className={"hidden"}
      />
      <input
        ref={folderRef}
        onChange={onFolderAdd}
        type={"file"}
        webkitdirectory={"true"}
        directory={"true"}
        className={"hidden"}
      />
      <Drawer open={openDrawer} onOpenChange={setOpenDrawer} modal={false}>
        <DrawerContent
          aria-describedby={"file-upload-list"}
          className={
            "z-20 w-[23rem] max-h-[365px] left-auto right-6 sm:right-0 sm:left-0 sm:w-full"
          }
          overlayClassName={"hidden"}
          hideDragger
          onInteractOutside={(event) => event.preventDefault()}
          onPointerDownOutside={(event) => event.preventDefault()}
        >
          <header className={"flex p-2 w-full justify-end"}>
            <DialogTitle className="sr-only">File upload list</DialogTitle>
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              iconPosition={"only"}
              icon={
                <ChevronDown
                  className={cn(!openCollapsible && "rotate-[180deg] ")}
                />
              }
              onClick={handleCollapsibleToggle}
            />
            <Button
              onClick={handleClose}
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              iconPosition={"only"}
              icon={<X />}
            />
          </header>
          <div className="px-4">
            {folderVariable ? (
              <p className="text-fg-muted text-sm text-center">
                Trwa przygotowywanie folderu...
              </p>
            ) : null}
          </div>
          <motion.div
            className={"overflow-auto h-full"}
            initial={{ opacity: 0, height: "auto" }}
            animate={{
              opacity: openCollapsible ? 1 : 0,
              height: openCollapsible ? "auto" : 0,
            }}
            transition={{
              ease: "easeInOut",
              stiffness: 260,
              damping: 20,
              duration: 0.2,
            }}
          >
            <div className={"flex flex-col gap-2 p-4 h-full"}>
              {dirFilesStatuses.length > 0 ? (
                <DirectoryCard
                  directory={{ name: dirName }}
                  status={dirStatus}
                  progress={dirProgress}
                  abort={abortAllDirFiles}
                  variant={"upload"}
                />
              ) : null}
              {filesStatuses.map((entity) => {
                return (
                  <FileCard
                    key={entity.params.tempUUID}
                    file={entity.params.file}
                    progress={entity.progress}
                    abort={() => entity.abort?.()}
                    retry={() => entity.retry?.()}
                    status={entity.status}
                    error={entity?.error}
                    variant={"upload"}
                  />
                );
              })}
            </div>
          </motion.div>
        </DrawerContent>
      </Drawer>
    </Fragment>
  );
}
