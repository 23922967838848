import UnexpectedError from "@/assets/images/errors/UnexpectedError.svg?react";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import { Terminal } from "lucide-react";
import React from "react";
import { FallbackProps } from "react-error-boundary";

export default function ErrorBoundaryFallback({
  error,
  resetErrorBoundary,
}: FallbackProps) {
  const linkRef = React.useRef<HTMLAnchorElement>(null);

  const handleRestart = () => {
    resetErrorBoundary();
  };
  const handleReport = () => {
    linkRef.current?.click();
  };

  return (
    <>
      <div
        className={
          "flex min-h-[100dvh] w-full items-center justify-center p-6 sm:items-end sm:p-0 sm:pt-6"
        }
      >
        <div
          role={"alert"}
          className={
            "z-30 flex min-h-[400px] w-[clamp(380px,60dvw,620px)] flex-col gap-7 overflow-auto overflow-x-hidden rounded-2xl border-1 border-border bg-bg-container px-6 py-6 shadow-md-sharp-border sm:h-[85dvh] sm:w-full sm:rounded-b-none sm:border-0 sm:border-t-1 sm:px-4"
          }
        >
          <UnexpectedError className={"max-h-[16rem] w-fit"} />
          <a
            ref={linkRef}
            rel="noreferrer"
            className={"hidden"}
            target={"_blank"}
            href={
              "https://bettercallpaul.youtrack.cloud/form/ae26043a-18a2-4f34-8f82-e10053d5c6d6"
            }
          />
          <div className={"flex flex-col gap-3"}>
            <h1 className={"text-3xl font-semibold text-fg-primary"}>
              Wystąpił błąd
            </h1>
            <h5 className={"text-sm text-fg-muted"}>
              Po więcej informacji sprawdź konsolę przegladarki.
            </h5>
            <Badge variant={"muted"} hideDot>
              Prawy przycisk myszy --/ Zbadaj
            </Badge>
          </div>

          <div className={"flex flex-row gap-2"}>
            <Terminal className={"h-5 w-5 stroke-fg-primary"} />
            <pre className={"text-wrap text-md text-fg-secondary"}>
              {error.message}
            </pre>
          </div>
          <div className={"flex justify-end gap-3 sm:flex-col"}>
            <Button
              className={"w-full"}
              variant={"flat"}
              variantColor={"muted"}
              onClick={() => handleRestart()}
            >
              Spróbuj ponownie
            </Button>
            <Button
              className={"w-full"}
              variant={"flat"}
              variantColor={"destructive"}
              onClick={() => handleReport()}
            >
              Zgłoś błąd
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
