import {
  Children,
  Fragment,
  ReactNode,
  cloneElement,
  isValidElement,
  useCallback,
  useMemo,
  useRef,
} from "react";

interface InfiniteScrollProps {
  isLoading: boolean;
  isFetching: boolean;
  hasNextPage: boolean;
  next: () => unknown;
  threshold?: number;
  root?: Element | Document | null;
  rootMargin?: string;
  reverse?: boolean;
  children?: ReactNode;
}

function InfiniteScroll({
  isLoading,
  isFetching,
  hasNextPage,
  next,
  threshold = 1,
  root = null,
  rootMargin = "0px",
  reverse = false,
  children,
}: InfiniteScrollProps) {
  const observer = useRef<IntersectionObserver>();

  const observerRef = useCallback(
    (node: HTMLElement | null) => {
      let safeThreshold = threshold;
      if (threshold < 0 || threshold > 1) {
        console.warn(
          "Threshold should be between 0 and 1. You are exceed the range. will use default value: 1",
        );
        safeThreshold = 1;
      }
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      if (!node) return;

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextPage && !isFetching) {
            next();
          }
        },
        { threshold: safeThreshold, root, rootMargin },
      );
      if (node) observer.current.observe(node);
    },
    [next, hasNextPage, isFetching, isLoading],
  );

  const flattenChildren = useMemo(() => Children.toArray(children), [children]);

  return (
    <Fragment>
      {flattenChildren.map((child, index) => {
        if (!isValidElement(child)) {
          process.env.NODE_ENV === "development" &&
            console.warn("You should use a valid element with InfiniteScroll");
          return child;
        }

        const isObserveTarget = reverse
          ? index === 0
          : index === flattenChildren.length - 1;
        const ref = isObserveTarget ? observerRef : null;
        // @ts-expect-error ignore ref type
        return cloneElement(child, { ref });
      })}
    </Fragment>
  );
}

export { InfiniteScroll };
