import { useCallback, useRef } from "react";

interface LongPressProps<T> {
  onClick?: (event: React.PointerEvent<T>, isTouch: boolean) => void;
  onHold?: (event: React.PointerEvent<T>, isTouch: boolean) => void;
  options: { preventDefault?: boolean; delay?: number; distance?: number };
}

export default function useLongPress<Type>({
  onClick,
  onHold,
  options: { preventDefault = false, delay = 500, distance = 3 ** 2 },
}: LongPressProps<Type>) {
  const timerRef = useRef<number | null>(null);
  const posRef = useRef<[number, number]>([0, 0]);

  const onPointerDown = (event: React.PointerEvent<Type>) => {
    if (preventDefault) event.preventDefault();
    const clientX = event.clientX;
    const clientY = event.clientY;
    posRef.current = [clientX, clientY];
    const timeoutId = window.setTimeout(
      timesup.bind(null, { ...event }),
      delay,
    );
    timerRef.current = timeoutId;
  };

  const onPointerUp = (event: React.PointerEvent<Type>) => {
    if (preventDefault) event.preventDefault();
    if (timerRef.current) {
      const isTouch = isTouchEvent(event);
      window.clearTimeout(timerRef.current);
      timerRef.current = null;
      onClick?.(event, isTouch);
    }
  };

  const onPointerMove = (event: React.PointerEvent<Type>) => {
    if (preventDefault) event.preventDefault();
    // cancel hold operation if moved too much
    if (timerRef.current) {
      const clientX = event.clientX;
      const clientY = event.clientY;
      const d =
        (clientX - posRef.current[0]) ** 2 + (clientY - posRef.current[1]) ** 2;
      if (d > distance) {
        window.clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    }
  };

  function timesup(event: React.PointerEvent<Type>) {
    timerRef.current = null;
    const isTouch = isTouchEvent(event);
    onHold?.(event, isTouch);
  }

  function isTouchEvent(event: React.PointerEvent<Type>) {
    return event.pointerType === "touch";
  }

  return {
    onPointerDown: onPointerDown,
    onPointerUp: onPointerUp,
    onPointerMove: onPointerMove,
  };
}
