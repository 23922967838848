import { io } from "socket.io-client";

export function socketIOClient(id: number) {
  return io(import.meta.env.VITE_SOCKET_DOMAIN, {
    withCredentials: true,
    autoConnect: false,
    query: { chatId: id },
    path: import.meta.env.VITE_SOCKET_IO,
    reconnectionAttempts: 6,
    timeout: 100000,
  });
}
