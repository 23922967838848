import { httpErrorHandler } from "@/api/api";
import { useDeleteConversationMutation } from "@/api/queries/chatQueries";
import {
  Alert,
  AlertContent,
  AlertDescription,
  AlertTitle,
} from "@/components/ui/alert/Alert";
import { Button } from "@/components/ui/button/Button";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/dialog/AlertDialog";
import { Check } from "lucide-react";
import { Dispatch, Fragment, SetStateAction, useMemo } from "react";
import { useNavigate } from "react-router-dom";

type ChatConversationDeleteFormProps = {
  name: string;
  id: number;
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
};

export default function DeleteChat({
  name,
  id,
  open,
  onOpenChange,
}: ChatConversationDeleteFormProps) {
  const navigate = useNavigate();

  const { mutateAsync, isPending, isSuccess, isError, error } =
    useDeleteConversationMutation();

  const errorResponse = httpErrorHandler(error);

  const onClose = () => {
    navigate("/chat");
    onOpenChange(false);
  };

  const onCloseCancel = () => {
    onOpenChange(false);
  };

  const onSubmit = async () => {
    await mutateAsync(id);
  };

  const content = useMemo(() => {
    if (isSuccess) {
      return (
        <div className={"flex gap-2 items-center"}>
          <span
            className={
              "flex justify-center items-center w-9 h-9 rounded-md bg-bg-success-subtle"
            }
          >
            <Check className={"a h-5 w-5 stroke-fg-success"} />
          </span>
          <p className={"text-fg-secondary"}>Chat został usunięty pomyślnie.</p>
        </div>
      );
    } else if (isError) {
      return (
        <Alert variant={"destructive"}>
          <AlertContent>
            <AlertTitle>{errorResponse.title}</AlertTitle>
            <AlertDescription>{errorResponse.detail}</AlertDescription>
          </AlertContent>
        </Alert>
      );
    } else {
      return (
        <p className={"text-sx text-fg-secondary"}>
          Czy na pewno chcesz usunąć czat: <b> {name} ?</b>
        </p>
      );
    }
  }, [isSuccess, isError, errorResponse.title, errorResponse.detail, name]);

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent className={"max-w-[40ch]"}>
        <AlertDialogHeader>
          <AlertDialogTitle>Czy chcesz usunąć to chat ?</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogBody>{content}</AlertDialogBody>
        <AlertDialogFooter>
          {isSuccess ? (
            <Button variant={"ghost"} variantColor={"muted"} onClick={onClose}>
              Zamknij
            </Button>
          ) : (
            <Fragment>
              <Button
                variant={"ghost"}
                variantColor={"muted"}
                onClick={onCloseCancel}
              >
                Anuluj
              </Button>
              <Button
                autoFocus
                variant={"flat"}
                variantColor={"destructive"}
                onClick={onSubmit}
                isLoading={{ state: isPending }}
              >
                Potwierdź
              </Button>
            </Fragment>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
