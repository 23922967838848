import { ReactNode, createContext, useContext } from "react";

interface Identifiable {
  id: number;
}

type UlElementProps<T extends Identifiable> = {
  children: ReactNode | ReactNode[] | null;
  selected: T[];
  setSelected: (data: T[]) => void;
};

type UlElementContextT<T extends Identifiable> = {
  selected: T[];
  removeElement: (elementId: number) => void;
  addElement: (element: T) => void;
  isSelected: (id: number) => boolean;
};

const UlElementContext = createContext<UlElementContextT<any>>({
  selected: [],
  removeElement: () => {},
  addElement: () => {},
  isSelected: () => false,
});

const UlElement = <T extends Identifiable>({
  children,
  selected,
  setSelected,
}: UlElementProps<T>) => {
  const addElement = (element: T) => {
    setSelected([...selected, element]);
  };
  const removeElement = (id: number) => {
    const newSelected: T[] = selected.filter((element: T) => {
      return element.id !== id;
    });
    setSelected(newSelected);
  };

  const isSelected = (id: number): boolean => {
    return selected.some((element: T) => {
      return element.id === id;
    });
  };

  return (
    <UlElementContext.Provider
      value={{
        selected,
        removeElement,
        addElement,
        isSelected,
      }}
    >
      <ul className={"w-full"}>{children}</ul>
    </UlElementContext.Provider>
  );
};

const useUlElementContext = () => {
  return useContext(UlElementContext);
};

export { Identifiable, UlElement, useUlElementContext };
