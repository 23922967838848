import FSPath from "@/components/features/files/FsPath";
import { Button } from "@/components/ui/button/Button";
import { TabsList, TabsTrigger } from "@/components/ui/tabs/Tabs";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { useFilesStorage } from "@/store/filesStore";
import { DirectoryI } from "@/types/files";
import {
  Download,
  FileUp,
  FolderPlus,
  FolderSymlinkIcon,
  FolderUp,
  HardDrive,
  Trash2,
  Users,
  X,
} from "lucide-react";
import { useMemo } from "react";

type StorageHeaderNavigationProps = {
  fsEntry?: DirectoryI;
  isLoading: boolean;
  isError: boolean;
  showTabsList: boolean;
};

export default function FilesPageHeader({
  fsEntry,
  isError,
  isLoading,
  showTabsList,
}: StorageHeaderNavigationProps) {
  const breakpointLaptop = useMediaQueryHook("md");
  const {
    resetSelected,
    setOpenCreateDir,
    setOpenCreateFile,
    setOpenCreateFolder,
    selectedSize,
    selected,
    setOpenShare,
    setOpenDownload,
    setOpenMove,
    setOpenDelete,
  } = useFilesStorage();

  const size = selectedSize();

  const permissions = useMemo(() => {
    return {
      read: [...selected.directories, ...selected.files].every(
        (el) => el.permissions.read,
      ),
      edit: [...selected.directories, ...selected.files].every(
        (el) => el.permissions.edit,
      ),
      write: [...selected.directories, ...selected.files].every((el) => {
        return el.permissions.write;
      }),
    };
  }, [selected]);

  const openEditShare = () => {
    setOpenShare(true);
  };

  const openDownload = () => {
    setOpenDownload(true);
  };

  const openMove = () => {
    setOpenMove(true);
  };

  const openDelete = () => {
    setOpenDelete(true);
  };

  return (
    <header
      className={
        "grid [grid-template-areas:'breadcrumb_buttons''model_model'] md:[grid-template-areas:'breadcrumb_breadcrumb''model_buttons'] gap-3 p-4 bg-bg-container rounded-lg border-border border-1"
      }
    >
      <FSPath fsEntry={fsEntry} isLoading={isLoading} isError={isError} />
      <div
        className={cn(
          "[grid-area:buttons] flex justify-end gap-2 sm:gap-1 shrink-0",
          size > 0 && "md:hidden",
        )}
      >
        <Button
          onClick={() => setOpenCreateDir(true)}
          variant={"outline"}
          variantColor={"muted"}
          icon={<FolderPlus />}
          iconPosition={"only"}
          disabled={isError || isLoading}
        />
        <Button
          onClick={() => setOpenCreateFile(true)}
          variant={"outline"}
          variantColor={"muted"}
          icon={<FileUp />}
          iconPosition={"only"}
          disabled={isError || isLoading}
        />
        <Button
          onClick={() => setOpenCreateFolder(true)}
          variant={"outline"}
          variantColor={"muted"}
          icon={<FolderUp />}
          iconPosition={"only"}
          disabled={isError || isLoading}
        />
      </div>
      <TabsList
        className={cn(
          "grid grid-cols-2 [grid-area:model] w-fit",
          !showTabsList && "hidden",
          size > 0 && "hidden",
        )}
      >
        <TabsTrigger value={"my_drive"} className={"sm:px-4"}>
          <HardDrive className={"w-4 h-4"} />
          {!breakpointLaptop && "Mój Dysk"}
        </TabsTrigger>
        <TabsTrigger value={"shared_with_me"} className={"sm:px-4"} disabled>
          <Users className={"w-4 h-4"} />
          {!breakpointLaptop && "Udostępnione"}
        </TabsTrigger>
      </TabsList>
      <div
        className={cn(
          "col-[span_2] row-[2] flex w-full bg-bg border-border border-1 items-center rounded-sm px-0.5 h-11",
          size === 0 && "hidden",
        )}
      >
        <Button
          onClick={resetSelected}
          size={"sm"}
          variant={"ghost"}
          variantColor={"muted"}
          icon={<X />}
          iconPosition={"only"}
        />
        <p className={"text-xs font-medium text-fg-muted mx-2 w-14"}>
          Wybrano: {size}
        </p>
        <Button
          onClick={openMove}
          disabled={!permissions.edit}
          size={"sm"}
          variant={"ghost"}
          variantColor={"muted"}
          icon={<FolderSymlinkIcon />}
          iconPosition={"only"}
        />
        <Button
          onClick={openDownload}
          disabled={!permissions.read}
          size={"sm"}
          variant={"ghost"}
          variantColor={"muted"}
          icon={<Download />}
          iconPosition={"only"}
        />
        <Button
          onClick={openDelete}
          disabled={!permissions.edit && !permissions.write}
          size={"sm"}
          variant={"ghost"}
          variantColor={"muted"}
          icon={<Trash2 />}
          iconPosition={"only"}
        />
        {/* <Button
          onClick={openEditShare}
          disabled={!permissions.edit}
          size={"sm"}
          variant={"ghost"}
          variantColor={"muted"}
          icon={<Share2 />}
          iconPosition={"only"}
        /> */}
      </div>
    </header>
  );
}
