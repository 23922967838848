import ChatTimeToPresent from "@/components/features/chat/ChatTimeToPresent";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar/Avatar";
import { Button } from "@/components/ui/button/Button";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuGroup,
  ContextMenuItem,
  ContextMenuTrigger,
} from "@/components/ui/context-menu/ContextMenu";
import Image from "@/components/ui/image/Image";
import { Link } from "@/components/ui/link/Link";
import AudioPlayer from "@/components/ui/media-viewer/AudioPlayer";
import VideoPlayer from "@/components/ui/media-viewer/VideoPlayer";
import { Spinner } from "@/components/ui/spinner/Spinner";
import { cn } from "@/lib/utils";
import { useCredentials } from "@/store/authStore";
import { useChatStore } from "@/store/chatStore";
import { MessageI, conversationTypeT } from "@/types/chat";
import { getAvatar } from "@/utils/getAvarar";
import getFileUrl from "@/utils/getFileUrl";
import { AudioMimeType, VideoMimeType } from "@vidstack/react/types/vidstack";
import { AnimatePresence, motion } from "framer-motion";
import { Copy, Download, Reply, Trash, User } from "lucide-react";
import { ReactNode, forwardRef, useMemo, useRef } from "react";

type ChatMessageProps = {
  message: MessageI;
  onDelete: (id: number) => void;
  conversationType: conversationTypeT;
};

const Message = forwardRef<HTMLDivElement, ChatMessageProps>(
  ({ message, conversationType, onDelete }, ref) => {
    const { tempUUID, sender, createdAt, id, type } = message;
    const { id: userId } = useCredentials();
    const isUserMessage = useMemo(
      () => sender?.id === userId,
      [sender, userId],
    );
    const userName = useMemo(() => {
      if (sender) {
        return isUserMessage
          ? "Ty"
          : sender.name + " " + sender.surname?.at(0) + ".";
      } else {
        return "Użytkownik został usunięty";
      }
    }, [isUserMessage, sender]);
    return (
      <div
        ref={ref}
        className={cn(
          "flex gap-2  items-center justify-start select-none",
          isUserMessage ? "flex-row-reverse" : "flex-row",
        )}
        id={String(id)}
        data-message-date={createdAt}
        data-message-sender={sender?.id}
      >
        <ChatMessageMoreContext
          message={message}
          onDelete={onDelete}
          isUserMessage={isUserMessage}
        >
          <div
            className={cn(
              "flex",
              isUserMessage ? "flex-row" : "flex-row-reverse",
            )}
          >
            <div className={"min-w-10 w-10"} />
            <div
              className={cn(
                "flex flex-col border-border border-1 rounded-md p-2 gap-1 max-w-[50ch] ",
                isUserMessage
                  ? "bg-bg-muted-subtle/60 border-bg-muted-subtle/60"
                  : "bg-bg-container",
                type === "error" && "bg-bg-destructive-subtle",
              )}
            >
              <ChatMessageHeader
                avatarURL={sender?.avatarURL}
                userName={userName}
                isUserMessage={isUserMessage}
                conversationType={conversationType}
                createdAt={createdAt}
              />
              <ChatMessageContent
                userName={userName}
                message={message}
                isUserMessage={isUserMessage}
                conversationType={conversationType}
              />
              <ChatMessageFooter tempUUID={tempUUID} />
            </div>
          </div>
        </ChatMessageMoreContext>
      </div>
    );
  },
);

Message.displayName = "Message";

function ChatMessageMoreContext({
  children,
  onDelete,
  message,
  isUserMessage,
}: {
  onDelete: (id: number) => void;
  message: MessageI;
  isUserMessage: boolean;
  children: ReactNode;
}) {
  const downloadButtonRef = useRef<HTMLAnchorElement>(null);

  const { id, type, attachment, content } = message;
  const setReplyingTo = useChatStore.useSetReplyingTo();

  const handleDeleteMessage = () => {
    if (id) onDelete(id);
  };

  const handleReplyTo = () => {
    setReplyingTo(message);
  };

  const handleDownload = () => {
    if (!downloadButtonRef.current) return;
    downloadButtonRef.current.click();
  };

  const handleCopyText = async () => {
    await navigator.clipboard.writeText(content);
  };

  if (type === "deleted" || type === "error") {
    return children;
  }

  return (
    <ContextMenu>
      <ContextMenuTrigger asChild>{children}</ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuGroup>
          <ContextMenuItem onClick={handleReplyTo} disabled={!id}>
            <Reply className={"w-4 h-4"} />
            Odpowiedz
          </ContextMenuItem>
          {type === "text" ? (
            <ContextMenuItem onClick={handleCopyText}>
              <Copy className={"w-4 h-4"} />
              Kopiuj tekst
            </ContextMenuItem>
          ) : (
            <ContextMenuItem onClick={handleDownload} disabled={!id}>
              <a
                ref={downloadButtonRef}
                href={getFileUrl(attachment?.id as string)}
                className={"hidden"}
              />
              <Download className={"w-4 h-4"} />
              Pobierz
            </ContextMenuItem>
          )}
          {isUserMessage ? (
            <ContextMenuItem
              onClick={handleDeleteMessage}
              disabled={!id}
              className={"text-fg-destructive"}
            >
              <Trash className={"w-4 h-4"} />
              Usuń
            </ContextMenuItem>
          ) : null}
        </ContextMenuGroup>
      </ContextMenuContent>
    </ContextMenu>
  );
}

function ChatMessageContent({
  message,
  conversationType,
  userName,
  isUserMessage,
}: {
  message: MessageI;
  isUserMessage: boolean;
  conversationType: conversationTypeT;
  userName: string;
}) {
  const { type, content, replyingTo, attachment, file, tempUUID } = message;

  const fileURL = useMemo(() => {
    if (!file) return;
    return URL.createObjectURL(file);
  }, [file]);

  const mimeType = useMemo(() => {
    const type = file ? file.type : (attachment?.fileType as string);
    if (!type) return undefined;
    return type;
  }, [file, attachment]);

  const messageReplyToContent = useMemo(() => {
    if (!replyingTo) return;
    return (
      <div className={"flex gap-1"}>
        <span
          className={"w-1 max-w-1 shrink-0 grow rounded-md bg-bg-element"}
        />
        <div className={"flex flex-col gap-1"}>
          {conversationType !== "individual" ? (
            <p className={"text-xs text-fg-primary"}>{userName}</p>
          ) : null}

          <p
            className={
              "text-sm max-h-[10rem] text-fg-muted italic line-clamp-5 text-ellipsis"
            }
          >
            {replyingTo.content}
          </p>
        </div>
      </div>
    );
  }, [conversationType, replyingTo, userName]);
  const messageContent = useMemo(() => {
    switch (type) {
      case "text":
        return (
          <p
            className={
              "text-sm overflow-hidden break-words whitespace-pre-line"
            }
          >
            {content}
          </p>
        );
      case "deleted":
        return <p className={"text-sm text-fg-muted italic"}>{content}</p>;
      case "error":
        return (
          <p className={"text-sm text-fg-destructive italic"}>{content}</p>
        );
      case "image":
        return (
          <Image
            src={fileURL ? fileURL : getFileUrl(attachment?.id as string)}
            alt={content}
            thumbnail={attachment?.thumbnail}
            type={attachment?.fileType}
            className={"rounded-sm"}
            layoutClassName={"rounded-sm"}
            height={200}
          />
        );
      case "video":
        return (
          <VideoPlayer
            src={{
              src: fileURL ? fileURL : getFileUrl(attachment?.id as string),
              type: mimeType as VideoMimeType,
            }}
            title={attachment?.name ? attachment?.name : "video file"}
            className={cn("h-[204px]")}
          />
        );
      case "audio":
        return (
          <AudioPlayer
            src={{
              src: fileURL ? fileURL : getFileUrl(attachment?.id as string),
              type: mimeType as AudioMimeType,
            }}
            title={attachment?.name ? attachment?.name : "audio file"}
          />
        );
      case "file":
        return (
          <div
            className={cn(
              "flex gap-2 items-center p-2  rounded-sm",
              isUserMessage ? "bg-bg-container" : "bg-bg-element",
            )}
          >
            <Link href={getFileUrl(attachment?.id as string)}>
              <Button
                disabled={!!tempUUID}
                variant={"ghost"}
                variantColor={"muted"}
                className={"rounded-sm"}
                icon={<Download />}
                iconPosition={"only"}
              />
            </Link>
            <p className={"text-sm truncate"}>{content}</p>
          </div>
        );
    }
  }, [content, type]);
  return (
    <div className={"flex flex-col gap-2 w-full"}>
      {messageReplyToContent}
      {messageContent}
    </div>
  );
}

function ChatMessageHeader({
  conversationType,
  isUserMessage,
  createdAt,
  userName,
  avatarURL,
}: {
  isUserMessage: boolean;
  conversationType: conversationTypeT;
  createdAt: string;
  userName: string;
  avatarURL?: string;
}) {
  return (
    <div
      className={cn(
        "flex gap-2 w-full items-center",
        isUserMessage ? "flex-row-reverse" : "flex-row",
      )}
    >
      {conversationType !== "individual" && !isUserMessage ? (
        <Avatar size={"sm"} className={"min-h-6 h-6 min-w-6 w-6 rounded-sm"}>
          <AvatarImage src={getAvatar(avatarURL)} />
          <AvatarFallback>
            <User className={"w-3 h-3"} />
          </AvatarFallback>
        </Avatar>
      ) : null}

      {conversationType !== "individual" ? (
        <p className={"text-xs text-fg-primary font-medium"}>{userName}</p>
      ) : null}
      <ChatTimeToPresent
        className={"text-xs text-fg-muted"}
        createdAt={createdAt}
      />
    </div>
  );
}

function ChatMessageFooter({ tempUUID }: { tempUUID: string | undefined }) {
  return (
    <AnimatePresence mode={"sync"}>
      {tempUUID && (
        <motion.div
          key={"sending-key"}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.8, opacity: 0 }}
          transition={{ type: "spring" }}
          className={"flex gap-1 items-center text-xs text-fg-muted italic"}
        >
          <Spinner variant={"muted"} size={"sm"} />
          Wysyłanie...
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export const ChatMessageSkeleton = forwardRef<HTMLDivElement>(
  (props = {}, ref) => {
    return (
      <div
        ref={ref}
        className={"w-full text-center text-sm text-fg-muted italic p-2"}
        {...props}
      >
        Ładowanie...
      </div>
    );
  },
);

ChatMessageSkeleton.displayName = "ChatMessageSkeleton";

export default Message;
