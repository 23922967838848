import { differenceInMilliseconds } from "date-fns";

/**
 * Convert the time of day to the height of a parent div.
 *
 * @param {Date} date - The current date.
 * @param {number} parentHeight - The height of the parent div.
 * @returns {number} The height in pixels.
 */

export function timeToHeight(date: Date, parentHeight: number): number {
  // Get the start of the day.
  const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());

  // Calculate the difference in milliseconds between the current time and the start of the day.
  const diffMillis = differenceInMilliseconds(date, startOfDay);

  // Convert the difference in milliseconds to a percentage of the day.
  const percentOfDay = diffMillis / (24 * 60 * 60 * 1000);

  // Convert the percentage of the day to a height in pixels.
  return percentOfDay * parentHeight;
}