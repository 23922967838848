import { RefObject, useEffect, useState } from "react";

interface Size {
  width: number;
  height: number;
}

export default function useElementData<T extends HTMLElement = HTMLDivElement>(
  target: RefObject<T>,
  returnDOMRect: boolean = false,
): [Size, DOMRect | undefined] {
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });
  const [DOMRect, setDOMRect] = useState<DOMRect>();

  useEffect(() => {
    const targetElement = target.current;
    if (targetElement) {
      const resizeObserver = new ResizeObserver(() => {
        setSize({
          width: targetElement.offsetWidth,
          height: targetElement.offsetHeight,
        });
        if (returnDOMRect) {
          setDOMRect(targetElement.getBoundingClientRect());
        }
      });

      resizeObserver.observe(targetElement);

      return () => {
        resizeObserver.unobserve(targetElement);
      };
    }
  }, [target, returnDOMRect]);

  return [size, returnDOMRect ? DOMRect : undefined];
}
