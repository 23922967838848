import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatBytes(
  bytes: number,
  opts: {
    decimals?: number;
    sizeType?: "accurate" | "normal";
  } = {},
) {
  const { decimals = 0, sizeType = "normal" } = opts;

  const dm = decimals < 0 ? 0 : decimals;

  const sizes = ["bytes", "kb", "mb", "gb", "tb"];
  const accurateSizes = ["bytes", "kib", "mib", "gib", "tib"];
  if (bytes === 0) return "0 Byte";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / Math.pow(1024, i)).toFixed(dm)} ${
    sizeType === "accurate" ? accurateSizes[i] ?? "bytes" : sizes[i] ?? "bytes"
  }`;
}
