import { httpErrorHandler } from "@/api/api";
import { useEditDirectoryMutation } from "@/api/queries/directoryQueries";
import { useEditFileMutation } from "@/api/queries/filesQueries";
import { Button } from "@/components/ui/button/Button";
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form/Form";
import { Input } from "@/components/ui/input/Input";
import { useToast } from "@/components/ui/toast/useToast";
import { DirectoryDirI, DirectoryFileI } from "@/types/files";
import { getIsFSEntityFile } from "@/utils/files";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { InferType, object, string } from "yup";

type StorageElementEditNameFormProps = {
  element?: DirectoryDirI | DirectoryFileI;
  onOpenChange: (open: boolean) => void;
  open: boolean;
  directoryId?: string;
};

const EditFSEntryNameSchema = object({
  id: string().required(),
  name: string().required("Nazwa jest wymagana"),
});

type EditFSEntryNameSchemaType = InferType<typeof EditFSEntryNameSchema>;

function useEditFSEntryForm(element?: DirectoryDirI | DirectoryFileI) {
  const isFile = getIsFSEntityFile(element);
  return isFile ? useEditFileMutation : useEditDirectoryMutation;
}

export default function EditFSEntryName({
  element,
  onOpenChange,
  open,
  directoryId,
}: StorageElementEditNameFormProps) {
  const { toast } = useToast();

  const editMutation = useEditFSEntryForm(element);

  const { mutateAsync, isPending } = editMutation(directoryId);

  const splitName = (fileName?: string) => {
    if (fileName === undefined) return { name: "", extension: "" };
    const lastDotIndex = fileName.lastIndexOf(".");
    if (lastDotIndex === -1) return { name: fileName, extension: "" };
    return {
      name: fileName.substring(0, lastDotIndex),
      extension: fileName.substring(lastDotIndex),
    };
  };

  const handleClose = () => {
    onOpenChange(false);
  };

  const onSubmit = (data: EditFSEntryNameSchemaType) => {
    const { extension } = splitName(element?.name);
    const newData = { ...data, name: data.name + extension };
    mutateAsync(newData)
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        const { title, detail } = httpErrorHandler(error);
        toast({
          title: title,
          description: detail,
          variant: "destructive",
        });
      });
  };

  const form = useForm<EditFSEntryNameSchemaType>({
    mode: "onBlur",
    defaultValues: {
      id: "",
      name: "",
    },
    resolver: yupResolver(EditFSEntryNameSchema),
  });

  useEffect(() => {
    if (open && element) {
      const { name } = splitName(element?.name);
      form.setValue("name", name);
      form.setValue("id", element?.id);
    }
  }, [open, element]);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className={"w-[40ch] sm:w-full"}>
        <DialogHeader>
          <DialogTitle>Zmień nazwę</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <DialogBody className={"py-1"}>
            <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
              <FormField
                name={"name"}
                control={form.control}
                render={({ field }) => (
                  <FormItem className={"col-span-1"}>
                    <FormControl>
                      <Input {...field} placeholder={"Nazwa"} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </DialogBody>
        </Form>
        <DialogFooter>
          <Button
            onClick={handleClose}
            type={"button"}
            variant={"flat"}
            variantColor={"muted"}
            className={"sm:w-full"}
          >
            Anuluj
          </Button>
          <Button
            isLoading={{ state: isPending }}
            onClick={form.handleSubmit(onSubmit)}
            type={"submit"}
            variant={"flat"}
            variantColor={"brand"}
            className={"sm:w-full"}
          >
            Zapisz
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
