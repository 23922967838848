import { useTanstackTableDynamicContext } from "@/components/features/manage/tanstack-table-dynamic/TanstackTableDynamic";
import { Button } from "@/components/ui/button/Button";
import { Row } from "@tanstack/react-table";
import { Check, CircleSlash, Pencil, X } from "lucide-react";

type DataTableEditCellT<TData> = {
  row: Row<TData>;
};

export function TanstackTableDynamicEditCell<TData>({
  row,
}: DataTableEditCellT<TData>) {
  const { id, index } = row;
  const {
    saveChanges,
    setEditedRows,
    removeRow,
    editedRows,
    revertData,
    restoreOriginalData,
  } = useTanstackTableDynamicContext();

  const handleSetEditedRows = () => {
    restoreOriginalData();
    setEditedRows((curr) => {
      return {
        ...curr,
        [id]: !curr[id],
      };
    });
  };

  const handleRevertData = () => {
    handleSetEditedRows();
  };

  const handleCancelRow = () => {
    handleSetEditedRows();
    revertData(index);
  };

  const handleSaveChanges = () => {
    handleSetEditedRows();
    saveChanges(index);
  };

  const handleRemoveRow = () => {
    removeRow(index);
  };

  return (
    <div className={"flex gap-1 h-full justify-end"}>
      {editedRows[id] ? (
        <div className={"flex gap-1"}>
          <Button
            onClick={handleCancelRow}
            type={"button"}
            variant={"outline"}
            variantColor={"muted"}
            name={"cancel"}
            icon={<CircleSlash />}
            iconPosition={"only"}
          />
          <Button
            onClick={handleSaveChanges}
            type={"button"}
            variant={"outline"}
            variantColor={"muted"}
            name={"done"}
            icon={<Check />}
            iconPosition={"only"}
          />
        </div>
      ) : (
        <div className={"flex gap-1"}>
          <Button
            onClick={handleRevertData}
            type={"button"}
            variant={"outline"}
            variantColor={"muted"}
            name={"edit"}
            icon={<Pencil />}
            iconPosition={"only"}
          />
          <Button
            onClick={handleRemoveRow}
            type={"button"}
            variant={"outline"}
            variantColor={"muted"}
            name={"remove"}
            icon={<X />}
            iconPosition={"only"}
          />
        </div>
      )}
    </div>
  );
}
