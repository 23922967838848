import Image from "@/components/ui/image/Image";
import { FilesI } from "@/types/files";
import getFileUrl from "@/utils/getFileUrl";
import { useMemo } from "react";

type MediaImageViewerProps = {
  file: FilesI | File;
};

export default function MediaImageViewer({ file }: MediaImageViewerProps) {
  const isFilesInstance = "id" in file;

  const fileURL = useMemo(() => {
    if (!file || isFilesInstance) {
      return getFileUrl(file.id);
    }
    return URL.createObjectURL(file);
  }, [file]);

  return (
    <Image
      src={fileURL}
      alt={file.name}
      thumbnail={isFilesInstance ? file.thumbnail : undefined}
      type={isFilesInstance ? file.fileType : undefined}
      className={"rounded-sm"}
      layoutClassName={"rounded-sm h-full w-full justify-center"}
    />
  );
}
