import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs/Tabs";
import ThemeContext from "@/context/Theme";
import { Moon, Sun } from "lucide-react";
import { useCallback, useContext } from "react";

function ToggleThemeMode() {
  const { SwitchTheme, theme } = useContext(ThemeContext);
  const handleValueChange = useCallback(() => {
    SwitchTheme();
  }, [SwitchTheme]);

  return (
    <Tabs
      defaultValue={theme}
      onValueChange={handleValueChange}
      className={"mx-4"}
    >
      <TabsList className={"grid w-full grid-cols-2"}>
        <TabsTrigger value={"light"} icon={<Sun />} iconPosition={"left"}>
          Jasny
        </TabsTrigger>
        <TabsTrigger value={"dark"} icon={<Moon />} iconPosition={"left"}>
          Ciemny
        </TabsTrigger>
      </TabsList>
    </Tabs>
  );
}
ToggleThemeMode.displayName = "ToggleThemeMode";

export { ToggleThemeMode };
